import { useContext } from 'react';

import { TextsContext } from '../../../../context/TextsContext';

import { NavLink } from 'react-router-dom'
import SHHr from '../../../SHHr/SHHr'

import './Menu.css';

import {entries} from './components/entries'
import React from 'react';
import { CORESec } from '../../../../env/CORESec';

function ShowMenu () {
    
    const dataText = useContext(TextsContext)
    
    return (
        <nav className="navbar-nav me-auto mb-lg-0">
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav navbar-custom">
                        {
                            entries.map((entry, idx) => {
                                return (
                                    entry.entries.length === 0 ?
                                    (entry.operation === undefined || CORESec.Operation(entry.operation)) &&
                                    <li key={idx} className="nav-item">
                                        <NavLink className="nav-link" aria-current="page" to={`${entry.to}`}>
                                            {dataText.get(entry.text)}
                                        </NavLink>
                                    </li>
                                    :
                                    <li key={idx} className="nav-item dropdown">
                                        <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {dataText.get(entry.text)}
                                        </span>
                                        <ul 
                                            className="dropdown-menu navbar-custom" 
                                            aria-labelledby="navbarDropdown"
                                            style={{margin: 0}} // Este margin fixea un warning de Popper
                                        >
                                            {entry.entries.map((subEntry, idx) => {
                                                return (
                                                    subEntry.hasHr ?
                                                    (subEntry.operation === undefined || CORESec.Operation(subEntry.operation)) &&
                                                    <React.Fragment key={idx}>
                                                        <li className='dropdown-item customItem'>
                                                            <NavLink style={{textDecoration:'none', color:'#000', padding:'.25rem 2rem'}} to={`${entry.to}${subEntry.to}`}>
                                                                {dataText.get(subEntry.text)}
                                                            </NavLink>
                                                        </li>
                                                        <SHHr />
                                                    </React.Fragment>
                                                    :
                                                    (subEntry.operation === undefined || CORESec.Operation(subEntry.operation)) &&
                                                    <li key={idx} className='dropdown-item customItem'>
                                                        <NavLink style={{textDecoration:'none', color:'#000', padding:'.25rem 2rem'}} to={`${entry.to}${subEntry.to}`}>
                                                            {dataText.get(subEntry.text)}
                                                        </NavLink>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                )
                        })}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default React.memo(ShowMenu);