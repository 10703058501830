import React from 'react';

// Estilos
import './SHLoading.css'

function SHLoading({ styles }) {
    return (
        <div className="d-flex justify-content-center align-items-center my-2" style={styles}>
            <div className="spinner-border" role="status" style={{ color: '#aecb29' }} >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default React.memo(SHLoading)
