import React from 'react';

// Estilos
import './SHCard.css'

function SHCard({
    alignFooter,
    cardClass,
    children,
    containerClass,
    contentAlign,
    footer,
    header,
    size,
    headerCenter
}) {
    return (
        <>
            <div style={{ display: 'inline-block', width: '100%' }} className={containerClass}>
                <div className={`card ${size} ${cardClass}`}>
                    <h5 className={`card-header ${headerCenter && 'text-center'} `}>{header}</h5>
                    <div className={`card-body py-2 ${contentAlign}`}>
                        {children}
                    </div>
                    {/* Si tiene la prop footer lo mostrará, sino no. */}
                    {footer &&
                        <div className={'card-footer d-flex ' +
                            (alignFooter === 'center' ? 'justify-content-center' :
                                alignFooter === 'left' ? 'justify-content-start' :
                                    'justify-content-end')} >
                            {footer}
                        </div>}
                </div>
            </div>

        </>
    )
}

export default SHCard;