import React, { useMemo, lazy, Suspense } from 'react';

import SHButton from '../SHButton/SHButton';
import SHIcon from '../SHIcon/SHIcon';

import { CORESec } from '../../env/CORESec';

import { accountService } from '../../services/accountService';
import { accountEventNoteService } from '../../services/accountEventNoteService';
import { accountEventService } from '../../services/accountEventService';
import { accountOpportunityService } from '../../services/accountOpportunityService';
import { businessUnitAccountService } from '../../services/businessUnitAccountService';
import { accountProductService } from '../../services/accountProductService';
import { productService } from '../../services/productService';
import { accountOpportunityProductService } from '../../services/accountOpportunityProductService';
import { accountNoteService } from '../../services/accountNoteService';
import { accountAddressService } from '../../services/accountAddressService';
import { accountContactService } from '../../services/accountContactService';
import { accountContactInfoService } from '../../services/accountContactInfoService';
import { accountOtherProductService } from '../../services/accountOtherProductService';
import { useNavigate } from 'react-router-dom';
import maxCharactersString from '../../assets/utils/maxCharactersString';
import { Config } from '../../env/Config';

const AccountModal = lazy(() => import('../../pages/Account/AccountModal'));
const ProductModal = lazy(() => import('../../pages/Product/ProductModal'));

export default function SHColumns(array) {
  let navigate = useNavigate();
  const headerStyles = React.useMemo(() => ({ display: 'inline-block' }), []);
  const actionStyles = React.useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'center',
      gap: '0.3rem',
      flexWrap: 'wrap',
      width: '100%',
      height: '100%',
      alignItems: 'center',
    }),
    []
  );
  const footerStyles = React.useMemo(() => ({ margin: 0, padding: 0 }), []);

  const handleDelete = (btnDeleteFrom, rowData, onRefresh) => {
    // Servicios para DELETE de registros
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    } else {
      switch (btnDeleteFrom) {
        case 'accounts':
          if (rowData)
            accountService.DeleteAccount(rowData.accountID).then((ok) => {
              if (ok) onRefresh();
            });
          break;
        case 'events':
          if (rowData)
            accountEventService
              .DeleteAccountEvent(rowData.accountEventID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountEventNote':
          if (rowData)
            accountEventNoteService
              .DeleteAccountEventNote(rowData.accountEventNoteID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountEventChild':
          if (rowData)
            accountEventService
              .DeleteAccountEvent(rowData.accountEventID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountNote':
          if (rowData)
            accountNoteService
              .DeleteAccountNote(rowData.accountNoteID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountAdress':
          if (rowData)
            accountAddressService
              .DeleteAccountAddress(rowData.accountAddressID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountContact':
          if (rowData)
            accountContactService
              .DeleteAccountContact(rowData.accountContactID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountContactInfo':
          if (rowData)
            accountContactInfoService
              .DeleteAccountContactInfo(rowData.accountContactInfoID)
              .then((ok) => {
                if (ok) {
                  onRefresh();
                }
              });
          break;
        case 'accountBU':
          if (rowData)
            businessUnitAccountService
              .DeleteAccountBU(rowData.accountProductID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountProductsBU':
          if (rowData)
            accountProductService
              .DeleteAccountProduct(rowData.accountProductID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountOthersProductsBU':
          if (rowData)
            accountOtherProductService
              .DeleteAccountOtherProduct(rowData.accountOtherProductID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'opportunities':
          if (rowData)
            accountOpportunityService
              .DeleteAccountOpportunity(rowData.accountOpportunityID)
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'accountOpportunityProduct':
          if (rowData)
            accountOpportunityProductService
              .DeleteAccountOpportunityProduct(
                rowData.accountOpportunityProductID
              )
              .then((ok) => {
                if (ok) onRefresh();
              });
          break;
        case 'products':
          if (rowData)
            productService.DeleteProduct(rowData.productID).then((ok) => {
              if (ok) onRefresh();
            });
          break;
        default:
          break;
      }
    }
  };

  const columnArray = array.map((col) => {
    if (col.key === 'actions') {
      // COLUMNA ACCIONES
      return {
        Header: () => <div style={headerStyles}>{col.header}</div>,
        Footer: () => <div style={footerStyles}> {col.header} </div>,
        width: 15,
        notLink: true,
        accessor: col.key,
        Cell: (cell) => {
          const getEntityComponent = (entity) => {
            if (!CORESec.validateSesion()) {
              CORESec.Logout();
              navigate(`/${Config.Version}/login`);
            } else {
              switch (entity) {
                case 'account':
                  return (
                    CORESec.Operation('accountPut') && (
                      <Suspense fallback={<div></div>}>
                        <AccountModal
                          mode="edit"
                          dataRow={cell.row.original}
                          onRefresh={() => col.onRefresh()}
                          entity='accountGets'
                        />
                      </Suspense>
                    )
                  );
                case 'product':
                  return (
                    CORESec.Operation('productPut') && (
                      <Suspense fallback={<div></div>}>
                        <ProductModal
                          mode="edit"
                          dataRow={cell.row.original}
                          onRefresh={() => col.onRefresh()}
                        />
                      </Suspense>
                    )
                  );
                default:
                  return;
              }
            }
          };
          const getOperation = (operationName) => {
            // Validación de operaciones coresec
            switch (operationName) {
              case 'accountDelete':
                return CORESec.Operation('accountDelete');
              case 'accountEventDelete':
                return CORESec.Operation('accountEventDelete');
              case 'accountEventNoteDelete':
                return CORESec.Operation('accountEventNoteDelete');
              case 'accountEventChildDelete':
                return CORESec.Operation('accountEventChildDelete');
              case 'accountOpportunityDelete':
                return CORESec.Operation('accountOpportunityDelete');
              case 'accountOpportunityProductDelete':
                return CORESec.Operation('accountOpportunityProductDelete');
              case 'accountNoteDelete':
                return CORESec.Operation('accountNoteDelete');
              case 'accountContactDelete':
                return CORESec.Operation('accountContactDelete');
              case 'accountBussinessUnitProductDelete':
                return CORESec.Operation('accountBussinessUnitProductDelete');
              case 'accountBussinessUnitOtherProductDelete':
                return CORESec.Operation(
                  'accountBussinessUnitOtherProductDelete'
                );
              case 'accountContactInfoDelete':
                return CORESec.Operation('accountContactInfoDelete');
              case 'accountAddressDelete':
                return CORESec.Operation('accountAddressDelete');
              case 'productDelete':
                return CORESec.Operation('productDelete');
              default:
                return false;
            }
          };

          return (
            <div style={actionStyles}>
              {!col.deleteOnly && getEntityComponent(cell.state.entity)}

              {getOperation(cell.initialState.operationName) && (
                <SHButton
                  action="delete"
                  classType="icon"
                  onClick={() =>
                    handleDelete(
                      cell.initialState.btnDeleteFrom,
                      cell.row.values,
                      () => col.onRefresh()
                    )
                  }
                />
              )}
            </div>
          );
        },
      };
    } else if (col.boolType) {
      // COLUMNA CON ICONOS TRUE/FALSE
      return {
        Header: () => <div style={headerStyles}>{col.header}</div>,
        width: 18,
        sortType: (a, b) => { // Orden de iconos
          var firstValue;
          firstValue = a.values[col.key];
          return firstValue === false ? -1 : (firstValue === true ? 1 : 0);
        },
        accessor: col.key,
        Cell: (cell) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: '100%',
                height: '100%',
                alignItems: 'center',
              }}
            >
              {cell.value ? (
                <SHIcon type="check-circle" color="#6fb321" />
              ) : (
                <SHIcon type="circle-x" color="#dc3545" />
              )}
            </div>
          );
        },
      };
    } else {
      // RESTO DE COLUMNAS
      return {
        Header: () => <div style={headerStyles}>{col.header}</div>,
        Footer: () => <div style={footerStyles}> {col.header} </div>,
        accessor: col.key,
        width: col.width ? col.width : 30,
        minWidth: col.minWidth ? col.minWidth : 5,
        maxWidth: col.maxWidth ? col.maxWidth : 50,
        sortType: col.id ? 'alphanumeric' : (a, b) => {
          if (col.key === 'importanceString' || col.key === 'potentialString') { // Orden de Potencial e importancia
            let firstRowValue, secondRowValue;
            firstRowValue = a.values[col.key] === 'Alto' ? 1 : (a.values[col.key] === 'Bajo' ? -1 : 0);
            secondRowValue = b.values[col.key] === 'Alto' ? 1 : (b.values[col.key] === 'Bajo' ? -1 : 0);
            return firstRowValue < secondRowValue ? -1 : (firstRowValue > secondRowValue ? 1 : 0);
          }
          else if(col.sort === undefined) { // Orden de alfanuméricos
            if(a.values[col.key] === null || a.values[col.key] === undefined || b.values[col.key] === null || b.values[col.key] === undefined) {
              return -1
            }
            return a.values[col.key].toLowerCase().localeCompare(b.values[col.key].toLowerCase())
          }
          else if (col.sort) { // Orden de Fechas
            let firstRowValue, secondRowValue;
            firstRowValue = a.values[col.key].split('-').reverse().join();
            secondRowValue = b.values[col.key].split('-').reverse().join();
            return firstRowValue < secondRowValue ? -1 : (firstRowValue > secondRowValue ? 1 : 0);
          }
        },
        Cell: (cell) => (
          <div
            style={{
              display: 'flex',
              justifyContent: col.align ? col.align : 'start',
              textAlign: col.align === 'center' ? 'center' : 'initial',
              alignItems: 'center',
              height: '100%',
            }}
          >
            { col.maxCharacters ? maxCharactersString(cell.value, col.maxCharacters) : cell.value }
          </div>
        ),
      };
    }
  });
  return useMemo(() => columnArray, [columnArray]);
}
