import React, { useContext, useState } from 'react';

// Textos
import { TextsContext } from '../../../../context/TextsContext';

import { NavLink, useNavigate } from 'react-router-dom';

import { Config } from '../../../../env/Config';
import { CORESec } from '../../../../env/CORESec';

import SHIcon from '../../../SHIcon/SHIcon';

import './showUser.css';
import { Fetch } from '../../../../env/Fetch';

function ShowUser() {

    const dataText = useContext(TextsContext)

    const navigation = useNavigate()
    const [currentUser, setCurrentUser] = useState()
    const [accessLevel, setAccessLevel] = useState('')

    const refreshUserInfo = () => {
        setCurrentUser(CORESec.GetCurrentUser)
        setAccessLevel(CORESec.GetCountryAccessLevels())
    }

    const onCountryChanged = (e) => {
        if (e.target.innerText === 'ARG') {
            Config.WriteLS('country', { code: "ARG", id: 1 })
        }
        else if (e.target.innerText === 'BRA') {
            Config.WriteLS('country', { code: "BRA", id: 2 })
        }
        navigation(0)
    }

    const onLangChanged = async (e) => {
        const init = { method: 'GET', headers: Fetch.HEADERS(null, e.target.innerText.toLowerCase()) }

        let url = `${Config.WebAPI}/Environment/PropertyValue`

        try {
            const response = await fetch(url, init);
            const json = await response.json();

            Config.WriteLS('lang', e.target.innerText)
            Config.WriteLS('props',json.return)
            
            return navigation(0)
        }
        catch(error) {
            console.log(error);
        }

    }

    return (
        <div className="btn-group">
            <button className="btn btn-sm dropdown-toggle user-icon" type="button" onClick={refreshUserInfo} data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                <SHIcon type='userCircle' size='22' />
            </button>
            <ul 
                className="dropdown-menu dropdown-menu-end navbar-custom" 
                style={{margin: 0}} // Este margin fixea un warning de Popper
            >
                <li className='px-3'>
                    <p className='m-0 text-center text-muted d-flex align-items-center justify-content-center'>
                        <SHIcon type='person' size='16' /> {currentUser ? currentUser.username : ''}
                    </p>
                    <p className='m-0 text-center'>{`${currentUser ? currentUser.firstName : ''} ${currentUser ? currentUser.lastName : ''}`}</p>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <div className="px-3 d-flex justify-content-between align-items-center">
                        <span style={{ whiteSpace: 'nowrap', cursor: 'default' }}>
                            <SHIcon type='web' iconClass='text-muted me-1' size='16' />
                            {dataText.get("user.countryOfOperation")}</span>
                        {accessLevel && accessLevel.countries.length === 2 &&
                            <div className="btn-group ms-2" role="group">
                                <label id={'country-arg'} className={`btn btn-sm btn-outline-secondary ${accessLevel.code === "ARG" ? 'disable-option' : 'active-option'}`} onClick={accessLevel.code !== "ARG" ? onCountryChanged : undefined}>ARG</label>
                                <label id={'country-bra'} className={`btn btn-sm btn-outline-secondary ${accessLevel.code === "BRA" ? 'disable-option' : 'active-option'}`} onClick={accessLevel.code !== "BRA" ? onCountryChanged : undefined}>BRA</label>
                            </div>}
                    </div>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <div className="px-3 d-flex justify-content-between align-items-center">
                        <span style={{ whiteSpace: 'nowrap', cursor: 'default' }}>
                            <SHIcon type='lang' iconClass='text-muted me-1' size='16' />
                            {dataText.get("user.language")}
                        </span>
                        <div className="btn-group ms-2" role="group">
                            <label id={'lang-es'} className={`btn btn-sm btn-outline-secondary ${Config.ReadLS('lang') === 'ES' ? 'disable-option' : 'active-option'}`} onClick={Config.ReadLS('lang') !== 'ES' ? onLangChanged : undefined}>ES</label>
                            <label id={'lang-en'} className={`btn btn-sm btn-outline-secondary ${Config.ReadLS('lang') === 'EN' ? 'disable-option' : 'active-option'}`} onClick={Config.ReadLS('lang') !== 'EN' ? onLangChanged : undefined}>EN</label>
                            <label id={'lang-pt'} className={`btn btn-sm btn-outline-secondary ${Config.ReadLS('lang') === 'PT' ? 'disable-option' : 'active-option'}`} onClick={Config.ReadLS('lang') !== 'PT' ? onLangChanged : undefined}>PT</label>
                        </div>
                    </div>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <NavLink className="dropdown-item d-flex justify-content-start align-items-center" to="/">
                        <SHIcon type='lock' iconClass='text-muted me-1' size='16' />
                        {dataText.get("user.changePassword")}
                    </NavLink>
                </li>
                <li>
                    <NavLink className="dropdown-item d-flex justify-content-start align-items-center" to={`/${Config.Version}/logout`}>
                        <SHIcon type='logout' iconClass='text-muted me-1' size='16' />
                        {dataText.get("user.logout")}
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default React.memo(ShowUser)