import React from 'react'

import SHBreadCrumb from '../SHBreadCrumb/SHBreadCrumb';

// Estilos
import './SHFooter.css'

function SHFooter({
    copyright,
    version
}) {
    return (
        <nav className="navbar fixed-bottom navbar-dark p-1 footer-bg">
            <div className="container-fluid justify-content-between">
                <span className="span-style">
                    <a className='navbar-brand link-style' href="/">
                        {copyright}
                    </a>
                    {version}
                </span>
                <SHBreadCrumb />
            </div>
        </nav>
    )
}

export default React.memo(SHFooter)