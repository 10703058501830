import { Config } from "../../env/Config";
import { Fetch } from "../../env/Fetch";

const GETFilterRows = async (url, query) => {
    // Array para agregar todos los valores formateados
        let queryArray = [];

        // Guardamos dentro de queryArray y así transformamos el Map Object en un array común (más fácil de manejar)
        for (let [key, value] of query) {
            queryArray = [...queryArray, { [key]: value }];
        }

        // Array para agregar arrays formateados con sus valores numéricos
        let queryString = [];

        queryArray.forEach((obj) => {
            if (
                Array.isArray(Object.values(obj)[0]) &&
                Object.values(obj)[0].length > 0
            ) {
                // Si es un array y su longitud es mayor a 0...
                return Object.values(obj)[0].map((val) => {
                return (queryString = [
                    ...queryString,
                    `${Object.keys(obj)}=${val.value}`,
                ]); // Devuelve valores formateados para arrays
                });
            } else {
                if (
                Object.values(obj)[0] !== '' &&
                !Array.isArray(Object.values(obj)[0])
                ) {
                // Si no esta vacío y no es un array...
                return (queryString = [
                    ...queryString,
                    `${Object.keys(obj)}=${Object.values(obj)[0]}`,
                ]); // Devuelve valores formateados para strings
                }
            }
        });

    // Convertimos el array a string y reemplazamos ',' por '&'
        const filterQueryString = queryString.toString().replaceAll(',', '&');

        const fetchURL = filterQueryString ? `${Config.WebAPI + url}?${filterQueryString}` : `${Config.WebAPI + url}`;

        let init = { method: 'GET', headers: Fetch.HEADERS() };

        const fetchResponse = await fetch(fetchURL, init);
        const fetchedData = await fetchResponse.json();

        Fetch.DEBUGFetch(fetchURL, fetchedData);

        return fetchedData.return;
    };

export default GETFilterRows;