import { Config } from '../env/Config';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';

const GetNotifications = async () => {
    const INIT = {
        method: 'GET',
        headers: Fetch.HEADERS(),
    };

    let url = `${Config.WebAPI}/Notification?viewAll=true`;
    
    try {
        const fetching = await fetch(
        url,
        INIT
        );
        const fetchedData = await fetching.json();
        Fetch.DEBUGFetch(url, fetchedData)
        return fetchedData
    } catch (err) {
        console.log(err);
    }
}

const PutNotification = async (id) => {
    const init = {
        method: 'PUT',
        headers: Fetch.HEADERS(),
        body: JSON.stringify(id),
    };
    try {
        const fetchData = await fetch(`${Config.WebAPI}/Notification/modify?NotificationID=${id}`, init)
        const fetchJson = await fetchData.json();
        return fetchJson;
    } catch(err) {
        console.log(err)
    }
}

const PutViewAllNotification = () => {
    const InitPut = {
        method: 'PUT',
        headers: Fetch.HEADERS(),
    };
    return fetch(`${Config.WebAPI}/Notification/modify?viewAll=true`, InitPut)
    .then(res => res.json())
}

const DeleteNotification = async (id) => {
    return new Promise(function (resolve) {
        const url = `${Config.WebAPI}/Notification/${id}`

        let init = { method: 'GET', headers: Fetch.HEADERS() }

        fetch(url, init)
            .then(response => response.json())
            .then(fetchedData => {
                if (fetchedData.return.canBeDeleted) {
                    Texts.ShowConfirmation('dialogs.confirmation.sureDelete', 'dialogs.confirmation.sureDelete.extra')
                        .then(c => {
                            if (c) {
                                Fetch.DELETEFetch(`${Config.WebAPI}/Notification/${id}`, id)
                                    .then(rtn => { console.log(rtn); resolve(rtn) })
                                    .then(Texts.Toast())
                            }
                        })
                }
                else {
                    Texts.ShowValidation('dialogs.information.cannotBeDeleted', '')
                }
            })
    })
}

export const notificationService = {
    GetNotifications,
    PutNotification,
    PutViewAllNotification,
    DeleteNotification,
}