// Librerías de terceros
import React, { useState, lazy, Suspense } from 'react';

// Router
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// Context
import { TextsContext, texts } from './context/TextsContext';

// Librerías SH

import { environmentService } from './services/environmentService';

// Componentes SH
import SHFooter from './components/SHFooter/SHFooter';
import SHLoading from './components/SHLoading/SHLoading';
import SHNavbar from './components/SHNavbar/SHNavbar';
import SHPrivateRoute from './components/SHPrivateRoute/SHPrivateRoute';

// Estilos
import './assets/globalStyles/App.css';

// Vistas SH
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import AccountGet from './pages/Account/AccountGet';
import Search from './pages/Search/Search';
import { Config } from './env/Config';

const AccountGets = lazy(() => import('./pages/Account/AccountGets'));
const EventGets = lazy(() => import('./pages/Event/EventGets'));
const OpportunityGets = lazy(() =>
  import('./pages/Opportunity/OpportunityGets')
);
const ProductGets = lazy(() => import('./pages/Product/ProductGets'));
const ContactGets = lazy(() => import('./pages/Contact/ContactGets'));

const QuotedProductsReports = lazy(() =>
  import('./pages/Reports/QuotedProductsReports')
);
const AccountsReports = lazy(() => import('./pages/Reports/AccountsReports'));
const AccountsAnalysis = lazy(() => import('./pages/Reports/AccountsAnalysis'));
const SummaryAccounts = lazy(() => import('./pages/Reports/SummaryAccounts'))

export default function App() {
  let location = useLocation();

  const [loading, setLoading] = useState(true);

  environmentService.Dummy().then(() => {
    setLoading(false);
  });

  if (loading) return <SHLoading />;

  return (
    <div className="App">
        <TextsContext.Provider value={texts}>
          {location.pathname !== `/${Config.Version}/login` && (
            <SHNavbar brandName="ENSOL" brandLogo="/logoBasic.png" />
          )}

          <div className="container-fluid application">
            <Suspense fallback={<SHLoading />}>
              <Routes>
                <Route
                  path={`/`}
                  element={
                    <SHPrivateRoute>
                      <Navigate to={`/${Config.Version}/account`} replace />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/login`}
                  element={<Login />}
                  exact
                />
                <Route
                  path={`/${Config.Version}/account`}
                  element={
                    <SHPrivateRoute>
                      <AccountGets />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/account/get/:id/*`}
                  element={
                    <SHPrivateRoute>
                      <AccountGet />
                    </SHPrivateRoute>
                  }
                >
                  <Route path={`event/:accountEventID`} element />
                  <Route path={`opportunity/:accountOpportunityID`} element />
                  <Route path={`note/:accountNoteID`} element />
                  <Route path={`businessUnit`} element />
                  <Route path={`address/:accountAddressID`} element />
                  <Route path={`contact/:accountContactID`} element />
                </Route>
                <Route
                  path={`/${Config.Version}/event`}
                  element={
                    <SHPrivateRoute>
                      <EventGets />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/opportunity`}
                  element={
                    <SHPrivateRoute>
                      <OpportunityGets />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/products`}
                  element={
                    <SHPrivateRoute>
                      <ProductGets />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/contact`}
                  element={
                    <SHPrivateRoute>
                      <ContactGets />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/reports/accountsReports`}
                  element={
                    <SHPrivateRoute>
                      <AccountsReports />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/reports/accountsAnalysis`}
                  element={
                    <SHPrivateRoute>
                      <AccountsAnalysis />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/reports/quotedProductsReports`}
                  element={
                    <SHPrivateRoute>
                      <QuotedProductsReports />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/reports/summaryAccounts`}
                  element={
                    <SHPrivateRoute>
                      <SummaryAccounts />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/search/:value`}
                  element={
                    <SHPrivateRoute>
                      <Search />
                    </SHPrivateRoute>
                  }
                />
                <Route
                  path={`/${Config.Version}/logout`}
                  element={<Login logout />}
                  exact
                />
                <Route
                  path="*"
                  element={
                    <SHPrivateRoute>
                      <NotFound />
                    </SHPrivateRoute>
                  }
                />
              </Routes>
            </Suspense>
          </div>
          {location.pathname !== `/${Config.Version}/login` && (
            <SHFooter
              copyright="COPYRIGHT © 2021 --"
              version={`${Config.Version} - ${Config.ReleaseDate} (WIP=${Config.WIP})`}
            />
          )}
        </TextsContext.Provider>
    </div>
  );
}
