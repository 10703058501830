import * as yup from 'yup';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';
import GETFilterRows from '../assets/utils/GETFilterRows';
import { Config } from '../env/Config';

const GetAccountEventNotes = () => { return Fetch.GETFetch(`${Config.WebAPI}/AccountEventNote`) }
const GetAccountEventNote = (id) => { return Fetch.GETFetch(`${Config.WebAPI}/AccountEventNote/${id}`) }

const PostAccountEventNote = async (data) => { 
    
    return new Promise(function (resolve){
        accountEventNoteSchema.isValid(data)
        .then(valid =>{
            if (!valid) {
                accountEventNoteSchema.validate(data, { abortEarly: false }) 
                .catch(result =>  { 
                    Texts.ShowValidations('AccountEventNote', result)
                    console.log(result);
                })
                resolve(false)
            }
            else {
                Fetch.POSTFetch(`${Config.WebAPI}/AccountEventNote`, data)
                .then(rtn => { console.log(rtn); resolve(rtn) })
                .then(Texts.Toast('new'))
            }
        })
    })
}

const PutAccountEventNote = async (id, data) => { 
    
    return new Promise(function (resolve){
        accountEventNoteSchema.isValid(data)
        .then(valid =>{
            if (!valid) {
                accountEventNoteSchema.validate(data, { abortEarly: false }) 
                .catch(result =>  { 
                    Texts.ShowValidations('AccountEventNote', result)
                    console.log(result);
                })
                resolve(false)
            }
            else {
                Fetch.PUTFetch(`${Config.WebAPI}/AccountEventNote/${id}`, data)
                .then(rtn => { console.log(rtn); resolve(rtn) })
                .then(Texts.Toast('edit'))
            }
        })
    })
}

const GetFilterAccountEventNote = async (query) => {
    return await GETFilterRows('/AccountEventNote', query)
}

const DeleteAccountEventNote = async (id) => { 
    return new Promise(function (resolve){
        const url = `${Config.WebAPI}/AccountEventNote/${id}`

        let init = { method: 'GET', headers: Fetch.HEADERS() }

        fetch(url, init)
		.then(response => response.json())
		.then(fetchedData => {
            if (fetchedData.return.canBeDeleted) {
                Texts.ShowConfirmation('dialogs.confirmation.sureDelete', 'dialogs.confirmation.sureDelete.extra')
                .then(c => {
                    if(c){
                        Fetch.DELETEFetch(`${Config.WebAPI}/AccountEventNote/${id}`, id)
                        .then(rtn => { 
                            console.log(rtn);
                            resolve(rtn);
                            Texts.Toast()
                        })
                    }
                })
            }
            else {
                Texts.ShowValidation('dialogs.information.cannotBeDeleted', 'dialogs.information.cannotBeDeleted.extra', fetchedData.return.associatedRows)
            }
        })
    })
}

// eslint-disable-next-line no-unused-vars
let accountEventNoteSchema = yup.object().shape({
    accountEventID: yup.number().min(1).required(),
    title: yup.string().nullable().max(1500).required(),
    description: yup.string().nullable().max(1500).required(),
    private: yup.bool().required(),
    archived: yup.bool().required(),
});

export const accountEventNoteService = {
    GetAccountEventNotes,
    GetAccountEventNote,
    GetFilterAccountEventNote,
    PostAccountEventNote,
    PutAccountEventNote,
    DeleteAccountEventNote,
}