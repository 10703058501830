import * as yup from 'yup';
import { Config } from '../env/Config';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';

// const GetAccountsProduct = () => { return Fetch.GETFetch(`${Config.WebAPI}/AccountProduct`) }
const GetProductList = (id) => {
  return Fetch.GETFetch(`${Config.WebAPI}/Product/ProductList?accountID=${id}`);
};
const GetAccountProduct = (id) => {
  return Fetch.GETFetch(`${Config.WebAPI}/AccountProduct/${id}`);
};

const GetAccountProductsByAccount = (id) => {
  return Fetch.GETFetch(`${Config.WebAPI}/AccountProduct/Account${id}`);
};
// TODO Resolver la invocacion del accountProduct/account agregandole la / al final

const PostAccountProduct = async (data) => {
  return new Promise(function (resolve) {
    accountProductSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountProductSchema
          .validate(data, { abortEarly: false })
          .catch((result) => {
            Texts.ShowValidations('accountProduct', result);
            console.log(result);
          });
        resolve(false);
      } else {
        Fetch.POSTFetch(`${Config.WebAPI}/AccountProduct`, data)
          .then((rtn) => {
            console.log(rtn);
            resolve(rtn);
          })
          .then(Texts.Toast('new'));
      }
    });
  });
};

const PutAccountProduct = async (id, data) => {
  return new Promise(function (resolve) {
    accountProductSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountProductSchema
          .validate(data, { abortEarly: false })
          .catch((result) => {
            Texts.ShowValidations('accountProduct', result);
            console.log(result);
          });
        resolve(false);
      } else {
        Fetch.PUTFetch(`${Config.WebAPI}/AccountProduct/${id}`, data)
          .then((rtn) => {
            console.log(rtn);
            resolve(rtn);
          })
          .then(Texts.Toast('edit'));
      }
    });
  });
};

const DeleteAccountProduct = async (id) => {
  return new Promise(function (resolve) {
    const url = `${Config.WebAPI}/AccountProduct/${id}`;

    let init = { method: 'GET', headers: Fetch.HEADERS() };

    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.return.canBeDeleted) {
          Texts.ShowConfirmation(
            'dialogs.confirmation.sureDelete',
            'dialogs.confirmation.sureDelete.extra'
          ).then((c) => {
            if (c) {
              Fetch.DELETEFetch(`${Config.WebAPI}/AccountProduct/${id}`, id)
                .then((rtn) => {
                  console.log(rtn);
                  resolve(rtn);
                })
                .then(Texts.Toast());
            }
          });
        } else {
          Texts.ShowValidation('dialogs.information.cannotBeDeleted', '');
        }
      });
  });
};

let accountProductSchema = yup.object().shape({
  accountID: yup.number().min(1).required(),
  productID: yup.number().nullable().min(1).required(),
  measurementUnitID: yup.number().nullable().min(1).required(),
  quantityByUser: yup.number().nullable().min(0.1).required(),
  description: yup.string().nullable().max(200),
});

export const accountProductService = {
  GetProductList,
  GetAccountProduct,
  GetAccountProductsByAccount,
  PostAccountProduct,
  PutAccountProduct,
  DeleteAccountProduct,
};
