import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";

import App from './App';

// Estilos
import './assets/globalStyles/index.css'; // Estilos custom
import 'bootstrap/dist/css/bootstrap.min.css'; // Le da estilos a los componentes de Bootstrap
// import 'bootstrap/dist/js/bootstrap.bundle.js'; // Necesario para desplegar formularios modales en las vistas
import 'bootstrap/dist/js/bootstrap.min.js';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);