import React, { useState } from 'react';

// Componentes
import SHButton from '../SHButton/SHButton';

// Estilos
import './SHScrollTop.css';

function SHScrollTop() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.addEventListener('scroll', checkScrollTop);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="scrollTop" style={{ padding: '0 0 0 5px' }}>
      <SHButton
        classType="icon"
        action="scrollTop"
        className="btn-up"
        onClick={() => scrollTop()}
      />
    </div>
  );
}

export default React.memo(SHScrollTop);
