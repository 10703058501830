import React, { useState } from 'react';

// Componentes
import SHIcon from '../SHIcon/SHIcon';

// Estilos
import './SHCollapse.css';

function SHCollapse({
  bsClassName,
  children,
  header,
  hsize,
  openModal,
  onClick,
}) {
  const [toggle, setToggle] = useState(openModal);

  const handleToggle = (e) => {
    setToggle(!toggle);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <>
      <div
        id="accordion"
        className={bsClassName ? bsClassName : 'm-0'}
        onClick={() => (onClick ? onClick() : () => {})}
      >
        <div className="card">
          <div
            className="card-header d-flex align-items-center justify-content-between"
            id="headingOne"
            onClick={handleToggle}
          >
            <h6 className={hsize ? hsize : 'mb-0'}>{header}</h6>
            <button
              className="btn btn-sm p-0"
              aria-expanded={toggle}
              aria-controls="collapseOne"
              style={{ boxShadow: 'none' }}
              onClick={() => (onClick ? onClick() : () => {})}
            >
              <SHIcon type={toggle ? 'chevron-up' : 'chevron-down'} />
            </button>
          </div>

          <div
            id="collapseOne"
            onKeyPress={handleEnterKey}
            className={`collapse ${toggle ? 'show' : ''}`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordion"
          >
            <div className="card-body pt-1">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SHCollapse;
