import React from 'react';

// Componentes
import SHLabel from '../SHLabel/SHLabel';

// Estilos
import './SHSelect.css'

// Otros
import Select from 'react-select';
import { Config } from '../../env/Config';

function SHSelect({
  size,
  label,
  name,
  id,
  isDisabled,
  placeholder,
  options,
  onChange,
  defaultValue,
  value,
  className,
  single,
  isSearchable,
  isClearable,
  noSelectionText,
}) {

  let optionsSelect = options;

  function wordsAccordingToLanguage(word) {
    if(word === 'Todos') {
      switch (Config.ReadLS('lang')) {
        case 'EN':
          return 'All';
        case 'PT':
          return 'Tudo';
        default:
          return 'Todos';
      }
    } else {
      switch (Config.ReadLS('lang')) {
        case 'EN':
          return 'Select...';
        case 'PT':
          return 'Selecionar...';
        default:
          return 'Seleccione...';
      }
    }
  }

  // Si es single-select lleva la opcion extra "Todos"
  if (single && optionsSelect) {
    // optionsSelect = [selectAllOption ? { value: -1, label: noSelectionText ? noSelectionText : wordsAccordingToLanguage('Todos') } : [], ...options];
    optionsSelect = [[], ...options];
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '30px',
      height: 'auto',
      boxShadow: state.isFocused ? "0 0 0 0.25rem rgb(13 110 253 / 25%)" : 0,
      borderColor: state.isFocused ? "#86b7fe" : "#CED4DA",
      "&:hover": {
        borderColor: state.isFocused ? "#86b7fe" : "#CED4DA"
      }
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: '29px',
      padding: '0 6px',
      fontSize: '0.875rem',
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      alignSelf: 'stretch',
      backgroundColor: '#cccccc',
      marginTop: '5px',
      marginBottom: '5px',
      boxSizing: 'border-box',
      minHeight: '20px',
      width: '1px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      alignSelf: 'center',
      height: '29px',
    }),
    menu: (provided, state) => ({
      ...provided,
      lineHeight: '0.8rem',
      border: '1px solid #ced4da94',
      fontSize: '0.875rem'
    })
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <div className={size}>
      {label ? <SHLabel id={id}>{label}</SHLabel> : null}
      <Select
        name={name} // nombre del componente (distinto a label)
        id={id} // id del componente
        isDisabled={isDisabled}// tipo Bool. Si pasamos True el input estará deshabilitado
        placeholder={(!single && !placeholder) ? wordsAccordingToLanguage('Todos') : (!placeholder ? wordsAccordingToLanguage('Seleccione...') : placeholder)} // Si pasamos esta prop mostrará el placeholder, sino ""
        options={optionsSelect} // Ver especificación de propiedades en la intro del componente
        onChange={onChange} // al onChange se le pueden pasar dos parámetros para que tome el tag Name
        defaultValue={defaultValue} // valor por defecto
        value={value}
        classNamePrefix="react-select" // customización del componente
        className={className} // className opcional
        menuPlacement="auto" // customización del componente
        maxMenuHeight={90} // Height del menú de opciones
        styles={customStyles} // Estilos customizados del componente
        formatGroupLabel={formatGroupLabel} //para opciones agrupadas
        isMulti={!single} // Por defecto es un multiselect
        isSearchable={isSearchable} // tipo Bool. Por defecto es true
        isClearable={isClearable} // tipo Bool. Por defecto es true. Quita la x de limpiar el input
      />
    </div>
  )
}

export default SHSelect;