import React, { useContext } from 'react';
import { TextsContext } from '../../context/TextsContext';

// Componentes
import ButtonModel from './components/ButtonModel'

// Estilos
import './SHButton.css'

function SHButton({
    action,
    classType,
    disabled,
    margin,
    onClick,
    onChange,
    text,
    type,
    dataBsToggle,
    dataBsTarget,
    width,
    backgroundColor,
    dismiss,
    fontColor
}) {

    const dataText = useContext(TextsContext)

    switch (action) {
        case "accept":
            return <ButtonModel
                text={text ? text : dataText.get('button.accept')} dismiss={dismiss ? 'modal' : null} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-primary'} icon='check-circle' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} dataBsToggle={dataBsToggle ? 'modal' : null} dataBsTarget={dataBsTarget} /> // props a definir en la invocación

        case "acceptContinue":
            return <ButtonModel
                text={dataText.get('button.acceptAndContinue')} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-success'} icon='forward' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "add":
            return <ButtonModel
                text={text ? text : dataText.get('button.add')} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-primary'} icon='add' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "attachment":
            return <ButtonModel
                text={text ? text : dataText.get('button.attachment')} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-success'} icon='attachment' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType}  onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "edit":
            return <ButtonModel
                text={text ? text : dataText.get('button.edit')} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-primary'} icon='edit' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={(onClick)} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} dataBsToggle={dataBsToggle ? 'modal' : null} // props a definir en la invocación
                dataBsTarget={dataBsTarget} /> // props a definir en la invocación

        case "back":
            return <ButtonModel
                fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-secondary'} icon='back' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "cancel":
            return <ButtonModel
                text={text ? text : dataText.get('button.cancel')} dismiss={'modal'} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-danger'} icon='error' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "clean":
            return <ButtonModel
                text={text ? text : dataText.get("button.deleteFilter")} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-secondary'} icon='clean' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "config":
            return <ButtonModel
                text={text ? text : dataText.get("button.config")} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-warning'} icon='config' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "collapse-on":
            return <ButtonModel
                fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-secondary'} icon='chevron-up' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "collapse-off":
            return <ButtonModel
                fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-secondary'} icon='chevron-down' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "create":
            return <ButtonModel
                text={text ? text : dataText.get("button.create")} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-success'} icon='add' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "delete":
            return <ButtonModel
                text={text ? text : dataText.get('button.delete')} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-danger'} icon='trash' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "export":
            return <ButtonModel
                text={text ? text : dataText.get("button.export")} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-success'} icon='export' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        // case "history":
        //     return <ButtonModel 
        //         text={text ? text : 'Historial'} backgroundColor='btn-secondary' icon='history' // props generales a definir acá: texto/backgroundColor/ícono
        //         classType={classType} type={type} onClick={onClick} // props a definir en la invocación
        //         margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "login":
            return <ButtonModel
                text={text ? text : dataText.get("login.getInto")} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-primary'} icon='login' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "search":
            return <ButtonModel
                text={text ? text : dataText.get('button.search')} dismiss={'modal'} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-primary'} icon='search' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "filter":
            return <ButtonModel
                text={text ? text : dataText.get('button.filter')} dismiss={'modal'} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-primary'} icon='search' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "refresh":
            return <ButtonModel
                text={text ? text : dataText.get('button.refresh')} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-secondary'} icon='refresh' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "scrollTop":
            return <ButtonModel
                fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-secondary'} icon='scrollTop' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        case "download":
            return <ButtonModel
                fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-primary'} icon='download' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación

        default:
            return <ButtonModel
                text={text} fontColor={fontColor} backgroundColor={backgroundColor ? backgroundColor : 'btn-primary'} icon='info' // props generales a definir acá: texto/backgroundColor/ícono
                classType={classType} type={type} onClick={onClick} // props a definir en la invocación
                margin={margin} disabled={disabled} width={width} /> // props a definir en la invocación
    }
}

export default React.memo(SHButton)