import React, { useContext } from 'react';
import { TextsContext } from '../../context/TextsContext';
import SHLoading from '../SHLoading/SHLoading';

const SHErrorBoundary = ({children}) => {
    const dataText = useContext(TextsContext)

    const wrongURL = `/get/0`; // Al crear algo y que salga mal lleva al ID 0;
    let currentURL = window.location.href;
    let index = currentURL.indexOf(wrongURL); // Chequea si el URL contiene el "wrongURL"

    return <ErrorBoundary IdEqualToZero={index} thereWasAError={dataText.get('error.thereWasAError')} reloadPage={dataText.get('error.reloadPage')} redirectText={dataText.get('error.redirect')} >
        {children}
    </ErrorBoundary>
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false, 
            messageError: ''
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, messageError: error.message };
    }

    componentDidCatch(error) {
        console.log("Error Boundary - Error captado:", error.message);

        setTimeout(() => {
            if(this.props.IdEqualToZero !== -1) {
                window.location.replace('')
            } else {
                window.location.reload()
            }
        }, 5000);
    }

    render() {
        if (this.state.hasError) { // "UI de emergencia"
            return (
                <div className="px-4 py-2 m-4" style={{display: "flex", flexDirection:'column', justifyContent: "center", alignItems: "center"}}>
                <h1 className="mt-2 font-bold text-lg mb-1">{this.props.thereWasAError}</h1>
                {
                    this.props.IdEqualToZero !== -1 ?
                    <b> {this.props.redirectText} </b>
                    :
                    <b> {this.props.reloadPage} </b>
                }
                <SHLoading />
                </div>
            );
        }
        // Componente
        return this.props.children;
    }
}

export default SHErrorBoundary;
