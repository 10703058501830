import React, { useState, useContext, useEffect } from 'react';

// Context
import { TextsContext } from '../../../../context/TextsContext';

import { useNavigate } from 'react-router-dom';

import { CORESec } from '../../../../env/CORESec';

// Componentes propios
import SHButton from '../../../../components/SHButton/SHButton';
import SHInput from '../../../../components/SHInput/SHInput';
import SHLoading from '../../../../components/SHLoading/SHLoading';
import SHSelect from '../../../../components/SHSelect/SHSelect';
import SHContainer from '../../../../components/SHContainer/SHContainer';

import { environmentService } from '../../../../services/environmentService';
import { accountProductService } from '../../../../services/accountProductService';
import Swal from 'sweetalert2';
import { Config } from '../../../../env/Config';

export default function AccountGetBusinessUnitsForm({
  mode,
  rowData,
  dataOptions,
  back,
  rowAccountID,
  refreshProducts,
}) {
  const dataText = useContext(TextsContext);

  const navigate = useNavigate();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [detectInputChange, setDetectInputChange] = useState();
  const [quantity, setQuantity] = useState(
    mode === 'edit' && rowData !== null ? rowData.quantityByUser : ''
  );
  const [description, setDescription] = useState(
    mode === 'edit' && rowData !== null ? rowData.description : ''
  );

  const [selectedOptionProduct, setSelectedOptionProduct] = useState([]);
  const [selectedOptionUnit, setSelectedOptionUnit] = useState(
    mode === 'edit' && rowData !== null
      ? environmentService.GetOption(
          dataOptions.measurementUnit,
          rowData.measurementUnitID
        )
      : []
  );

  const { data: productList, loading } =
    accountProductService.GetProductList(rowAccountID);

  const formatProductList = (productList) => {
    const productListFormatted = productList.map((obj) => {
      return {
        label: obj.valueName,
        value: obj.tableValueID,
      };
    });
    return productListFormatted;
  };

  useEffect(() => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    }
  }, [productList, mode, rowData, navigate]);

  useEffect(() => {
    if (disabledSubmit) {
      const disableSubmitTime = setTimeout(() => {
        setDisabledSubmit(false);
      }, 500);
      return () => clearTimeout(disableSubmitTime);
    }
  }, [disabledSubmit]);

  const handleChangeProduct = (e) => {
    setDetectInputChange(true);
    setSelectedOptionProduct(e);
  };
  const handleChangeUnit = (e) => {
    setDetectInputChange(true);
    setSelectedOptionUnit(e);
  };
  const handleChangeQuantity = (e) => {
    setDetectInputChange(true);
    setQuantity(e.target.value);
  };
  const handleChangeObservations = (e) => {
    setDetectInputChange(true);
    setDescription(e.target.value);
  };
  const handleDisabledSubmit = () => setDisabledSubmit(true);
  const backFromEditMode = () => {
    if (detectInputChange) {
      return Swal.fire({
        title: dataText.get('dialogs.back.sureBack'),
        text: dataText.get('dialogs.back.changes'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: dataText.get('button.accept'),
        cancelButtonText: dataText.get('button.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          back();
        } else if (result.isDenied) {
          return;
        }
      });
    } else return back();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleDisabledSubmit();

    const newData = {
      accountProductID: rowData ? rowData.accountProductID : 0,
      accountID: rowAccountID,
      productID: selectedOptionProduct.value || null,
      measurementUnitID: selectedOptionUnit.value || null,
      quantityByUser: Number(quantity) || null,
      description: description || null,
    };

    if (mode === 'new') {
      accountProductService.PostAccountProduct(newData).then((ok) => {
        if (ok) {
          refreshProducts();
          back();
        }
      });
    } else if (mode === 'edit') {
      newData.accountProductID = rowData.accountProductID;
      accountProductService
        .PutAccountProduct(newData.accountProductID, newData)
        .then((ok) => {
          if (ok) {
            refreshProducts();
            back();
          }
        });
    }
  };

  if (!CORESec.GetSessionStatus()) {
    navigate(-1);
    return <SHLoading />;
  }
  return (
    <SHContainer>
      <div className="businessUnits" id="element">
        <div className="d-flex justify-content-between align-content-center">
          <h6 className="m-0">
            {mode === 'new'
              ? dataText.get('accountGetBusinessUnit.addProduct')
              : `#${rowData.accountProductID || ''} - ${
                  rowData.productString || ''
                }`}
          </h6>
          <SHButton
            action="back"
            classType="icon"
            disabled={loading}
            onClick={() => backFromEditMode()}
          />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row g-2 align-items-center ">
            <div className="col-md-4">
              <SHSelect
                label={dataText.get('accountGetBusinessUnit.products')}
                name="product"
                id="product"
                options={productList && formatProductList(productList)}
                value={selectedOptionProduct}
                single
                onChange={handleChangeProduct}
                isDisabled={loading}
              />
            </div>
            <div className="col-md-2">
              <SHSelect
                label={dataText.get('accountGetBusinessUnit.measurementUnit')}
                name="unit"
                id="unit"
                options={dataOptions.measurementUnit || []}
                onChange={handleChangeUnit}
                value={selectedOptionUnit}
                single
              />
            </div>
            <div className="col-md-2">
              <SHInput
                type="number"
                step={`${selectedOptionUnit.label === 'TN' ? '0.1' : '1'}`}
                label={dataText.get('accountGetBusinessUnit.quantity')}
                name="quantity"
                id="quantity"
                min="1"
                onChange={handleChangeQuantity}
                value={quantity}
                disabled={selectedOptionUnit.length === 0}
              />
            </div>
            <div className="col-md-4">
              <SHInput
                label={dataText.get('AccountOpportunity.Observations')}
                name="observations"
                id="observations"
                type="textarea"
                rows="1"
                onChange={handleChangeObservations}
                value={description}
              />
            </div>
          </div>
          <br></br>
          <div className="d-flex justify-content-end align-items-center">
            <SHButton
              action="accept"
              classType="both"
              type="submit"
              disabled={loading || disabledSubmit}
            />
          </div>
        </form>
      </div>
    </SHContainer>
  );
}
