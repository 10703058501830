import React from 'react';

// Componentes
import SHIcon from '../../SHIcon/SHIcon';

// Estilos
import './ButtonModel.css'

function ButtonModel({
    backgroundColor,
    margin,
    disabled,
    onClick,
    classType,
    text,
    type,
    dismiss,
    icon,
    dataBsTarget,
    dataBsToggle,
    fontColor
}) {

    const comunClass = 'btn btn-sm d-flex align-items-center justify-content-center btn-primary" '; // Clases generales a todos los botones

    if (icon === 'edit') {
        return (
            <button
                data-bs-toggle={dataBsToggle}
                data-bs-target={dataBsTarget}
                // data-bs-toggle="modal"
                // data-bs-target={"#"}
                data-bs-dismiss={dismiss ? 'modal' : false}
                className={`${comunClass}  ${backgroundColor ? backgroundColor : null} ${classType === 'both' ? 'lineHeight' : ''} ${margin ? margin : ''}`} // Clase gral + backgroundColor gral definido en el switch + margin definido en invocación
                disabled={disabled} // definido en la invocación
                onClick={onClick} // definido en la invocación
                style={{ margin: margin ? margin : '', padding: classType === 'icon' ? '4.5px' : null, pointerEvents: disabled ? 'none' : 'auto', color: fontColor ? fontColor : '#fff', backgroundColor: backgroundColor ? backgroundColor : 'transparent' }} // si es tipo ícono le agrega padding para equiparar tamaños
                type={type ? type : 'button'} > {/* definido en la invocación */}
                {
                    classType !== 'icon' ? // Si NO es tipo ícono mostrará el texto (con margin-end en caso de ser acompañado por un ícono)
                        <span className={classType === 'both' ? 'me-1' : null}>{text}</span>
                        : null
                }
                {
                    classType === 'icon' || classType === 'both' ? <SHIcon type={icon} />
                        : null
                } {/* Mostrará ícono en caso de ser class icon/both */}
            </button>
        )
    } else {
        return (
            <button
                data-bs-dismiss={dismiss ? 'modal' : false}
                data-bs-toggle={dataBsToggle ? 'modal' : false}
                className={`${comunClass}  ${backgroundColor} ${classType === 'both' ? 'lineHeight' : ''} ${margin ? margin : ''}`} // Clase gral + backgroundColor gral definido en el switch + margin definido en invocación
                disabled={disabled} // definido en la invocación
                onClick={onClick} // definido en la invocación
                style={{ margin: margin ? margin : '', padding: classType === 'icon' ? '4.5px' : null, pointerEvents: disabled ? 'none' : 'auto', color: fontColor ? fontColor : '#fff', backgroundColor: backgroundColor ? backgroundColor : 'transparent' }} // si es tipo ícono le agrega padding para equiparar tamaños
                type={type ? type : 'button'} > {/* definido en la invocación */}
                {
                    classType !== 'icon' ? // Si NO es tipo ícono mostrará el texto (con margin-end en caso de ser acompañado por un ícono)
                        <span className={classType === 'both' ? 'me-1' : null}>{text}</span>
                        : null
                }
                {
                    classType === 'icon' || classType === 'both' ? <SHIcon type={icon} />
                        : null
                } {/* Mostrará ícono en caso de ser class icon/both */}
            </button>
        )
    }

}

export default ButtonModel;