// Esta función se utiliza en los services para enviar la 
// información a la función ShowValidations (Texts.js) en 
// el formato que lo necesita

export default function validationResponse (type, message) {
    return {
        inner: [
            {
                path: message,
                type: type,
            }
        ]
    }
}