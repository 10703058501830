import React from 'react';
import Swal from 'sweetalert2';

import './alert.css';

import { Config } from './Config';

const GetText = () => {
  const lang = Config.ReadLS('lang');

  if (Config.ReadLS('texts').length > 0) {
    const rtn = Config.ReadLS('texts');

    const texts = new Map();

    rtn.map((item) => {
      return texts.set(
        item.code,
        lang === 'ES'
          ? item.textStringES
          : lang === 'PT'
          ? item.textStringPT
          : item.textStringEN
      );
    });

    return texts;
  }
};

const dataText = GetText();

const ShowValidations = (model, result) => {
  let template = '';

  result.inner.forEach((e) => {
    let field =
        model[0].toUpperCase() +
        model.slice(1) +
        '.' +
        e.path[0].toUpperCase() +
        e.path.slice(1);
      
    switch (e.type) {
      case 'min': {
          template += `<li className="text-left">${dataText.get(field)} ${dataText.get('dialogs.schemaValidations.minCharacters')} ${e.params.min.toString()} ${dataText.get('dialogs.schemaValidations.characterWord')}</li>`;
        break;
      }
      case 'max': {
          template += `<li className="text-left">${dataText.get(field)} ${dataText.get('dialogs.schemaValidations.maxCharacters')} ${e.params.max.toString()} ${dataText.get('dialogs.schemaValidations.characterWord')}</li>`;
        break;
      }
      case 'required': {
        template += `<li className="text-left">${dataText.get(
          field
        )} ${dataText.get('dialogs.schemaValidations.required')}</li>`;
        break;
      }
      case 'typeError': {
        template += `<li className="text-left">${dataText.get(
          field
        )} validations.valRequired</li>`;
        break;
      }
      case '': {
        template += `<li className="text-left">${dataText.get(field)} ${
          e.message
        }
          </li>`;
        break;
      }
      case 'matches': { // Se usa cuando en el schema se usa .matches()
        template += `<li className="text-left">${dataText.get(field)} ${
          e.message
        }
          </li>`;
        break;
      }
      case 'duplicated': { // Se usa cuando el valor ingresado ya existe, o sea que se duplicó.
        // dataText.get('dialogs.schemaValidations.duplicated')
        template += `<li className="text-left">${dataText.get(field)} ${dataText.get(`dialogs.schemaValidation.duplicated`)}
          </li>`;
        break;
      }
      default: { 
        template += `<li className="text-left">
            ${dataText.get(field)} ${e.message}
          </li>`;
        break;
      }
    }
  });

  Swal.fire({
    title: `<h6>${dataText.get('dialogs.schemaValidations')}</h6>`,
    icon: 'warning',
    html: `<div className="container"><ol>${template}</ol></div>        
            <hr />`,
  });
};

const ShowConfirmation = async (key, extra) => {
  return Swal.fire({
    title: dataText.get(key),
    text: extra ? dataText.get(extra) : null,
    icon: 'question',
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#87c10ad9',
    cancelButtonColor: '#d33',
    cancelButtonText: dataText.get('button.cancel'),
    confirmButtonText: dataText.get('button.accept'),
  }).then((result) => {
    return result.isConfirmed;
  });
};

const ShowValidation = (key, extra, values) => {
  var text = extra ? dataText.get(extra) : '';
  text += values ? ` ${values}` : '';

  Swal.fire({
    title: dataText.get(key),
    text: text,
    icon: 'warning',
    allowOutsideClick: false,
    footer: 'ENSOL CRM',
  });
};

// Mensaje de confirmación
const Toast = (type) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: 'white',
    width: '218px',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: 1800,
    timerProgressBar: true,
  });

  Toast.fire({
    icon: 'success',
    title:
      type === 'new'
        ? 'Registro añadido'
        : type === 'edit'
        ? 'Registro modificado'
        : 'Registro eliminado',
  });
};

export const Texts = {
  GetText,
  dataText,
  ShowConfirmation,
  ShowValidation,
  ShowValidations,
  Toast,
  GetComposition,
  showMessage,
  showError,
  showErrorHandler,
  showFetchingValidation,
};
/**
 * Función para obtener un texto específico, en el idioma actual.
 * Sobre dicho texto se aplica un reemplazo de las propiedades entre [] por los valores
 * del registro recibido como origen de información.
 * Si no hay un idioma actual definido, se devuelve el texto en el idioma por defecto.
 * Si no se encuentra el texto para retornar (no existe la clave), se genera un error,
 * para forzar la carga de la clave necesaria.
 * @param key: Corresponde a la clave del texto a buscar.
 * @param row: Corresponde al registro del cual tomar los valores a reemplazar para la composición.
 */
function GetComposition(key, row) {
  let lang = Config.GetLang();

  // eslint-disable-next-line no-unused-vars
  let splitKey = key.split('.');
  let rtn = null; //jsonTexts[splitKey[0]][splitKey[1]][splitKey[2]][lang];

  Object.GetOwnPropertyNames(row).forEach((p) => {
    rtn = rtn.replace('[' + p + ']', row[p]);
  });

  // Retorno el texto en el idioma correspondiente. En caso de no
  // encontrar el texto asociado, se devuelve una combinación de
  // clave e idioma.
  return rtn == null ? '[' + key + '-' + lang + ']' : rtn;
}

function showMessage(key) {
  Swal({
    buttons: { cancel: dataText.get('button.close') },
    icon: 'info',
    closeOnClickOutside: false,
    content: (
      <div>
        <h6>{dataText.get(key)}</h6>
        <hr />
      </div>
    ),
  });
}

/**
 * Muestra un mensaje de error al Usuario.
 * Se utiliza en los casos en los cuales el componente ErrorHandler NO
 * tiene alcance.
 * @param error: Objeto con toda la info del error.
 * @param func: Función sobre la cual se registró el error.
 */
function showError(error, func) {
  Swal({
    buttons: { cancel: dataText.get('button.close') },
    icon: 'error',
    closeOnClickOutside: false,
    content: (
      <div>
        <h6>{dataText.get('error.errorGlobal')}</h6>
        <small>{error.message + ' (' + func + ')'}</small>
        <br />
        <hr />
      </div>
    ),
  });
}

/**
 * Muestra un mensaje de error al Usuario.
 * Se utiliza en los casos en los cuales el componente ErrorHandler SÍ
 * tiene alcance.
 * @param info: Información sobre el contexto en el cual se registró el error.
 */
function showErrorHandler(info) {
  return Swal.fire({
    title: 'Error',
    text: dataText.get(info),
    icon: 'error',
    showCancelButton: false,
    confirmButtonColor: '#87c10ad9',
    /* cancelButtonColor: '#d33', */
    confirmButtonText: 'Ok',
    footer: 'ENSOL CRM',
    /* cancelButtonText: 'Cancelar', */
  });
}

/**
 * Muestra un mensaje de error al Usuario.
 * Se utiliza en los casos en los cuales la invocación a un método
 * del Backend de la aplicación devuelve un código interno
 * @param rtn: Objeto con toda la info del retorno de ejecución.
 */
async function showFetchingValidation(rtn) {
  var confirmation;
  let valText;
  let innerText = '';
  //var ret;
  switch (rtn.response.status) {
    case 409: {
      valText = dataText.get('alerts.sureDeleteWithComponents');
      let arr = rtn.results.innerMessage.split(',');

      innerText = rtn.results.innerMessage;

      arr.forEach((table) => {
        innerText = innerText.replace(table.trim(), dataText.get(table.trim()));
      });
      break;
    }
    default:
      break;
  }

  await Swal({
    buttons: [
      dataText.get('button.cancel').toUpperCase(),
      dataText.get('button.accept').toUpperCase(),
    ],
    icon: 'warning',
    dangerMode: true,
    closeOnClickOutside: false,
    content: (
      <div>
        <h6>{valText}</h6>
        <small>{dataText.get('label.relations') + innerText}</small>
        <hr />
      </div>
    ),
  }).then((yes) => {
    confirmation = yes;
  });

  return confirmation;
}
