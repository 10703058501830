import React, { useContext, useEffect, useState } from 'react';

// Context
import { TextsContext } from '../../../../context/TextsContext';

// Componentes propios
import SHButton from '../../../../components/SHButton/SHButton';
import SHSelect from '../../../../components/SHSelect/SHSelect';
import SHHr from '../../../../components/SHHr/SHHr';
import SHLoading from '../../../../components/SHLoading/SHLoading';

// Servicios
import { environmentService } from '../../../../services/environmentService';
import { businessUnitAccountService } from '../../../../services/businessUnitAccountService';
import { CORESec } from '../../../../env/CORESec';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Config } from '../../../../env/Config';

export default function AccountGetBusinessUnitsConfigForm({
  rowData,
  back,
  dataTableOptions,
  onRefresh,
}) {
  const dataText = useContext(TextsContext);
  let navigate = useNavigate();
  const { businessUnit, accountID } = rowData;

  const { data: dataBU, loading: loadingBU } =
    businessUnitAccountService.GetAccountBU(accountID);

  const [detectInputChange, setDetectInputChange] = useState(false);
  const [commercial, setCommercial] = useState(
    dataBU
      ? environmentService.GetOptions(
          dataTableOptions.userCommercials,
          dataBU.usersCommercial
        )
      : []
  );
  const [functional, setFunctional] = useState(
    dataBU
      ? environmentService.GetOptions(
          dataTableOptions.userFunctional,
          dataBU.usersFunctional
        )
      : []
  );
  const [customerService, setCustomerService] = useState(
    dataBU
      ? environmentService.GetOptions(
          dataTableOptions.userCustomerServices,
          dataBU.usersCustomerService
        )
      : []
  );
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  useEffect(() => {
    if (disabledSubmit) {
      const disableSubmitTime = setTimeout(() => {
        setDisabledSubmit(false);
      }, 500);
      return () => clearTimeout(disableSubmitTime);
    }
  }, [disabledSubmit]);

  useEffect(() => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    }
    if (dataBU) {
      setCommercial(
        environmentService.GetOptions(
          dataTableOptions.userCommercials,
          dataBU.usersCommercial
        )
      );
      setFunctional(
        environmentService.GetOptions(
          dataTableOptions.userFunctional,
          dataBU.usersFunctional
        )
      );
      setCustomerService(
        environmentService.GetOptions(
          dataTableOptions.userCustomerServices,
          dataBU.usersCustomerService
        )
      );
    }
  }, [dataBU, dataTableOptions, navigate]);

  const handleChangeCommercials = (e) => {
    setDetectInputChange(true);
    setCommercial(e);
  };
  const handleChangeCustomerServices = (e) => {
    setDetectInputChange(true);
    setCustomerService(e);
  };
  const handleChangeFunctional = (e) => {
    setDetectInputChange(true);
    setFunctional(e);
  };
  const handleDisabledSubmit = () => setDisabledSubmit(true);

  const backFromEditMode = () => {
    if (detectInputChange) {
      return Swal.fire({
        title: dataText.get('dialogs.back.sureBack'),
        text: dataText.get('dialogs.back.changes'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: dataText.get('button.accept'),
        cancelButtonText: dataText.get('button.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          back();
        } else if (result.isDenied) {
          return;
        }
      });
    } else return back();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleDisabledSubmit();

    const newData = {
      accountID: accountID,
      businessUnitID: 1 || null,
      config:
        commercial.length > 0 ||
        functional.length > 0 ||
        customerService.length > 0
          ? true
          : false,
      usersCommercial: commercial.length > 0 ? commercial : [],
      usersFunctional: functional.length > 0 ? functional : [],
      usersCustomerService: customerService.length > 0 ? customerService : [],
    };
    businessUnitAccountService
      .PutAccountBU(rowData.accountID, newData)
      .then((ok) => {
        if (ok) {
          onRefresh();
          back();
        }
      });
  };

  if (loadingBU) {
    <SHLoading />;
  }
  return (
    <div className="businessUnits">
      <div className="d-flex justify-content-between align-content-center">
        <h5 className="m-0">
          {dataText.get('accountGetBusinessUnit.config')} {businessUnit}
        </h5>
        <SHButton
          action="back"
          classType="icon"
          onClick={() => backFromEditMode()}
        />
      </div>
      <form
        className={`row g-2 ${loadingBU && 'set-blur'}`}
        onSubmit={handleSubmit}
      >
        <div className="col-md-4">
          <SHSelect
            label="Comercial"
            name="commercial"
            id="commercial"
            options={dataTableOptions ? dataTableOptions.userCommercials : []}
            onChange={handleChangeCommercials}
            value={commercial}
          />
        </div>
        <div className="col-md-4">
          <SHSelect
            label="Customer Service"
            name="customerService"
            id="customerService"
            options={
              dataTableOptions ? dataTableOptions.userCustomerServices : []
            }
            onChange={handleChangeCustomerServices}
            value={customerService}
          />
        </div>
        <div className="col-md-4 ">
          <SHSelect
            label="Funcional"
            name="functional"
            id="functional"
            options={dataTableOptions ? dataTableOptions.userFunctional : []}
            onChange={handleChangeFunctional}
            value={functional}
          />
        </div>
        <SHHr margin="my-2" />
        <div className="d-flex justify-content-end m-0">
          <SHButton
            action="accept"
            type="submit"
            classType="both"
            disabled={loadingBU || disabledSubmit}
          />
        </div>
      </form>
    </div>
  );
}
