import React from 'react';

function SHTabPane ({
    children,
    id,
    active,
}) {
    return (
        <div className={ `tab-pane fade ${active && 'show active'}` } id={id} role="tabpanel" aria-labelledby={id}>
            {children}
        </div>
    );
};

export default SHTabPane;