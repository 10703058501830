import * as yup from 'yup';
import { Config } from '../env/Config';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';

const GetAccountBU = (id) => { return Fetch.GETFetch(`${Config.WebAPI}/BusinessUnitAccount/Account${id}`) }

const GetProducts = async (accountID) => {
    const init = {
        method: 'GET',
        headers: Fetch.HEADERS()
    };
    const urlProducts = `${Config.WebAPI}/AccountProduct/Account${accountID}`;
    const fetchData = await fetch(urlProducts, init)
    const fetchJson = await fetchData.json();
    return fetchJson;
}

const GetOtherProducts = async (accountID) => {
    const init = {
        method: 'GET',
        headers: Fetch.HEADERS()
    };
    const urlOtherProducts = `${Config.WebAPI}/AccountOtherProduct/Account${accountID}`;
    const fetchData = await fetch(urlOtherProducts, init)
    const fetchJson = await fetchData.json();
    return fetchJson;
}

const GetProductsAndOtherProducts = (accountID) => {
    const urlOtherProducts = `${Config.WebAPI}/AccountOtherProduct/Account${accountID}`;
    const urlProducts = `${Config.WebAPI}/AccountProduct/Account${accountID}`;

    return Fetch.GETDoubleFetch(urlProducts, urlOtherProducts) 
}

const PutAccountBU = async (id, data) => {

    let dataArray = {};

    for (const property in data) {
        if (property === 'usersCommercial') {
            dataArray = {
                ...dataArray,
                usersCommercial: data[property] !== null ? data[property].map(obj => { return { userID: obj.value } }) : null
            }
        } else if (property === 'usersFunctional') {
            dataArray = {
                ...dataArray,
                usersFunctional: data[property] !== null ? data[property].map(obj => { return { userID: obj.value } }) : null
            }
        }
        else if (property === 'usersCustomerService') {
            dataArray = {
                ...dataArray,
                usersCustomerService: data[property] !== null ? data[property].map(obj => { return { userID: obj.value } }) : null
            }
        } else {
            dataArray = {
                ...dataArray,
                [property]: data[property]
            }
        }
    }
        
    if(dataArray.usersCommercial.length === 0 && dataArray.usersCustomerService.length === 0 && dataArray.usersFunctional.length === 0) {
        return Texts.ShowValidation('accountGetBusinessUnit.users', 'accountGetBusinessUnit.enterUser')
    }

    return new Promise(function (resolve) {
        businessUnitAccountSchema.isValid(dataArray)
            .then(valid => {
                if (!valid) {
                    businessUnitAccountSchema.validate(dataArray, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('businessUnitAccount', result)
                            console.log(result);
                        })
                    resolve(false)
                }
                else {
                    Fetch.PUTFetch(`${Config.WebAPI}/BusinessUnitAccount/${id}`, dataArray)
                        .then(rtn => { console.log(rtn); resolve(rtn) })
                }
            })
    })
}

const DeleteAccountBU = async (id) => {
    return new Promise(function (resolve) {
        Fetch.DELETEFetch(`${Config.WebAPI}/BusinessUnitAccount/${id}`, id)
            .then(rtn => { console.log(rtn); resolve(rtn) })
    })
}

let businessUnitAccountSchema = yup.object().shape({
    accountID: yup.number().min(1).required(),
    businessUnitID: yup.number().min(1).required(),
    config: yup.bool().required(),
    usersCommercial: yup.array(),
    usersCustomerService: yup.array(),
    usersFunctional: yup.array(),
});

export const businessUnitAccountService = {
    GetAccountBU,
    GetProductsAndOtherProducts,
    GetProducts,
    GetOtherProducts,
    PutAccountBU,
    DeleteAccountBU,
}