import * as yup from 'yup';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';
import GETFilterRows from '../assets/utils/GETFilterRows';
import { Config } from '../env/Config';

const GetAccountContacts = (userID) => { 
    if(userID){
        return Fetch.GETFetch(`${Config.WebAPI}/AccountContact?accountUsers=${userID}`) 
    } else {
        return Fetch.GETFetch(`${Config.WebAPI}/AccountContact`) 
    }
}

const GetAccountContact = async (id) => { 
    const init = { method: 'GET', headers: Fetch.HEADERS() };
    try {
      const fetching = await fetch(`${Config.WebAPI}/AccountContact/${id}`, init);
      const fetchedData = await fetching.json();
      return fetchedData.return;
    } catch (err) {
      console.log(err);
    }
}

const GetAccountContactByAccount = (id) => { return Fetch.GETFetch(`${Config.WebAPI}/AccountContact/Account${id}`) }

const PostAccountContact = async (data) => { 
    
    return new Promise(function (resolve){
        contactSchema.isValid(data)
        .then(valid =>{
            if (!valid) {
                contactSchema.validate(data, { abortEarly: false }) 
                .catch(result =>  { 
                    Texts.ShowValidations('accountContact', result)
                    console.log(result);
                })
                resolve(false)
            }
            else {
                Fetch.POSTFetch(`${Config.WebAPI}/AccountContact`, data)
                .then(rtn => { console.log(rtn); resolve(rtn) })
                .then(Texts.Toast('new'))
            }
        })
    })
}

const PutAccountContact = async (id, data) => {

    return new Promise(function (resolve) {
        contactSchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    contactSchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('accountContact', result)
                            console.log(result);
                        })
                    resolve(false)
                }
                else {
                    Fetch.PUTFetch(`${Config.WebAPI}/AccountContact/${id}`, data)
                        .then(rtn => { console.log(rtn); resolve(rtn) })
                        .then(Texts.Toast('edit'))
                }
            })
    })
}

const DeleteAccountContact = async (id) => {
    return new Promise(function (resolve) {
        const url = `${Config.WebAPI}/AccountContact/${id}`

        let init = { method: 'GET', headers: Fetch.HEADERS() }
        
        fetch(url, init)
            .then(response => response.json())
            .then(fetchedData => {
                if (fetchedData.return) {
                    Texts.ShowConfirmation('dialogs.confirmation.sureDelete', 'dialogs.confirmation.sureDelete.extra')
                        .then(c => {
                            if (c) {
                                Fetch.DELETEFetch(`${Config.WebAPI}/AccountContact/${id}`, id)
                                    .then(rtn => { console.log(rtn); resolve(rtn) })
                                    .then(Texts.Toast())
                            }
                        })
                }
                else {
                    Texts.ShowValidation('dialogs.information.cannotBeDeleted', 'dialogs.information.cannotBeDeleted.extra', fetchedData.return.associatedRows)
                }
            })
    })
}

const ForceDeleteAccountContact = async (id) => {
    return new Promise(function (resolve) {
        const url = `${Config.WebAPI}/AccountContact/ForceDelete${id}`

        let init = { method: 'GET', headers: Fetch.HEADERS() }
        
        fetch(url, init)
            .then(response => response.json())
            .then(fetchedData => {
                console.log(fetchedData);
                if (fetchedData.return) {
                    Texts.ShowConfirmation('dialogs.confirmation.sureDelete', 'dialogs.confirmation.sureDelete.extra')
                        .then(c => {
                            if (c) {
                                Fetch.DELETEFetch(`${Config.WebAPI}/AccountContact/ForceDelete${id}`, id)
                                    .then(rtn => { console.log(rtn); resolve(rtn) })
                                    .then(Texts.Toast())
                            }
                        })
                }
                else {
                    Texts.ShowValidation('dialogs.information.cannotBeDeleted', 'dialogs.information.cannotBeDeleted.extra', fetchedData.return.associatedRows)
                }
            })
    })
}

const GetFilterContacts = async (query) => {
    return await GETFilterRows('/AccountContact', query)
}

let contactSchema = yup.object().shape({
    accountID: yup.number().min(1).required(),
    name: yup.string().nullable().max(50).required(),
    surname: yup.string().nullable().max(50).required(),
    position: yup.string().nullable().max(50),
    classificationID: yup.number().nullable().min(1).required(),
    accountAddressID: yup.number().nullable().min(1),
    observations: yup.string().nullable().max(1500),
    workPhone: yup.mixed(),
    workCellPhone: yup.mixed(),
    workEmail: yup.string().nullable().max(50),
    channelType: yup.number().nullable().min(1),
    channelInfo: yup.string().nullable().max(150)
});

export const accountContactService = {
    GetAccountContact,
    GetAccountContacts,
    GetAccountContactByAccount,
    GetFilterContacts,
    PostAccountContact,
    PutAccountContact,
    DeleteAccountContact,
    ForceDeleteAccountContact
}