import React, { useContext } from 'react';

// Context
import { TextsContext } from '../../context/TextsContext';

// Componentes
import ShowMenu from './components/Menu/Menu';
import ShowUser from './components/ShowUser/ShowUser';
import SearchBar from './components/SearchBar/SearchBar';
import SHIcon from '../SHIcon/SHIcon';

import { CORESec } from '../../env/CORESec';

// Estilos
import './SHNavbar.css';
import { useNavigate } from 'react-router-dom';
import Notifications from './components/Notifications/Notifications';
import { Config } from '../../env/Config';

function SHNavbar({
    brandLogo,
    brandName,
    color,
}) {
    let navigate = useNavigate()
    const dataText = useContext(TextsContext)
    if (dataText === undefined) {
        window.location.reload()
    }

    const handleClick = () => {
        if(!CORESec.validateSesion()) {
            CORESec.Logout()
            navigate(`/${Config.Version}/login`)
        }
        return;
    }

    return (
        <nav onClick={handleClick}  className="navbar navbar-expand-lg navbar-dark p-0 mb-2" id='navbar' >
            <div className="container-fluid">
                <a className="navbar-brand navbar-logo" href="/">
                    <img src={brandLogo} alt={`${brandName} logo`} height='26' />
                    {brandName}
                </a>
                <button className="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{border: 'none'}}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ShowMenu />
                    <div className='d-flex flex-column flex-lg-row align-items-start mb-2 mb-lg-0'>
                        <SearchBar />
                        <Notifications />
                        <span className="countryOperation mt-2">
                            <SHIcon type='web' size='13' fill="#e4f1d5"/>
                            {CORESec.GetCountryAccessLevels().code === "ARG" ? "ARG" : "BRA"}
                        </span>
                        <ShowUser />
                    </div>

                </div>
            </div>
        </nav>
    )
}

export default React.memo(SHNavbar)