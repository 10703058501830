import * as yup from 'yup';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';
import GETFilterRows from '../assets/utils/GETFilterRows';
import { Config } from '../env/Config';

const GetAccountOpportunities = (userID) => { 
    if(userID) {
        return Fetch.GETFetch(`${Config.WebAPI}/AccountOpportunity?accountUsers=${userID}&commercial=${userID}`) 
    } else {
        return Fetch.GETFetch(`${Config.WebAPI}/AccountOpportunity`) 
    }
}

const GetAccountOpportunity = (id) => { return Fetch.GETFetch(`${Config.WebAPI}/AccountOpportunity/${id}`) }

const GetAccountOpportunityByAccount = async (id) => { 
    let fetchData = await fetch(`${Config.WebAPI}/AccountOpportunity/Account${id}`)
    let fetchJson = await fetchData.json();

    return fetchJson
}

const GetOpportunityAndAttachments = (oppID) => { 
    const urlFormData = `${Config.WebAPI}/AccountOpportunity/${oppID}`;
    const urlFilesData = `${Config.WebAPI}/AccountOpportunityAttachment/getAttachmentByAccountOpportunityId/${oppID}`;

    return Fetch.GETDoubleFetch(urlFormData, urlFilesData)
}

const PostAccountOpportunity = async (data) => {

    return new Promise(function (resolve) {
        accountOpportunitySchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    accountOpportunitySchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('accountOpportunity', result)
                            console.log(result);
                        })
                    resolve(false)
                }
                else {
                    Fetch.POSTFetch(`${Config.WebAPI}/AccountOpportunity`, data)
                        .then(rtn => { console.log(rtn.return); resolve(rtn) })
                        .then(Texts.Toast('new'))
                }
            })
    })
}

const PutAccountOpportunity = async (id, data) => {

    return new Promise(function (resolve) {
        accountOpportunitySchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    accountOpportunitySchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('accountOpportunity', result)
                            console.log(result);
                        })
                    resolve(false)
                }
                else {
                    Fetch.PUTFetch(`${Config.WebAPI}/AccountOpportunity/${id}`, data)
                        .then(rtn => { console.log(rtn); resolve(rtn) })
                        .then(Texts.Toast('edit'))
                }
            })
    })
}

const DeleteAccountOpportunity = async (id) => {
    return new Promise(function (resolve) {
        const url = `${Config.WebAPI}/AccountOpportunity/${id}`

        let init = { method: 'GET', headers: Fetch.HEADERS() }

        fetch(url, init)
            .then(response => response.json())
            .then(fetchedData => {
                if (fetchedData.return.canBeDeleted) {
                    Texts.ShowConfirmation('dialogs.confirmation.sureDelete', 'dialogs.confirmation.sureDelete.extra')
                        .then(c => {
                            if (c) {
                                Fetch.DELETEFetch(`${Config.WebAPI}/AccountOpportunity/${id}`, id)
                                    .then(rtn => { console.log(rtn); resolve(rtn) })
                                    .then(Texts.Toast())
                            }
                        })
                }
                else {
                    Texts.ShowValidation('dialogs.information.cannotBeDeleted', '')
                }
            })
    })
}

const GetFilterAccountOpportunity = async (query) => {
    return await GETFilterRows(`/AccountOpportunity`, query)
}

// eslint-disable-next-line no-unused-vars
let accountOpportunitySchema = yup.object().shape({
    accountID: yup.number().min(1).required(),
    quotationNumber: yup.string().nullable().max(50),
    name: yup.string().nullable().max(50).required(),
    businessUnitID: yup.number().min(1).required(),
    commercialID: yup.number().min(1).required(),
    quoteID: yup.number().min(1).required(),
    stateID: yup.number().min(1).required(),
    quotationDate: yup.date().nullable(),
    limitDate: yup.date().nullable(),
    potentialID: yup.number().min(1).required(),
    importanceID: yup.number().min(1).required(),
    observations: yup.string().nullable().max(1500),
    follow: yup.bool().required(),
    accountOpportunityAttachments: yup.array().nullable()
});

export const accountOpportunityService = {
    GetAccountOpportunities,
    GetAccountOpportunity,
    GetAccountOpportunityByAccount,
    GetFilterAccountOpportunity,
    GetOpportunityAndAttachments,
    PostAccountOpportunity,
    PutAccountOpportunity,
    DeleteAccountOpportunity,
}