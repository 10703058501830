/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

// Componentes propios
import SHInput from '../../../../components/SHInput/SHInput';
import SHHr from '../../../../components/SHHr/SHHr';
import SHButton from '../../../../components/SHButton/SHButton';
import SHContainer from '../../../../components/SHContainer/SHContainer';

import { TextsContext } from '../../../../context/TextsContext';
import SHSelect from '../../../../components/SHSelect/SHSelect';
import { environmentService } from '../../../../services/environmentService';
import { accountOtherProductService } from '../../../../services/accountOtherProductService';
import Swal from 'sweetalert2';

function AccountGetBusinessUnitOtherProductForm({
  mode,
  rowAccountID,
  rowData,
  back,
  dataOptions,
  loadingData,
  refreshProducts,
}) {
  const dataText = useContext(TextsContext);

  const [detectInputChange, setDetectInputChange] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  /* States */
  const [name, setOtherProduct] = useState(
    mode === 'edit' && rowData ? rowData.name : ''
  );
  const [quantity, setQuantity] = useState(
    mode === 'edit' && rowData !== null ? rowData.quantityByUser : ''
  );
  const [description, setDescription] = useState(
    mode === 'edit' && rowData !== null ? rowData.description : ''
  );
  const [selectedOptionUnit, setSelectedOptionUnit] = useState(
    mode === 'edit' && rowData !== null
      ? environmentService.GetOption(
          dataOptions.measurementUnit,
          rowData.measurementUnitID
        )
      : []
  );

  useEffect(() => {
    if (disabledSubmit) {
      const disabledSubmitTime = setTimeout(() => {
        setDisabledSubmit(false);
      }, 500);
      return () => clearTimeout(disabledSubmitTime);
    }
  }, [disabledSubmit]);

  /* Handlers */
  const handleChangeOtherProduct = (e) => {
    setDetectInputChange(true);
    setOtherProduct(e.target.value);
  };
  const handleChangeUnit = (e) => {
    setDetectInputChange(true);
    setSelectedOptionUnit(e);
  };
  const handleChangeQuantity = (e) => {
    setDetectInputChange(true);
    setQuantity(e.target.value);
  };
  const handleChangeObservations = (e) => {
    setDetectInputChange(true);
    setDescription(e.target.value);
  };

  const handleDisabledSubmit = () => setDisabledSubmit(true);

  const backFromEditMode = () => {
    if (detectInputChange) {
      return Swal.fire({
        title: dataText.get('dialogs.back.sureBack'),
        text: dataText.get('dialogs.back.changes'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: dataText.get('button.accept'),
        cancelButtonText: dataText.get('button.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          back();
        } else if (result.isDenied) {
          return;
        }
      });
    } else return back();
  };

  /* Submit */
  const handleSubmit = (e) => {
    e.preventDefault();
    
    handleDisabledSubmit()

    const newData = {
      accountID: rowAccountID,
      name: name || null,
      measurementUnitID: selectedOptionUnit.value || null,
      quantityByUser: Number(quantity) || null,
      description: description || null,
    };

    if (mode === 'new') {
      accountOtherProductService.PostAccountOtherProduct(newData).then((ok) => {
        if (ok) {
          refreshProducts();
          back();
        }
      });
    } else if (mode === 'edit') {
      newData.accountOtherProductID = rowData.accountOtherProductID;
      accountOtherProductService
        .PutAccountOtherProduct(rowData.accountOtherProductID, newData)
        .then((ok) => {
          if (ok) {
            refreshProducts();
            back();
          }
        });
    }
  };

  return (
    <SHContainer>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">
            {mode === 'new'
              ? dataText.get('AccountBusinessUnit.AddAnotherProduct')
              : `#${rowData ? rowData.accountOtherProductID : ''} - ${
                  rowData ? rowData.name : ''
                }`}
          </h6>
          <SHButton
            action="back"
            classType="icon"
            disabled={loadingData}
            onClick={() => backFromEditMode()}
          />
        </div>
        <div>
          <form
            onSubmit={handleSubmit}
            className={`${loadingData && 'set-blur'}`}
          >
            <div
              className="row g-2"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                justifyContent: 'space-between',
              }}
            >
              <div className="col-md-4">
                <SHInput
                  label={dataText.get('Account.Product')}
                  name="otherProduct"
                  id="otherProduct"
                  type="text"
                  onChange={handleChangeOtherProduct}
                  value={name}
                />
              </div>
              <div className="col-md-2">
                <SHSelect
                  label={dataText.get('accountGetBusinessUnit.measurementUnit')}
                  name="unit"
                  id="unit"
                  options={dataOptions.measurementUnit || []}
                  onChange={handleChangeUnit}
                  value={selectedOptionUnit}
                  single
                />
              </div>
              <div className="col-md-2">
                <SHInput
                  type="number"
                  step={`${selectedOptionUnit.label === 'TN' ? '0.1' : '1'}`}
                  label={dataText.get('accountGetBusinessUnit.quantity')}
                  name="quantity"
                  id="quantity"
                  min="1"
                  onChange={handleChangeQuantity}
                  value={quantity}
                  disabled={selectedOptionUnit.length === 0}
                />
              </div>
              <div className="col-md-4">
                <SHInput
                  label={dataText.get('AccountOpportunity.Observations')}
                  name="observations"
                  id="observations"
                  type="textarea"
                  rows="1"
                  onChange={handleChangeObservations}
                  value={description}
                />
              </div>
            </div>
            <SHHr margin="my-2" />
            <div className="d-flex justify-content-end">
              <SHButton
                action="accept"
                type="submit"
                classType="both"
                disabled={loadingData || disabledSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </SHContainer>
  );
}

export default AccountGetBusinessUnitOtherProductForm;
