import React from 'react';

// Estilos
import './SHCheckbox.css'

function SHCheckbox({
    checked,
    disabled,
    id,
    label,
    name,
    onChange,
    value,
    justify,
    align,
}) {

    return (
        <>
            <div
                className={`form-check d-flex justify-content-${justify ? justify : 'start'} align-items-${align ? align : 'end'}`}
                style={{ maxWidth: 'fit-content' }}
            >
                <input
                    className="form-check-input"
                    name={name}
                    type="checkbox"
                    value={value}
                    onChange={onChange}
                    id={id}
                    checked={checked}
                    disabled={disabled}
                />
                {
                    label &&
                    <label id={id} className="form-check-label ms-2" style={{ fontSize: 'small' }}>
                        {label}
                    </label>
                }
            </div>
        </>
    )
}

export default SHCheckbox
