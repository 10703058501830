import React, { useState, useContext, lazy, Suspense, useEffect } from 'react';

// Context
import { TextsContext } from '../../context/TextsContext';
// Router
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { CORESec } from '../../env/CORESec';

import { invertDate } from '../../assets/utils/invertDate';

import { accountService } from '../../services/accountService';

import SHButton from '../../components/SHButton/SHButton';
import SHContainer from '../../components/SHContainer/SHContainer';
import SHHeader from '../../components/SHHeader/SHHeader';
import SHLoading from '../../components/SHLoading/SHLoading';
import SHTabs from '../../components/SHTabs/SHTabs';
import SHErrorBoundary from '../../components/SHErrorBoundary/SHErrorBoundary';

import AccountGetBusinessUnits from '../Account/BusinessUnits/AccountGetBusinessUnits';
import { Config } from '../../env/Config';

const AccountDetail = lazy(() => import('./AccountDetail'));
const AccountModal = lazy(() => import('./AccountModal'));
const AccountGetEvents = lazy(() =>
  import('../Account/Events/AccountGetEvents')
);
const AccountGetNotes = lazy(() => import('../Account/Notes/AccountGetNotes'));
const AccountGetOpportunities = lazy(() =>
  import('../Account/Opportunities/AccountGetOpportunities')
);
const AccountGetAddress = lazy(() => import('./Address/AccountGetAddress'));
const AccountGetContacts = lazy(() =>
  import('../Account/Contacts/AccountGetContacts')
);

export default function AccountGet() {
  const dataText = useContext(TextsContext);

  let navigate = useNavigate();
  let location = useLocation();

  let params = useParams();
  let rowAccountID = parseInt(params.id); // Esto es accountID
  let { pathname } = location;
  let isSubEntityArr = pathname.split('/');
  let currentLocation = isSubEntityArr[5];
  let idSubentity = isSubEntityArr[6];
  const [edit, setEdit] = useState(false);
  const [currentTab, setCurrentTab] = useState('event');
  const [backFromBrowser, setBackFromBrowser] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);

  useEffect(() => {
    accountService.GetAccount(rowAccountID)
    .then((res) => {
      if(res === undefined || res === null) {
        // setError(true)
        return
      } else {
        return setData(res)
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }, [rowAccountID])

  useEffect(() => {
    window.onhashchange = function () {
        setBackFromBrowser(true);
      };
      return () =>  window.onhashchange()
  },[])

  useEffect(() => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    }
    // COMENTADO POR PROBLEMAS DE REDIRECCION (tal vez sea posible solucion de otro error que todavia no vi)
    currentTab === undefined && setCurrentTab('businessUnit');
    setCurrentTab(currentLocation);
    if( location.pathname === `/${Config.Version}/account/get/${rowAccountID}` && !backFromBrowser){
      navigate(`/${Config.Version}/account/get/${rowAccountID}/${currentTab}`)
    }  else if (
      location.pathname === `/${Config.Version}/account/get/${rowAccountID}` && backFromBrowser
    ) {
      setBackFromBrowser(false);
      navigate(`/${Config.Version}/account`)
    }
  }, [
    backFromBrowser,
    navigate,
    currentTab,
    currentLocation,
    idSubentity,
    location.pathname,
    rowAccountID,
  ]);

  let rowData = data ? data : [];

  let isEdit;
  let entityID;
  let rowValuesFromTable;

  if (location.state) {
    const { editMode, rowID, rowValues } = location.state;
    isEdit = editMode;
    entityID = rowID;
    // La siguiente variable es para los inputs de fecha, que muestra el dato como dd-mm-yyyy pero esperan el dato como yyyy-mm-dd
    rowValuesFromTable = rowValues;
    if (rowValuesFromTable.limitDateString) {
      let formattedDate = invertDate(rowValues.limitDateString);
      rowValuesFromTable = {
        ...rowValuesFromTable,
        limitDateString: formattedDate,
      };
    }
    if (rowValuesFromTable.quotationDateString) {
      let formattedDate = invertDate(rowValues.quotationDateString);
      rowValuesFromTable = {
        ...rowValuesFromTable,
        quotationDateString: formattedDate,
      };
    }
    if (rowValuesFromTable.dueDateString) {
      let formattedDate = invertDate(rowValues.dueDateString);
      rowValuesFromTable = {
        ...rowValuesFromTable,
        dueDateString: formattedDate,
      };
    }
  }

  const tabs = [
    {
      id: 'event',
      name: dataText.get('accountGetEvent.title'),
      active: currentTab === 'event' ? true : false,
    },
    {
      id: 'opportunity',
      name: dataText.get('accountGetOpportunity.title'),
      active: currentTab === 'opportunity' ? true : false,
    },
    {
      id: 'note',
      name: dataText.get('accountGetNote.title'),
      active: currentTab === 'note' ? true : false,
    },
    {
      id: 'businessUnit',
      name: dataText.get('accountGetBusinessUnit.title'),
      active: currentTab === 'businessUnit' ? true : false,
    },
    {
      id: 'address',
      name: dataText.get('accountGetAddress.title'),
      active: currentTab === 'address' ? true : false,
    },
    {
      id: 'contact',
      name: dataText.get('accountGetContact.title'),
      active: currentTab === 'contact' ? true : false,
    },
  ];

  const handleOnClick = (id) => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    } else {
      switch (id) {
        case 'opportunity':
          setCurrentTab('opportunity');
          navigate(
            `/${Config.Version}/account/get/${rowAccountID}/opportunity`
          );
          break;
        case 'note':
          setCurrentTab('note');
          navigate(`/${Config.Version}/account/get/${rowAccountID}/note`);
          break;
        case 'businessUnit':
          setCurrentTab('businessUnit');
          navigate(
            `/${Config.Version}/account/get/${rowAccountID}/businessUnit`
          );
          break;
        case 'address':
          setCurrentTab('address');
          navigate(`/${Config.Version}/account/get/${rowAccountID}/address`);
          break;
        case 'contact':
          setCurrentTab('contact');
          navigate(`/${Config.Version}/account/get/${rowAccountID}/contact`);
          break;
        default:
          setCurrentTab('event');
          navigate(`/${Config.Version}/account/get/${rowAccountID}/event`);
          break;
      }
    }
  };

  const handleRefreshAccount = () => {
    accountService.GetAccount(rowAccountID)
    .then((res) => {
      if(res === undefined || res === null) {
        // setError(true)
        return;
      } else {
        return setData(res)
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const handleNavigate = () => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    } else {
      navigate(`/${Config.Version}/account`);
    }
  };

  const handleDeleteAccount = () => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    } else {
      accountService.DeleteAccount(rowAccountID).then((ok) => {
        if (ok) navigate(`/${Config.Version}/account`);
      });
    }
  };

  const handleSetEdit = () => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    } else {
      setEdit(!edit);
    }
  };

  if (loading) return <SHLoading />;

  return (
    <SHContainer>
      <SHHeader
        titleId={rowData.accountID}
        detailTitle={rowData.name}
        state={rowData.stateString}
        potential={rowData.potentialString}
        classification={rowData.classificationString}
      >
        <SHButton action="back" classType="icon" onClick={handleNavigate} />
        {CORESec.Operation('accountPut') && (
          <AccountModal
            mode="edit"
            dataRow={rowData}
            onRefresh={handleRefreshAccount}
            entity="accountGet"
          />
        )}
        {CORESec.Operation('accountDelete') && (
          <SHButton
            action="delete"
            classType="both"
            onClick={handleDeleteAccount}
          />
        )}
        <SHButton
          classType="icon"
          onClick={handleSetEdit}
          action={edit ? 'collapse-on' : 'collapse-off'}
        />
      </SHHeader>
      {edit && (
        <AccountDetail
          description={rowData.description}
          commercial={rowData.commercial}
          customerService={rowData.customerService}
          functional={rowData.functional}
        />
      )}
      <Suspense fallback={<SHLoading />}>
        <SHTabs arrayIds={tabs} onClick={handleOnClick}>
          <Routes>
            <Route
              path="event/*"
              element={
                <SHErrorBoundary>
                  <AccountGetEvents
                    entityID={entityID}
                    isEdit={isEdit}
                    rowAccountID={rowAccountID}
                    rows={rowData.accountEvents}
                    eventRow={rowValuesFromTable}
                  />
                </SHErrorBoundary>
              }
            />
            <Route
              path="opportunity/*"
              element={
                <SHErrorBoundary>
                  <AccountGetOpportunities
                    entityID={entityID}
                    isEdit={isEdit}
                    rowAccountID={rowAccountID}
                    rows={rowData.accountOpportunities}
                    oppRow={rowValuesFromTable}
                    rowProducts={rowData.accountProducts}
                  />
                </SHErrorBoundary>
              }
            />
            <Route
              path="note/*"
              element={
                <SHErrorBoundary>
                  <AccountGetNotes
                    entityID={entityID}
                    isEdit={isEdit}
                    rowAccountID={rowAccountID}
                    rows={rowData.accountNotes}
                    noteRow={rowValuesFromTable}
                  />
                </SHErrorBoundary>
              }
            />
            <Route
              path="businessUnit/*"
              element={
                <SHErrorBoundary>
                  <AccountGetBusinessUnits
                    rowAccountID={rowAccountID}
                    rowData={rowData}
                    buRow={rowValuesFromTable}
                    accountProducts={rowData.accountProducts}
                    onRefresh={handleRefreshAccount}
                  />
                </SHErrorBoundary>
              }
            />
            <Route
              path="address/*"
              element={
                <SHErrorBoundary>
                  <AccountGetAddress
                    rowAccountID={rowAccountID}
                    rows={rowData.accountAddresses}
                    addressRow={rowValuesFromTable}
                  />
                </SHErrorBoundary>
              }
            />
            <Route
              path="contact/*"
              element={
                <SHErrorBoundary>
                  <AccountGetContacts
                    rowAccountID={rowAccountID}
                    rows={rowData.accountContacts}
                    contactRow={rowValuesFromTable}
                  />
                </SHErrorBoundary>
              }
            />
          </Routes>
        </SHTabs>
      </Suspense>
    </SHContainer>
  );
}
