import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Components
import SHIcon from '../SHIcon/SHIcon';

// Estilos
import './SHBreadCrumb.css';

// Otros
import { capitalizeFirstLetter } from "../../assets/utils/capitalizeFirstLetter";
import { Config } from "../../env/Config";
// import { breadcrumb, pagesList } from "./components/data";

function SHBreadCrumb() {
    const [crumbs, setCrumbs] = useState([]); // URL de rutas

    let location = useLocation() // Para tener las rutas en location.pathname

    let crumbPath = []; // Necesario para pasarle la ruta entera a los Link's y que redireccionen correctamente
    let entityID = null;

    useEffect(() => { // Esto renderiza cada vez que location.pathname cambia

        const routeWithoutSlashes = location.pathname.split('/'); // Se quitan las barras de la ruta

        const filterEmptyRoute = routeWithoutSlashes.filter((route, idx) => { // Se quita el espacio vacio en el array
            return route !== '';
        })

        filterEmptyRoute.shift() // Quitamos el primer elemento que es la versión (ej: "0.8.0")

        if (filterEmptyRoute.indexOf('get') === 1) { // Chequeamos si existe un elemento en el array que se llame "get"
            let deleteIndex = filterEmptyRoute.indexOf('get') // Obtenemos su indice
            filterEmptyRoute.splice(deleteIndex, 1) // Lo borramos
        }

        const mapRoutes = filterEmptyRoute.map((route, idx) => { // Se hace un array con las rutas con la primer letra en mayúscula
            let newStringRoute = capitalizeFirstLetter(route)
            return newStringRoute
        })

        setCrumbs(mapRoutes)

        return () => { }

    }, [location.pathname])

    function isLast(index) { // Para saber si es el ultimo y ponerle clase disabled
        return index === crumbs.length - 1;
    }

    return (
        (location.pathname !== '/' && location.pathname !== '/registration') &&
        <nav className="breadCrumbNav">
            <ol className='breadcrumb my-0' style={{ fontSize: '11px' }} >
                <li>
                    {
                        location.pathname === '/home' ?
                            <div className='centeredHomeIcon'>
                                <SHIcon type='house-door' />
                            </div>
                            :
                            <div className='centeredHomeIcon'>
                                <SHIcon type='house-door' />
                                <Link className='homeLink' to={`/${Config.Version}/account`}>
                                    {
                                        location.pathname === `/${Config.Version}/account` 
                                        ? 'Home' 
                                        : 'Accounts'
                                    }
                                </Link>
                            </div>
                    }
                </li>
                {
                    crumbs.map((crumb, idx) => {
                        const disabled = isLast(idx) ? 'disabled' : ''; // Si es el ultimo, le da clase disabled
                        
                        const lowerCaseCrumb = crumb.charAt(0).toLowerCase() + crumb.slice(1); // Convierte la primer letra en minúscula.

                        crumbPath = [...crumbPath, lowerCaseCrumb]

                        let indexOfCrumb = crumbPath.indexOf(lowerCaseCrumb);

                        let route = crumbPath.slice(indexOfCrumb)

                        
                        route = crumbPath.toString().replaceAll(',', '/') // Se pasa a string, se cambian las comas por barras

                        if (crumbPath[0] === 'account' && (crumbPath.length !== 0 || crumbPath.length !== 1)) { // En caso de entrar a una cuenta, agrega el 'get' a la ruta
                            let arrayRouteWithoutGet = route.split('/');

                            if(arrayRouteWithoutGet.length !== 1) { // Si es 2 o mas elementos
                                let arrayAccountWord = arrayRouteWithoutGet.slice(0);
                                let arrayGetWord = arrayAccountWord.concat('get');
                                let arrayRouteWithGet = arrayGetWord.concat(arrayRouteWithoutGet.slice(2))
                                let routeWithGet = arrayRouteWithGet.toString().replaceAll(',', '/');
                                route = routeWithGet

                                let pathArrayLocation = location.pathname.split('/');
                                let configurePathLocation = pathArrayLocation.slice(4);
                                entityID = configurePathLocation.slice(0,1).toString();
                            }
                        }

                        return (
                            <React.Fragment key={idx}>
                            { // Si es la palabra Account/Reports no devuelve nada
                                (crumb === 'Account' || crumb === 'Reports' || crumb === 'Search') && <React.Fragment />
                            }
                            { // Si es numero
                                !isNaN(crumb) && <React.Fragment>
                                    <span className='divider' >{`>`}</span>
                                    <Link
                                        to={`/${Config.Version}/account/get/${crumb}/event`}
                                        className={`breadcrumb-item ${disabled} middleRoute`}
                                    >
                                        {crumb}
                                    </Link>
                                </React.Fragment>
                            }
                            { // Si es una palabra que NO es Account/Reports/Search
                                (crumb !== 'Account' && crumb !== 'Reports' && crumb !== 'Search' && isNaN(crumb)) && <React.Fragment>
                                    <span className='divider' >{`>`}</span>
                                    <Link
                                        to={`/${Config.Version}/account/get/${entityID}/${lowerCaseCrumb}`}
                                        className={`breadcrumb-item ${disabled} middleRoute`}
                                    >
                                        {crumb}
                                    </Link>
                                </React.Fragment>
                            }
                            </React.Fragment>
                        );
                    })
                }
            </ol>
        </nav>
    );
}

export default React.memo(SHBreadCrumb)
