import { useState, useEffect } from 'react';
import { Config } from './Config';
import { CORESec } from './CORESec';

const HEADERS = (token = null, lang = null) => {
  let t = token;

  if (t === null) t = Config.ReadLS('session');

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    token: t,
    lang: lang ? lang : Config.ReadLS('lang'),
    countryID: CORESec.GetCountryAccessLevels().id,
    userID: CORESec.GetCurrentUser().userID,
    userName: CORESec.GetCurrentUser().username
  };
};

const GETFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isCancelled = false; // "Boolean flag" para limpiar funciones async

    const fetchingData = async () => {
      const init = { method: 'GET', headers: Fetch.HEADERS() };

      try {
        const fetching = await fetch(url, init);
        const fetchedData = await fetching.json();
        
        if (fetchedData && !isCancelled) {
          DEBUGFetch(url, fetchedData);
          setData(fetchedData.return);
          setError(false);
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchingData();

    return () => {
      isCancelled = true;
      setData(null)
    }
  }, [url]);

  const refresh = () => {
    const fetchingData = async () => {
      const init = { method: 'GET', headers: Fetch.HEADERS() };

      try {
        const fetching = await fetch(url, init);
        const fetchedData = await fetching.json();
        if (fetchedData) {
          DEBUGFetch(url, fetchedData);
          setLoading(false);
          setData(fetchedData.return);
          setError(false);
        }
      } catch (err) {
        setError(err);
        setLoading(false);
        setError(true);
      }
    };

    fetchingData();
  };

  return { data, loading, error, setData, setLoading, refresh };
};

const GETDoubleFetch = async (firstFetch = null, secondFetch = null, signal = null) => {
  const init = {
    method: 'GET',
    headers: Fetch.HEADERS()
  };

  try {
    let firstUrlFetch = firstFetch === null ? null :  await fetch(firstFetch, {signal}, init);
    let secondUrlFetch = secondFetch === null ? null : await fetch(secondFetch, {signal}, init);

    let firstResponse = firstUrlFetch === null ? null : await firstUrlFetch.json();
    let secondResponse = secondUrlFetch === null ? null : await secondUrlFetch.json();

    return [firstResponse, secondResponse]
  }
  catch(err) {
    console.log('ERROR ', err);
    return err;
  }
  
};

const POSTFetch = async (url, data) => {

  const init = {
    method: 'POST',
    headers: Fetch.HEADERS(),
    body: JSON.stringify(data),
  };

  return new Promise(function (resolve) {
    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.status === 400) {
          console.log(
            `status: ${fetchedData.status} - ${fetchedData.title} | See errors:`
          );
          console.log(fetchedData.errors);
        }
        else if (fetchedData.innerCode !== 0) {
          DEBUGFetch(url, fetchedData);
          resolve(fetchedData)
        }
        else {
          DEBUGFetch(url, fetchedData);
          resolve({ rowID: fetchedData.rowID, return: fetchedData.return });
        }

      })
      .catch((err) => {
        console.log(url + ' - Error:' + err);
      });
  });
};

const PUTFetch = async (url, data) => {
  const init = {
    method: 'PUT',
    headers: Fetch.HEADERS(),
    body: JSON.stringify(data),
  };

  return new Promise(function (resolve) {
    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.status === 400) {
          console.log(
            `status: ${fetchedData.status} - ${fetchedData.title} | See errors:`
          );
          console.log(fetchedData.errors);
        } else if (fetchedData.innerCode !== 0) {
          DEBUGFetch(url, fetchedData);
          resolve(fetchedData)
        } else {
          DEBUGFetch(url, fetchedData);
          resolve(fetchedData.return);
        }
      })
      .catch((err) => {
        console.log(url + ' - Error:' + err);
      });
  });
};

const DELETEFetch = async (url, id) => {
  const init = {
    method: 'DELETE',
    headers: Fetch.HEADERS(),
    body: JSON.stringify(id),
  };

  return new Promise(function (resolve) {
    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.status === 400) {
          console.log(
            `status: ${fetchedData.status} - ${fetchedData.title} | See errors:`
          );
          console.log(fetchedData.errors);
        } else if (fetchedData.innerCode !== 0) {
          DEBUGFetch(url, fetchedData);
          resolve(fetchedData)
        } else {
          DEBUGFetch(url, fetchedData);
          resolve(fetchedData.return);
        }

        DEBUGFetch(url, fetchedData);

        resolve(fetchedData.return);
      })
      .catch((err) => {
        console.log(url + ' - Error:' + err);
      });
  });
};

const DEBUGFetch = (url, fetched) => {
  if (fetched.timeInSeconds)
    console.log(url + ' - Backend Time:' + fetched.timeInSeconds);
  if (fetched.exception) console.log(url + ' - Exception:' + fetched.exception);
  if (fetched.innerCode) console.log(url + ' - InnerCode:' + fetched.innerCode);
  if (fetched.innerExceptionMessage)
    console.log(
      url + ' - InnerExceptionMessage:' + fetched.innerExceptionMessage
    );
  if (fetched.innerMessage)
    console.log(url + ' - InnerMessage:' + fetched.innerMessage);
};

export const Fetch = {
  DEBUGFetch,
  GETDoubleFetch,
  GETFetch,
  POSTFetch,
  PUTFetch,
  DELETEFetch,
  HEADERS,
};
