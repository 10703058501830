import React from 'react';

function SHContainer ({
    align,
    background,
    children,
    flex,
    flexCol,
    justify,
    margin,
    padding,
    sizeW,
    sizeH,
    gap
}) {
    return (
        <div style={{
            width: sizeW ? sizeW : '100%',
            height: sizeH ? sizeH : 'auto',
            background: background ? background : 'transparent',
            display: flex ? 'flex' : 'block',
            flexDirection: flexCol ? 'column' : 'row',
            justifyContent: justify ? justify : '',
            alignItems: align ? align : '',
            margin: margin ? margin : '0 0 0 0',
            padding: padding ? padding : '0 0 0 0',
            gap: gap ? gap : '',
        }}>
            {children}
        </div>
    );
};

export default React.memo(SHContainer)