import React from 'react';

// Estilos
import './SHHr.css'

function SHHr({
    margin
}) {
    return (
        <hr className={margin ? margin : 'my-1'} />
    )
}

export default React.memo(SHHr)
