// Resolución de la pantalla
function getScreenSize() {
    return {
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
    }
}

// Toma el string a recortar y la cantidad máxima asignada en la ejecución de la función.
function maxCharactersString(string, maxCharacters) {
    const {innerHeight, innerWidth} = getScreenSize()

    if(string && string !== undefined && string.length > maxCharacters) {

        if(innerWidth <= 1366 && innerHeight <= 768) {
            let newString = string.slice(0, maxCharacters - 10) + '...';
            return newString;
        } else {
            let newString = string.slice(0, maxCharacters) + '...';
            return newString;
        }

    }
    else return string
}

export default maxCharactersString;