
// Invierte formatos de fechas. Si es dd-mm-yyyy lo devuelve como yyyy-mm-dd, sino viceversa.
export function invertDate (date) {
    
    if(date && date !== '') {
        let datePart = date.match(/\d+/g);
        let dayOrYear, month, yearOrDay;
    
        dayOrYear = datePart[0];
        month = datePart[1];
        yearOrDay = datePart[2];
    
        let formattedDate = yearOrDay+'-'+month+'-'+dayOrYear;
        return formattedDate
    } else {
        return ''
    }
}