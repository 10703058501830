import React, { useContext } from 'react';
import { TextsContext } from '../../context/TextsContext';
import './SHCounter.css';

function SHCounter({quantity}) {
    const dataText = useContext(TextsContext)
    return (
        <>
            {quantity.length === 0 ?
                <p className='p-counter'>
                    {dataText.get('grid.coincidences')}
                   
                </p> :
                <p className='p-counter'>
                    {quantity.length === 1 ?
                        `${dataText.get('grid.wasFound')} ${quantity.length} ${dataText.get('grid.registration')}.` :
                        `${dataText.get('grid.counter')} ${quantity.length} ${dataText.get('grid.records')}.`}
                </p>}
        </>
    )
}

export default React.memo(SHCounter)
