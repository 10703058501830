import * as yup from 'yup';
import { Config } from '../env/Config';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';

// const GetAccountsProduct = () => { return Fetch.GETFetch(`${Config.WebAPI}/AccountProduct`) }
/* const GetProductList = () => {
  return Fetch.GETFetch(`${Config.WebAPI}/Product/ProductList`);
}; */
const GetAccountOtherProduct = (id) => {
  //get accountOtherProduct by id
  return Fetch.GETFetch(`${Config.WebAPI}/AccountOtherProduct/${id}`);
};

const GetAccountOtherProductsByAccount = (id) => {
  return Fetch.GETFetch(`${Config.WebAPI}/AccountOtherProduct/Account${id}`);
};
// TODO Resolver la invocacion del accountProduct/account agregandole la / al final

const PostAccountOtherProduct = async (data) => {
  return new Promise(function (resolve) {
    accountOtherProductSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountOtherProductSchema
          .validate(data, { abortEarly: false })
          .catch((result) => {
            Texts.ShowValidations('accountProduct', result);
            console.log(result);
          });
        resolve(false);
      } else {
        Fetch.POSTFetch(`${Config.WebAPI}/AccountOtherProduct`, data)
          .then((rtn) => {
            console.log(rtn);
            resolve(rtn);
          })
          .then(Texts.Toast('new'));
      }
    });
  });
};

const PutAccountOtherProduct = async (id, data) => {
  return new Promise(function (resolve) {
    accountOtherProductSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountOtherProductSchema
          .validate(data, { abortEarly: false })
          .catch((result) => {
            Texts.ShowValidations('accountOtherProduct', result);
            console.log(result);
          });
        resolve(false);
      } else {
        Fetch.PUTFetch(`${Config.WebAPI}/AccountOtherProduct/${id}`, data)
          .then((rtn) => {
            console.log(rtn);
            resolve(rtn);
          })
          .then(Texts.Toast('edit'));
      }
    });
  });
};

const DeleteAccountOtherProduct = async (id) => {
  return new Promise(function (resolve) {
    const url = `${Config.WebAPI}/AccountOtherProduct/${id}`;

    let init = { method: 'GET', headers: Fetch.HEADERS() };

    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        Texts.ShowConfirmation(
          'dialogs.confirmation.sureDelete',
          'dialogs.confirmation.sureDelete.extra'
        ).then((c) => {
          if (c) {
            Fetch.DELETEFetch(`${Config.WebAPI}/AccountOtherProduct/${id}`, id)
              .then((rtn) => {
                console.log(rtn);
                resolve(rtn);
              })
              .then(Texts.Toast());
          }
        });
      });
  });
};

let accountOtherProductSchema = yup.object().shape({
  accountID: yup.number().min(1).required(),
  name: yup.string().nullable().max(150).required(),
  measurementUnitID: yup.number().nullable().min(1).required(),
  quantityByUser: yup.number().nullable().min(0.1).required(),
  description: yup.string().nullable().max(200),
});

export const accountOtherProductService = {
  GetAccountOtherProduct,
  GetAccountOtherProductsByAccount,
  PostAccountOtherProduct,
  PutAccountOtherProduct,
  DeleteAccountOtherProduct,
};
