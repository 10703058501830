import * as yup from 'yup';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';
import GETFilterRows from '../assets/utils/GETFilterRows';
import { Config } from '../env/Config';

const GetAccountContactsInfo = () => { return Fetch.GETFetch(`${Config.WebAPI}/AccountContactInfo`) }
const GetAccountContactInfo = (id) => { return Fetch.GETFetch(`${Config.WebAPI}/AccountContactInfo/${id}`) }

const PostAccountContactInfo = async (data) => {

    return new Promise(function (resolve) {
        contactInfoSchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    contactInfoSchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('accountContactInfo', result)
                            console.log(result);
                        })
                    resolve(false)
                }
                else {
                    Fetch.POSTFetch(`${Config.WebAPI}/AccountContactInfo`, data)
                        .then(rtn => { console.log(rtn); resolve(rtn) })
                        .then(Texts.Toast('new'))
                    }
            })
    })
}

const PutAccountContactInfo = async (id, data) => {

    return new Promise(function (resolve) {
        contactInfoSchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    contactInfoSchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('accountContactInfo', result)
                            console.log(result);
                        })
                    resolve(false)
                }
                else {
                    Fetch.PUTFetch(`${Config.WebAPI}/AccountContactInfo/${id}`, data)
                        .then(rtn => { console.log(rtn); resolve(rtn) })
                        .then(Texts.Toast('edit'))
                    }
            })
    })
}

const GetFilterContactsInfo = async (query) => {
    return await GETFilterRows('/AccountContactInfo', query)
}

const DeleteAccountContactInfo = async (id) => {
    return new Promise(function (resolve) {
        const url = `${Config.WebAPI}/AccountContactInfo/${id}`

        let init = { method: 'GET', headers: Fetch.HEADERS() }

        fetch(url, init)
            .then(response => response.json())
            .then(fetchedData => {
                if (fetchedData.return.canBeDeleted) {
                    Texts.ShowConfirmation('dialogs.confirmation.sureDelete', 'dialogs.confirmation.sureDelete.extra')
                        .then(c => {
                            if (c) {
                                Fetch.DELETEFetch(`${Config.WebAPI}/AccountContactInfo/${id}`, id)
                                    .then(rtn => { console.log(rtn); resolve(rtn) })
                                    .then(Texts.Toast())
                            }
                        })
                }
                else {
                    Texts.ShowValidation('dialogs.information.cannotBeDeleted', 'dialogs.information.cannotBeDeleted.extra', fetchedData.return.associatedRows)
                }
            })
    })
}

let contactInfoSchema = yup.object().shape({
    accountContactID: yup.number().min(1).required(),
    channelID: yup.number().min(1).required(),
    channelTypeID: yup.number().min(1).required(),
    principal: yup.bool().required(),
    info: yup.string().nullable().max(150),
});

export const accountContactInfoService = {
    GetAccountContactInfo,
    GetAccountContactsInfo,
    GetFilterContactsInfo,
    PostAccountContactInfo,
    PutAccountContactInfo,
    DeleteAccountContactInfo
}