import { Config } from '../env/Config';
import { Fetch } from '../env/Fetch';

const Dummy = async () => {
  let url = `${Config.WebAPI}/Environment/Dummy`;
  let init = { method: 'GET', headers: Fetch.HEADERS() };

  return new Promise(function (resolve) {
    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        if (Config.ReadLS('lang') === '')
          Config.WriteLS(
            'lang',
            navigator.language.substring(0, 2).toUpperCase()
          );

        if (Config.ReadLS('texts').length > 0) resolve(true);
        else {
          Fetch.DEBUGFetch(url, fetchedData);
          const init = { method: 'GET', headers: Fetch.HEADERS() };
          url = `${Config.WebAPI}/Environment/Text`;
          return fetch(url, init);
        }
      })
      .then((response) => {
        if (response) return response.json();
      })
      .then((fetchedData) => {
        if (fetchedData) {
          Fetch.DEBUGFetch(url, fetchedData);
          Config.WriteLS('texts', fetchedData.return);
        }

        resolve(true);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const Properties = () => {
  const values = Config.ReadLS('props') || [];

  let reactSelectValues = {};

  values.map((obj) => {
    let entityName = Object.keys(obj);
    let newMap = obj[entityName].map((obj) => {
      switch (Config.ReadLS('lang')) {
        case 'ES':
          return { value: obj.valueInt, label: obj.propertyValueTextES };
        case 'EN':
          return { value: obj.valueInt, label: obj.propertyValueTextEN };
        case 'PT':
          return { value: obj.valueInt, label: obj.propertyValueTextPT };
        default:
          return { value: obj.valueInt, label: obj.propertyValueTextES };
      }
    });

    return (reactSelectValues = { ...reactSelectValues, [entityName]: newMap });
  });

  return reactSelectValues;
};

const TableValues = () => {
  const values = Config.ReadLS('tables') || [];

  let reactSelectValues = {};

  values.map((obj) => {
    let entityName = Object.keys(obj);
    let newMap = obj[entityName].map((obj) => {
      return {
        value: obj.tableValueID,
        label: obj.valueName,
      };
    });
    return (reactSelectValues = { ...reactSelectValues, [entityName]: newMap });
  });

  return reactSelectValues;
};

const GetOption = (options, value) => {
  let rtn = null;

  options.forEach((e) => {
    if (e.value === value) {
      rtn = e;
    }
  });

  return rtn;
};

const GetOptions = (options, values) => {
  if (values === null || values === undefined || values === '') return [];
  let rtn = [];

  if (Array.isArray(values)) {
    // Si es un array...
    options.forEach((e) => {
      values.forEach((v) => {
        if (v.id && e.value === v.id) {
          rtn.push(e);
        } else if (v.value && e.value === v.value) {
          rtn.push(e);
        }
      });
    });
  } else {
    // Si es string...
    let stringArray = values.split(','); // Convierte a array para mapear
    let numberArray = stringArray.map((string) => Number(string)); // Convierte a número

    options.forEach((e) => {
      numberArray.forEach((number) => {
        if (e.value === number) {
          rtn.push(e);
        }
      });
    });
  }
  return rtn;
};

const GetSearchResult = async (searchResult) => {
  const init = { method: 'GET', headers: Fetch.HEADERS() };
  if (searchResult) {
    try {
      const fetching = await fetch(
        `${Config.WebAPI}/Environment/searchGets?searchText=${searchResult}`,
        init
      );
      const fetchedData = await fetching.json();
      return fetchedData.return;
    } catch (err) {
      console.log(err);
    }
  } else {
    return Fetch.GETFetch(`${Config.WebAPI}/Environment/searchGets`, init);
  }
};

const GetRolesUsersByAccount = async (idAccount, profileCode) => {
  const init = { method: 'GET', headers: Fetch.HEADERS() };
  if (profileCode) {
    try {
      const fetching = await fetch(
        `${Config.WebAPI}/Account/UserAccount?id=${idAccount}&profileCode=${profileCode}`,
        init
      );
      const fetchedData = await fetching.json();

      return ParserToFullName(fetchedData.return);
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      const fetching = await fetch(
        `${Config.WebAPI}/Account/UserAccount?id=${idAccount}`,
        init
      );
      const fetchedData = await fetching.json();

      return ParserToFullName(fetchedData.return);
    } catch (err) {
      console.log(err);
    }
  }
};

const ParserToFullName = (users) => {
  if (Array.isArray(users)) {
    let usersFullNameArray = users.map(
      (obj) => (obj = { value: obj.id, label: obj.name + ' ' + obj.surname })
    );

    return usersFullNameArray;
  }
};

export const environmentService = {
  Dummy,
  GetOption,
  GetOptions,
  Properties,
  GetRolesUsersByAccount,
  ParserToFullName,
  TableValues,
  GetSearchResult,
};
