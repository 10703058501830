import React, { useContext, useRef, useState, useEffect } from 'react';
// Context
import { TextsContext } from '../../../../context/TextsContext';
// Componentes
import SHButton from '../../../SHButton/SHButton';
import SHIcon from '../../../SHIcon/SHIcon';
// Servicios
import { notificationService } from '../../../../services/notificationService';
// Estilos
import './notifications.css';
// Librerias
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// Utils
import maxCharactersString from '../../../../assets/utils/maxCharactersString';
// Otros
import SHLoading from '../../../SHLoading/SHLoading';
import { Config } from '../../../../env/Config';

const Notifications = () => {
  let navigate = useNavigate();

  const fetchTimeRef = useRef();
  const refreshTimeRef = useRef();
  const redirectTimeRef = useRef();

  const [open, setOpen] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const [unseenNotifications, setUnseenNotifications] = useState();
  const [btnText, setBtnText] = useState(false);
  const [notificationsNumber, setNotificationsNumber] = useState(5);
  const [visibleSeeMoreNotif, setVisibleSeeMoreNotif] = useState(true);
  const [loading, setLoading] = useState(false);

  const dataText = useContext(TextsContext);

  useEffect(() => { // PRIMER FETCH
        setLoading(true)
        const timerNoNotification = setTimeout(() => {
          notificationService.GetNotifications().then((res) => {
            if (res.return) {
              let unseenNotif = res.return.filter((notif) => !notif.viewed);
              setNotificationsData(res.return); // Guarda todas las notificaciones
              setUnseenNotifications(unseenNotif.length); // Guarda las notificaciones no vistas
              setLoading(false)
            }
          })
        }, 2000);
      // } 
      return () => {
        clearTimeout(timerNoNotification)
      }
  }, [])

  
  useEffect(() => { // Busca notificaciones nuevas cada 30 segundos
    if (!open) { // Si no esta abierto el cuadro de notificaciones
      if(notificationsData.length !== 0) { // Si hay notificaciones, se busca si hay nuevas
        const timerAddNotification = setInterval(
          () => notificationService.GetNotifications().then((res) => {
            if (res.return) {
              let unseenNotif = res.return.filter((notif) => !notif.viewed);
              setNotificationsData(res.return); // Guarda todas las notificaciones
              setUnseenNotifications(unseenNotif.length); // Guarda las notificaciones no vistas
            }
          }),
          60 * 1000 * 5
        );
        return () => {
          clearInterval(timerAddNotification);
        };
      }
    }
    return () => {
      clearTimeout(fetchTimeRef);
      clearTimeout(refreshTimeRef);
      clearTimeout(redirectTimeRef);
    };
  }, [notificationsData, open]);

  // Refresh de notificaciones
  const handleRefreshNotifications = (type, url) => {
      notificationService.GetNotifications() // Luego trae todas las notif.
        .then((res) => {
          let notificationResponse = res.return;
          if (!notificationResponse) {
            return;
          } else {
            switch (type) {
              case 'state':
                  let orderedData = notificationResponse.sort((obj) => {
                  if (!obj.viewed) return -1;
                  else return 1;
                });
                setNotificationsData(orderedData);
                return setLoading(false)
              case 'date':
                setNotificationsData(notificationResponse);
                return setLoading(false)
              default:
                setNotificationsData(notificationResponse);
                let unseenNotif = notificationResponse.filter(
                  (notif) => !notif.viewed
                );
                setUnseenNotifications(unseenNotif.length);
                url && navigate(url)
                window.location.reload()
                setLoading(false)
            }
          }
        });
  };

  // Ordena por fechas
  const handleOrderDates = () => {
    handleRefreshNotifications('date');
  };

  // Ordena por estado de leido o no
  const handleOrderStates = () => {
    handleRefreshNotifications('state');
  };

  // Marca como leída una notificación
  const handleViewNotification = (notificationID, viewed) => {
    if (viewed) {
      return;
    } else {
      notificationService.PutNotification(notificationID)
      .then(() => {
        handleRefreshNotifications();
      })
    }
  };

  // Cambia todo a leido
  const handleCheckAll = () => {
    return Swal.fire({
      title: dataText.get('dialogs.confirmation.checkAll'),
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#87c10af0',
      cancelButtonColor: '#6e7881',
    }).then((result) => {
      if (result.isConfirmed) {
        notificationService.PutViewAllNotification()
        .then(() => handleRefreshNotifications()) // Primero actualiza
      } else if (result.isDenied) {
        return;
      }
    });
  };

  // Ejecuta orden por estado o fecha según booleano
  const handleChangeBtnText = () => {
    setBtnText(!btnText);
    if (btnText) handleOrderDates();
    if (!btnText) handleOrderStates();
  };

  // Permite ver 5 notificaciones más (y en consecuencia activa el scroll)
  const handleSeeMore = () => {
    if (notificationsNumber < 10) {
      setNotificationsNumber(notificationsData.length);
      setVisibleSeeMoreNotif(false);
    }
  };

  // Redirección
  const handleRedirect = (notificationID, viewed, url) => {
      setLoading(true)
      if (viewed) {
        navigate(url);
        window.location.reload()
        return setTimeout(() => {
          setLoading(false)
        }, 500);
    } else {
      notificationService.PutNotification(notificationID)
      .then(() => {
          handleRefreshNotifications(null, url);
      })
    }
  };

  return (
    <div
      className={`btn-group buttonDropdown ${loading && 'set-blur'}`}
      onClick={() => setOpen(true)}
      onBlur={() => setOpen(false)}
    >
      <button
        type="button"
        className="btn btn-secondary dropdown-toggle"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        data-bs-auto-close="outside"
        aria-expanded="false"
        style={{filter: loading ? 'opacity(50%)' : '', background: '#8bc34a', border: 'none'}}
        disabled={loading}
      >
        <SHIcon type="bell" />
        <span
          className={
            notificationsData.length > 0 ? 'notifications' : 'noNotifications'
          }
        >
          {/* Cantidad de notificaciones mostradas en la campana del nav */}
          {unseenNotifications !== 0 && unseenNotifications}
        </span>
      </button>


        <ul
          className="dropdown-menu dropdown-menu-end navbar-custom dropDownStylesCustom"
          style={{
            backgroundColor: '#3e3f3a',
            minWidth: '25rem',
            maxHeight: notificationsNumber >= 10 ? '70vh' : 'auto',
            overflowY: notificationsNumber >= 10 ? 'scroll' : 'auto',
            scrollBehavior: notificationsNumber >= 10 ? 'smooth' : 'auto',
            margin: 0 // Este margin fixea un warning de Popper
          }}
        >
      {
        loading ? <SHLoading />
        :
        <>
          {notificationsData.length === 0 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.2rem',
              }}
            >
              {' '}
              <p style={{ color: '#fff' }}>No hay noticias</p>{' '}
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  <SHButton
                    action="accept"
                    disabled={notificationsData.length === 0}
                    text={dataText.get('notifications.checkAll')}
                    backgroundColor="transparent"
                    onClick={handleCheckAll}
                  />
                  <SHIcon type="check" color="#8bc34a" />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  <SHButton
                    action="accept"
                    text={
                      btnText
                        ? dataText.get('notifications.sortDate')
                        : dataText.get('notifications.sortState')
                    }
                    backgroundColor="transparent"
                    onClick={handleChangeBtnText}
                  />
                  <SHIcon type="reload" color="#29abe0" width="26" />
                </div>
              </div>
              {notificationsData.slice(0, notificationsNumber).map(
                ({
                  accountID,
                  notificationID,
                  entityID, // ID de evento u opp
                  entityTypeID, // Si es 240 es una opp, 241 es evento
                  actionString,
                  description,
                  viewed,
                  createdBy,
                  createdOnString,
                }) => {
                  return (
                    <li
                      key={notificationID}
                      className="dropdown-item px-3"
                      style={{
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: !viewed ? '90%' : '100%',
                        }}
                      >
                        <div
                          style={{ textDecoration: 'none', color: '#000' }}
                          onClick={() =>
                            handleRedirect(
                              notificationID,
                              viewed,
                              `/${Config.Version}/account/get/${accountID}/${
                                entityTypeID === 241 ? 'event' : 'opportunity'
                              }/${entityID}`
                            )
                          }
                        >
                          <p className="eventMessage">
                            {maxCharactersString(
                              `#${entityID} - ${description}`,
                              40
                            )}
                          </p>
                        </div>
                        <p style={{ color: '#fff', opacity: '0.5', margin: 0 }}>
                          {maxCharactersString(
                            `${createdOnString} - ${actionString} ${dataText.get('notifications.byWord')} ${createdBy}`,
                            42
                          )}
                        </p>
                      </div>
                      {/* Circulo verde */}
                      {!viewed && (
                        <div
                          style={{ width: '10%' }}
                          onClick={() =>
                            handleViewNotification(notificationID, viewed)
                          }
                        >
                          <div
                            style={{
                              borderRadius: '50%',
                              backgroundColor: 'green',
                              minWidth: '10px',
                              minHeight: '10px',
                              width: '0.7rem',
                              height: '0.7rem',
                            }}
                          ></div>
                        </div>
                      )}
                    </li>
                  );
                }
              )}
              {/* VER MAS  */}
              {notificationsData.length > 5 && visibleSeeMoreNotif && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '20%',
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                    onClick={handleSeeMore}
                  >
                    <p style={{ color: '#fff', margin: 0 }} className="seeMore">
                      {dataText.get('notifications.seeMore')}
                    </p>
                    <SHIcon type="add" color="#8bc34a" width="30" />
                  </div>
                </div>
              )}
            </>
          )}
        </>
        }
        </ul>
    </div>
  );
};

export default Notifications;
