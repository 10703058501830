import * as yup from 'yup';
import GETFilterRows from '../assets/utils/GETFilterRows';
import validationResponse from '../assets/utils/validationResponse';
import { Config } from '../env/Config';
import { CORESec } from '../env/CORESec';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';

const GetAccounts = (userID) => {
  if (userID) {
    // Toma el ID (para select) del usuario CORESec
    return Fetch.GETFetch(`${Config.WebAPI}/Account?user=${userID}`);
  } else {
    return Fetch.GETFetch(`${Config.WebAPI}/Account`);
  }
};

const GetAccount = async (id) => {
  const init = { method: 'GET', headers: Fetch.HEADERS() };
  try {
    const fetching = await fetch(`${Config.WebAPI}/Account/${id}`, init);
    const fetchedData = await fetching.json();
    return fetchedData.return;
  } catch (err) {
    console.log(err);
  }
};

const PostAccount = async (data) => {
  return new Promise(function (resolve) {
    accountSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountSchema.validate(data, { abortEarly: false }).catch((result) => {
          Texts.ShowValidations('account', result);
          console.log(result);
        });
        resolve(false);
      } else {
        Fetch.POSTFetch(`${Config.WebAPI}/Account`, data)
          .then((rtn) => {
            switch (rtn.innerCode) {
              case 610:
                Texts.ShowValidations(
                  'account',
                  validationResponse('duplicated', rtn.innerMessage)
                );
                break;

              default:
                return resolve(rtn);
            }
          })
          .then(Texts.Toast('new'));
      }
    });
  });
};

const PutAccount = async (id, data) => {
  return new Promise(function (resolve) {
    accountSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountSchema.validate(data, { abortEarly: false }).catch((result) => {
          Texts.ShowValidations('account', result);
          console.log(result);
        });
        resolve(false);
      } else {
        Fetch.PUTFetch(`${Config.WebAPI}/Account/${id}`, data)
          .then((rtn) => {
            switch (rtn.innerCode) {
              case 610:
                Texts.ShowValidations(
                  'account',
                  validationResponse('duplicated', rtn.innerMessage)
                );
                break;

              default:
                return resolve(rtn);
            }
          })
          .then(Texts.Toast('edit'));
      }
    });
  });
};

const DeleteAccount = async (id) => {
  return new Promise(function (resolve) {
    const url = `${Config.WebAPI}/Account/${id}`;

    let init = { method: 'GET', headers: Fetch.HEADERS() };

    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.return.canBeDeleted) {
          Texts.ShowConfirmation(
            'dialogs.confirmation.sureDelete',
            'dialogs.confirmation.sureDelete.extra'
          ).then((c) => {
            if (c) {
              Fetch.DELETEFetch(`${Config.WebAPI}/Account/${id}`, id)
                .then((rtn) => {
                  console.log(rtn);
                  resolve(rtn);
                })
                .then(Texts.Toast());
            }
          });
        } else {
          Texts.ShowValidation('dialogs.information.cannotBeDeleted', '');
        }
      });
  });
};

const GetFilterAccounts = async (query) => {
  return await GETFilterRows('/Account', query);
};

const customVal = (string, size, maxMulti = string.length, by10 = true) => {
  if (!by10) size = 1;

  for (let n = 1; n <= size; n++) {
    let soma = 0;
    let multi = 2;

    for (let i = string.length - 1; i >= 0; i--) {
      soma += multi * parseInt(string.charAt(i));
      multi++;
      if (multi > maxMulti) multi = 2;
    }

    let dig;

    if (by10) dig = ((soma * 10) % 11) % 10;
    else {
      dig = soma % 11;
      if (dig === 10) dig = 0;
    }

    string += dig;
  }

  return string.substr(string.length - size, size);
};

export function createCNPJ(CNPJ) {
  CNPJ = CNPJ.replace(/[^\d]+/g, '');

  if (CNPJ === '') return false;
  if (CNPJ.length !== 14) return false;

  // Elimina CNPJs no válidos
  if (
    CNPJ === '00000000000000' ||
    CNPJ === '11111111111111' ||
    CNPJ === '22222222222222' ||
    CNPJ === '33333333333333' ||
    CNPJ === '44444444444444' ||
    CNPJ === '55555555555555' ||
    CNPJ === '66666666666666' ||
    CNPJ === '77777777777777' ||
    CNPJ === '88888888888888' ||
    CNPJ === '99999999999999'
  ) {
    return false;
  }

  let size = CNPJ.length - 2;
  let numbers = CNPJ.substring(0, size);
  let soma = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    soma += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }

  const results = [0, 0];
  results[0] = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  size = size + 1;
  numbers = CNPJ.substring(0, size);
  soma = 0;
  pos = size - 7;

  for (let i = size; i >= 1; i--) {
    soma += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }

  results[1] = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  return results;
}

const createCPF = (CPF) => {
  CPF = CPF.replace(/[^\d]+/g, '');
  if (CPF === '00000000000') return false;
  const rest = customVal(CPF.substr(0, CPF.length - 2), 2, 12);
  return rest;
};

const validateCPF = (CPF) => {
  let empty = CPF.replace(/^[0-9.-]*$/gm, '');
  if (empty !== '') return false;

  CPF = CPF.replace(/[^\d]+/g, '');
  if (CPF.length !== 11) return false;

  const equals = /^(?!.*(\d)\1{10}).*$/;
  if (!CPF.match(equals)) return false;

  const rest = createCPF(CPF);

  if (!rest || rest !== CPF.substr(-2)) return false;

  return true;
};

const validateCNPJ = (CNPJ) => {
  let empty = CNPJ.replace(/^[0-9./-]*$/gm, '');
  if (empty !== '') return false;

  CNPJ = CNPJ.replace(/[^\d]+/g, '');
  let size = CNPJ.length - 2;
  const digits = CNPJ.substring(size);
  const results = createCNPJ(CNPJ);

  if (
    !results ||
    results[0] !== parseInt(digits.charAt(0), 10) ||
    results[1] !== parseInt(digits.charAt(1), 10)
  ) {
    return false;
  }

  return true;
};

const validateCPFCNPJ = (value) => {
  return validateCPF(value) || validateCNPJ(value);
};

const taxNumberRequiredValidation = (value, customerFromAbroad) => {
  if (value === '' && customerFromAbroad) {
    return true;
  } else if (value === '' && !customerFromAbroad) {
    return false;
  }

  if (CORESec.GetCountryAccessLevels().id === 1) {
    // Argentina
    return validateCUILCUIT(value);
  } else {
    return validateCPFCNPJ(value);
  }
};

// Validaciones CUIT / CUIL
export function isValidCUITCUIL(CUIT) {
  // if (CUIT.length !== 13) return false; // Codigo anterior a los dos condicionales siguientes
  if (CUIT.includes('-') && CUIT.length !== 13) return false;
  if (!CUIT.includes('-') && CUIT.length !== 11) return false;

  if (CUIT.includes('-')) {
    let cuitArray = CUIT.split('');
    if (cuitArray[2] !== '-' || cuitArray[11] !== '-') return false;
  }

  // Elimina CUITs no válidos
  if (
    CUIT === '00000000000000' ||
    CUIT === '11111111111111' ||
    CUIT === '22222222222222' ||
    CUIT === '33333333333333' ||
    CUIT === '44444444444444' ||
    CUIT === '55555555555555' ||
    CUIT === '66666666666666' ||
    CUIT === '77777777777777' ||
    CUIT === '88888888888888' ||
    CUIT === '99999999999999'
  ) {
    return false;
  }

  let rv = false;
  let results = 0;
  let CUITNumber;
  if (CUIT.includes('-')) {
    CUITNumber = CUIT.replaceAll('-', '');
  } else {
    CUITNumber = CUIT;
  }

  const codes = '6789456789';
  let size = parseInt(CUITNumber[CUITNumber.length - 1]);
  let x = 0;

  while (x < 10) {
    let validator = parseInt(codes.substring(x, x + 1));
    if (isNaN(validator)) validator = 0;
    let digit = parseInt(CUITNumber.substring(x, x + 1));
    if (isNaN(digit)) digit = 0;
    let digitValidation = validator * digit;
    results += digitValidation;
    x++;
  }
  results = results % 11;

  rv = results === size;

  return rv;
}

const validateCUILCUIT = (value) => {
  return isValidCUITCUIL(value);
};

let accountSchema = yup.object().shape({
  name: yup.string().nullable().max(50).required(),
  socialName: yup.string().nullable().max(50).required(),
  customerFromAbroad: yup.bool(),
  taxNumber: yup
    .string()
    .trim()
    .nullable()
    .when('customerFromAbroad', {
      is: (customerFromAbroad) => customerFromAbroad === true,
      then: yup.string().test(
        '',
        (value, obj) =>
          Texts.dataText.get('dialogs.schemaValidations.taxNumber'),
        (value, obj) =>
          taxNumberRequiredValidation(value, obj.parent.customerFromAbroad)
      ),
      otherwise: yup
        .string()
        .required()
        .test(
          '',
          (value, obj) =>
            Texts.dataText.get('dialogs.schemaValidations.taxNumber'),
          (value, obj) =>
            taxNumberRequiredValidation(value, obj.parent.customerFromAbroad)
        ),
    }),
  typeID: yup.number().nullable().min(1).required(),
  markets: yup.array().nullable(),
  industryID: yup.number().nullable().min(1),
  classificationID: yup.number().nullable().min(1),
  potentialID: yup.number().nullable().min(1),
  stateID: yup.number().min(1).nullable().required(),
  sizeID: yup.number().min(1).nullable(),
  phone: yup
    .string()
    .nullable()
    .max(20)
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, () =>
      Texts.dataText.get('dialogs.schemaValidations.invalid')
    ),
  webURL: yup
    .string()
    .nullable()
    .matches(
      /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
      () => Texts.dataText.get('dialogs.schemaValidations.invalid')
    ),
  description: yup.string().nullable().max(1500),
});

export const accountService = {
  GetAccounts,
  GetAccount,
  GetFilterAccounts,
  PostAccount,
  PutAccount,
  DeleteAccount,
};
