import React from 'react';

// Estilos
import './SHHeader.css';

function SHHeader({
    entityTitle, // titulos de entidades (Cuentas, Opp, Events)
    titleId, // #ID del GET 
    detailTitle, // título detalle de la entidad
    children,
    state,
    potential,
    classification,
    styles,
    hStyles
}) {
    return (
        <div className="header-container" style={styles}>
            <div className="children-gap">
                <h5  style={hStyles} className="title">
                    {entityTitle}
                    {titleId &&
                        <span className="title-id">
                            {titleId}
                        </span>}
                    {detailTitle}
                </h5>
                <div className="children-gap pt-1">
                    {state && <span className='detail-badge'>Estado:<span className='detail-info'>{state}</span></span>}
                    {potential && <span className='detail-badge'>Potencial:<span className='detail-info'>{potential}</span></span>}
                    {classification && <span className='detail-badge'>Clasificación:<span className='detail-info'>{classification}</span></span>}
                </div>
            </div>
            <div className="children-gap">
                {children}
            </div>
        </div>
    )
};

export default React.memo(SHHeader)