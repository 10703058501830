import * as yup from 'yup';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';
import GETFilterRows from '../assets/utils/GETFilterRows';
import { Config } from '../env/Config';

const GetAccountOpportunityProducts = () => { return Fetch.GETFetch(`${Config.WebAPI}/AccountOpportunityProduct`) }
const GetAccountOpportunityProduct = (id) => { return Fetch.GETFetch(`${Config.WebAPI}/AccountOpportunityProduct/${id}`) }

const PostAccountOpportunityProduct = async (data) => {

    return new Promise(function (resolve) {
        accountOpportunityProductSchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    accountOpportunityProductSchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('accountOpportunityProduct', result)
                        })
                    resolve(false)
                }
                else {
                    Fetch.POSTFetch(`${Config.WebAPI}/AccountOpportunityProduct`, data)
                        .then(rtn => { console.log(rtn); resolve(rtn) })
                        .then(Texts.Toast('new'))
                }
            })
    })
}


const PutAccountOpportunityProduct = async (id, data) => {

    return new Promise(function (resolve) {
        accountOpportunityProductSchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    accountOpportunityProductSchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('accountOpportunityProduct', result)
                        })
                    resolve(false)
                }
                else {
                    Fetch.PUTFetch(`${Config.WebAPI}/AccountOpportunityProduct/${id}`, data)
                        .then(rtn => { console.log(rtn); resolve(rtn) })
                        .then(Texts.Toast('edit'))
                }
            })
    })
}

const GetFilterAccountOpportunityProduct = async (query) => {
    return await GETFilterRows('/AccountOpportunityProduct', query)
}

const DeleteAccountOpportunityProduct = async (id) => {
    return new Promise(function (resolve) {
        const url = `${Config.WebAPI}/AccountOpportunityProduct/${id}`

        let init = { method: 'GET', headers: Fetch.HEADERS() }

        fetch(url, init)
            .then(response => response.json())
            .then(fetchedData => {
                Texts.ShowConfirmation('dialogs.confirmation.sureDelete', 'dialogs.confirmation.sureDelete.extra')
                    .then(c => {
                        if (c) {
                            Fetch.DELETEFetch(`${Config.WebAPI}/AccountOpportunityProduct/${id}`, id)
                                .then(rtn => { console.log(rtn); resolve(rtn) })
                                .then(Texts.Toast())
                        }
                    })
            })
    })
}

let accountOpportunityProductSchema = yup.object().shape({
    accountOpportunityID: yup.number().min(1).required(),
    productID: yup.number().min(1).required(),
    measurementUnitID: yup.number().min(1).required(),
    quantity: yup.number().min(1).nullable().required(),
    quantityByUser: yup.number().min(1).nullable(),
    currencyID: yup.number().min(1).required(),
    price: yup.number().min(1).nullable().required(),
    stateID: yup.number().min(1).required(),
    condition: yup.string().nullable().max(1500),
    observation: yup.string().nullable().max(1500),
});


export const accountOpportunityProductService = {
    GetAccountOpportunityProducts,
    GetAccountOpportunityProduct,
    GetFilterAccountOpportunityProduct,
    PostAccountOpportunityProduct,
    PutAccountOpportunityProduct,
    DeleteAccountOpportunityProduct,
}