/*
 * Más info en:
 * - https://create-react-app.dev/docs/adding-custom-environment-variables/
 * - https://create-react-app.dev/docs/deployment#customizing-environment-variables-for-arbitrary-build-environments
 */

import SecureLS from 'secure-ls';

// Idioma por defecto de la aplicación.
//const DefaultLang = process.env.REACT_APP_DEFAULT_LANG;
const DefaultLang = 'ES';
// Permite consultar en que modo se encuentra todo el entorno
//const Mode = process.env.NODE_ENV;
const Mode = 'production';
// Fecha de entrega de versión
//const ReleaseDate = process.env.REACT_APP_RELEASE_DATE;
const ReleaseDate = '2022.08.05';
// Indica el tiempo máximo, permitido, sin actividad,
// antes que expire la sesión en curso
//const SessionTime = parseInt(process.env.REACT_APP_SESSION_TIME);
const SessionTime = 3600;
// Indica el tiempo máximo de espera hasta recibir respuesta desde
// el Backend ante una invocación (useFetch)
//const FetchTimeout = parseInt(process.env.REACT_APP_FETCH_TIMEOUT);
const FetchTimeout = 10;
const MaxFetchTimeout = 70;
// Información de versión - Se visualiza en la aplicación
//const Version = process.env.REACT_APP_VERSION;
const Version = 'V1.0.1';
// URL de vinculación al Backend
//const WebAPI = process.env.REACT_APP_WEB_API;
//const WebAPI = 'https://localhost:5001';
const WebAPI = 'http://200.58.123.149:8080';
//const WebAPI = 'http://crm-test.ensolsa.com/webapi';
// Indica si la version está en modo Trabajo en Curso o no
// Esta propiedad se utiliza para saltear algunos controles y así
// agilizar el trabajo en tiempo de desarrollo.
//const WIP = process.env.REACT_APP_WIP === 'true' ? true : false;
const WIP = true;
// Sólo para uso interno y local (nunca subirlo en TRUE) para cuando no
// podemos vincularnos correctamente con CORESec del Cliente
const NoLogin = false;

function ReadLS(key) {
  var ls = new SecureLS({ isCompression: true });

  const value = ls.get(key);

  return value ? value : '';
}

function WriteLS(key, value) {
  var ls = new SecureLS({ isCompression: true });

  ls.set(key, value);
  return ls.get(key);
}

function RemoveLS(key = null) {
  var ls = new SecureLS({ isCompression: true });

  if (key) ls.remove(key);
  else ls.removeAll();
}

export const Config = {
  ReadLS,
  RemoveLS,
  WriteLS,
  DefaultLang,
  Mode,
  ReleaseDate,
  SessionTime,
  FetchTimeout,
  MaxFetchTimeout,
  Version,
  WebAPI,
  WIP,
  NoLogin,
};
