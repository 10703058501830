import React, { useContext } from 'react';

// Textos
import { TextsContext } from '../../context/TextsContext';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import SHCheckbox from '../SHCheckbox/SHCheckbox';

import './SHTable.css';

import {
  useTable,
  usePagination,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useExpanded,
} from 'react-table';
import SHScrollTop from '../SHScrollTop/SHScrollTop';

import { CORESec } from '../../env/CORESec';
import { Config } from '../../env/Config';

function SHTable({
  columns, // Columnas
  data, // Filas
  checkbox, // Columna de checkboxes
  entity, // Entidad actual en la cual estamos parados (ej: 'account')
  onClick, // Funcion extra al hacer click en un registro
  noRedirect, // Para cuando necesitamos que no cambie la URL al hacer click en un registro
  btnDeleteFrom, // Prop para que la tabla pueda hacer uso del servicio DELETE
  operationName, // Operación de coresec con la cuál sabemos si el usuario tiene habilitado la acción de eliminar
  stickyHeader,
  btnScrollTop, // Boton de scroll top. Si una tabla no lo requiere enviar este parametro en TRUE
}) {
  const dataText = useContext(TextsContext);

  let location = useLocation();
  let subEntity = location.pathname; // Obtenemos la URL
  let arrayLocation = subEntity.split('/'); // Hacemos un array separando las palabras por '/'
  subEntity = arrayLocation[2].toString(); // Usamos el tercer elemento que sera 'account', 'event', 'opportunity', 'products' o 'reports'

  function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          entity,
          btnDeleteFrom: btnDeleteFrom,
          operationName,
        },
      },
      useSortBy,
      useExpanded,
      usePagination,
      useFlexLayout,
      useResizeColumns,
      useRowSelect,
      (hooks) => {
        checkbox &&
          hooks.visibleColumns.push((columns) => [
            // Esta va a ser la columna para la selección con checkbox
            {
              id: 'selection',
              // El header usa el método de la tabla llamado getToggleAllRowsSelectedProps para mostrar el checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <SHCheckbox
                    justify="center"
                    align="center"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              width: 40,
              // La celda(cell) puede usar el método individual de la celda llamado getToggleRowSelectedProps para mostrar el checkbox
              Cell: ({ row }) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <SHCheckbox
                    justify="center"
                    align="center"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            ...columns, // El resto de columnas
          ]);
      }
    );

    let navigate = useNavigate();

    const getID = (row) => {
      switch (entity) {
        case 'account':
          return row.accountID;
        case 'product':
          return row.productID;
        default:
          return;
      }
    };

    const editMode = (subEntity) => {
      // Envia el nombre de subEntity para que accountGet sepa quién debe estar en editMode
      switch (subEntity) {
        case 'account':
          return null;
        case 'event':
          return 'event';
        case 'opportunity':
          return 'opportunity';
        case 'contact':
          return 'contact';
        default:
          return null;
      }
    };
    const selectedRowID = (row, subEntity) => {
      // Envia el dato ID según subEntity
      switch (subEntity) {
        case 'account':
          return null;
        case 'event':
          return row.original.accountEventID;
        case 'opportunity':
          return row.original.accountOpportunityID;
        case 'products':
          return null;
        default:
          return null;
      }
    };

    // Funciones de la Paginación
    const handleGoToPage = React.useCallback(() => gotoPage(0), [gotoPage]);
    const handleGoToPageNumber = React.useCallback(
      (e) => {
        const page = e.target.value ? Number(e.target.value) - 1 : 0;
        gotoPage(page);
      },
      [gotoPage]
    );
    const handlePreviousPage = React.useCallback(
      () => previousPage(),
      [previousPage]
    );
    const handleNextPage = React.useCallback(() => nextPage(), [nextPage]);
    const handleGoToPage2 = React.useCallback(
      () => gotoPage(pageCount - 1),
      [gotoPage, pageCount]
    );
    const handleSetPageSize = React.useCallback(
      (e) => {
        setPageSize(Number(e.target.value));
      },
      [setPageSize]
    );

    // Encabezado de la Tabla
    const TableHeader = React.useMemo(
      () =>
        headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th
                key={index}
                className="th"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                }
              </th>
            ))}
          </tr>
        )),
      [headerGroups]
    );

    const handleSession = () => {
      if (!CORESec.validateSesion()) {
        CORESec.Logout();
        navigate(`/${Config.Version}/login`);
      }
    };

    return (
      <>
        <table
          className="table table-sm table-hover table-striped table-responsive mb-1 "
          {...getTableProps()}
        >
          {/* ENCABEZADO DE LA TABLA */}

          <thead
            className="text-center"
            style={{
              fontSize: 'small',
              zIndex: stickyHeader ? '1000' : '',
              position: stickyHeader ? 'sticky' : '',
              right: stickyHeader ? 0 : '',
              left: stickyHeader ? 0 : '',
            }}
          >
            {TableHeader}
          </thead>

          {/* CUERPO DE LA TABLA */}

          <tbody {...getTableBodyProps()} style={{ fontSize: '12px' }}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  {entity ? (
                    <>
                      <tr
                        onClick={handleSession}
                        {...row.getRowProps()}
                        className="tr"
                      >
                        {row.cells.map((cell) => {
                          return cell.column.id === 'actions' ? (
                            <td key={cell.row.id} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ) : (
                            <td
                              onClick={() =>
                                onClick ? onClick(cell.row.original) : {}
                              }
                              {...cell.getCellProps()}
                            >
                              <>
                                {noRedirect ? (
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    key={cell.row.id}
                                  >
                                    {cell.render('Cell')}
                                  </div>
                                ) : (
                                  <Link
                                    to={`/${
                                      Config.Version
                                    }/${entity}/get/${getID(row.original)}`}
                                    style={{
                                      textDecoration: 'none',
                                      cursor: 'pointer',
                                      color: '#0e0e0e',
                                    }}
                                    state={{
                                      subEntity: `${subEntity}`, // Sub-entidades ('account', 'event', 'opportunity, etc)
                                      editMode: editMode(subEntity), // Modo edición
                                      rowID: selectedRowID(row, subEntity), // Para saber que tipo de ID enviar
                                      rowValues: cell.row.original, // Toda la data del registro seleccionado
                                    }}
                                  >
                                    {cell.render('Cell')}
                                  </Link>
                                )}
                              </>
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr {...row.getRowProps()} className="td">
                        {row.cells.map((cell) => {
                          return (
                            <td key={cell.row.id} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        {/* PAGINADO */}

        <div
          className="d-flex justify-content-between align-items-center"
          style={{ fontSize: '12px' }}
        >
          <div className="d-flex align-items-center">
            <span>
              {dataText.get('grid.page')}
              <strong className="ms-1">
                {pageIndex + 1} / {pageOptions.length}
              </strong>
            </span>
            <span className="d-flex align-items-center ms-1">
              - {dataText.get('grid.goToPage')}:
              <input
                className="form-control form-control-sm ms-1"
                style={{ width: '50px', fontSize: '12px' }}
                type="number"
                defaultValue={pageIndex + 1}
                onChange={handleGoToPageNumber}
              />
            </span>
          </div>
          <div className="d-flex gap-1">
            <button
              className="btn btn-sm btn-secondary"
              style={{ padding: '0 5px', height: '25px' }}
              onClick={handleGoToPage}
              disabled={!canPreviousPage}
            >
              {'<<'}
            </button>
            <button
              className="btn btn-sm btn-secondary"
              style={{ padding: '0 5px', height: '25px' }}
              onClick={handlePreviousPage}
              disabled={!canPreviousPage}
            >
              {'<'}
            </button>
            <button
              className="btn btn-sm btn-secondary"
              style={{ padding: '0 5px', height: '25px' }}
              onClick={handleNextPage}
              disabled={!canNextPage}
            >
              {'>'}
            </button>
            <button
              className="btn btn-sm btn-secondary"
              style={{ padding: '0 5px', height: '25px' }}
              onClick={handleGoToPage2}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <select
              className="form-select form-select-sm"
              style={{ width: 'min-content', fontSize: '12px' }}
              value={pageSize}
              onChange={handleSetPageSize}
            >
              {[10, 20, 30, 40, 50, 100].map((pageSize) => {
                return (
                  <option key={pageSize} value={pageSize}>
                    {dataText.get('grid.show')} {pageSize}
                  </option>
                );
              })}
            </select>
            <div>{pageSize >= 20 && !btnScrollTop && <SHScrollTop />}</div>
          </div>
        </div>
      </>
    );
  }
  return React.useMemo(
    () => <Table columns={columns} data={data} />,
    [columns, data]
  );
}

export default React.memo(SHTable);
