import * as yup from 'yup';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';
import GETFilterRows from '../assets/utils/GETFilterRows';
import { Config } from '../env/Config';

const GetAccountEvents = (userID) => {
  if(userID){
    return Fetch.GETFetch(`${Config.WebAPI}/AccountEvent?accountUsers=${userID}&responsible=${userID}`);
  } else {
    return Fetch.GETFetch(`${Config.WebAPI}/AccountEvent`);
  }
};
const GetAccountEvent = (id) => {
  let init = { method: 'GET', headers: Fetch.HEADERS() };
  fetch(`${Config.WebAPI}/AccountEvent/${id}`, init)
  .then(res => res.json())
  .then(res => {
    return res
  })
  .catch(err => console.log(err))
};

const GetAccountEventsByAccount = (id) => {
  return Fetch.GETFetch(`${Config.WebAPI}/AccountEvent/Account${id}`);
};

const GetFilterAccountEventsByAccount = async (query, id) => {
  return await GETFilterRows(`/AccountEvent/Account${id}`, query)
}

const PostAccountEvent = (data) => {
  return new Promise(function (resolve) {
    accountEventSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountEventSchema
          .validate(data, { abortEarly: false })
          .catch((result) => {
            Texts.ShowValidations('accountEvent', result);
            console.log(result);
          });
        resolve(false);
      } else {
        Fetch.POSTFetch(`${Config.WebAPI}/AccountEvent`, data)
          .then((rtn) => {
            console.log(rtn);
            resolve(rtn);
          })
          .then(Texts.Toast('new'));
      }
    });
  });
};

const PutAccountEvent = async (id, data) => {
  return new Promise(function (resolve) {
    accountEventSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountEventSchema
          .validate(data, { abortEarly: false })
          .catch((result) => {
            Texts.ShowValidations('accountEvent', result);
            console.log(result);
          });
        resolve(false);
      } else {
        Fetch.PUTFetch(`${Config.WebAPI}/AccountEvent/${id}`, data)
          .then((rtn) => {
            console.log(rtn);
            resolve(rtn);
          })
          .then(Texts.Toast('edit'));
      }
    });
  });
};

const DeleteAccountEvent = async (id) => {
  return new Promise(function (resolve) {
    const url = `${Config.WebAPI}/AccountEvent/${id}`;

    let init = { method: 'GET', headers: Fetch.HEADERS() };

    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.return.canBeDeleted) {
          Texts.ShowConfirmation(
            'dialogs.confirmation.sureDelete',
            'dialogs.confirmation.sureDelete.extra'
          ).then((c) => {
            if (c) {
              Fetch.DELETEFetch(`${Config.WebAPI}/AccountEvent/${id}`, id)
                .then((rtn) => {
                  console.log(rtn);
                  resolve(rtn);
                })
                .then(Texts.Toast());
            }
          });
        } else {
          Texts.ShowValidation('dialogs.information.cannotBeDeleted', '');
        }
      });
  });
};

const GetFilterEvents = async (query) => {
  return await GETFilterRows('/AccountEvent', query);
};

const GetFilterEvent = async (id, query) => {
  return await GETFilterRows(`/AccountEvent/${id}`, query);
};

let accountEventSchema = yup.object().shape({
  accountID: yup.number().min(1).required(),
  title: yup.string().nullable().max(50).required(),
  typeID: yup.number().nullable().min(1).required(),
  stateID: yup.number().nullable().min(1).required(),
  dueDate: yup.date().nullable(),
  responsibleID: yup.number().nullable().min(1),
  parentEventID: yup.number().min(1),
  description: yup.string().nullable().max(1500),
  closed: yup.bool().required(),
  follow: yup.bool().required(),
  attachments: yup.array().nullable(),
});

export const accountEventService = {
  GetAccountEvent,
  GetAccountEvents,
  GetAccountEventsByAccount,
  GetFilterAccountEventsByAccount,
  GetFilterEvent,
  GetFilterEvents,
  PostAccountEvent,
  PutAccountEvent,
  DeleteAccountEvent,
};
