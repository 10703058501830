import React from 'react';

// Estilos
import './SHLabel.css';

function SHLabel({
    children,
    color,
    fontSize,
    fnWeight,
    margin,
    textAlign,
    htmlFor,
    id
}) {
    return (
        <label id={id} style={{
            color: color ? color : '',
            fontWeight: fnWeight ? fnWeight : 'regular',
            margin: margin ? margin : 'auto',
            fontSize: fontSize ? fontSize : 'small',
            whiteSpace: 'nowrap',
            textAlign: textAlign ? textAlign : '',
        }}>
            {children}
        </label>
    )
}

export default SHLabel;