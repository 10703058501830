import React, { useState, useContext } from 'react';

// Context
import { TextsContext } from '../../context/TextsContext';
import { Texts } from '../../env/Texts'

import { Navigate } from 'react-router-dom';

import { CORESec } from '../../env/CORESec';

import SHButton from  '../../components/SHButton/SHButton';
import SHCard from  '../../components/SHCard/SHCard';
import SHLabel from  '../../components/SHLabel/SHLabel';
import SHInput  from  '../../components/SHInput/SHInput';
import SHLoading from '../../components/SHLoading/SHLoading';

import SecureLS from 'secure-ls';
import { Config } from '../../env/Config';

export default function Login( {logout}) {

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(CORESec.GetSession());
    const [localStorage, setLocalStorage] = useState(new SecureLS({isCompression: true}));

    const dataText = useContext(TextsContext);
    
    const handleChangeUser = (e) => {
        setUser( e.target.value)
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        
        if ((user !== '' && password !== '')) {
            setLoading(true);
            CORESec.ValidateUser(user, password)
            .then(validate => { 
                if (validate) {
                    setLocalStorage(localStorage.set("LastActivity", new Date()))
                    setRedirect(true)
                }
                else {
                    Texts.ShowValidation('login.valUnauthorized', 'login.valInput')
                }
            })  
            .then(() => setLoading(false))
        }
        else
            Texts.ShowValidation('login.login', 'login.valInput')
    }

    if (dataText === undefined) {
        window.location.reload()
    }
    if (logout) { 
        CORESec.Logout()
        return <Navigate to={`/${Config.Version}/account`} />
    }
    if (loading) return <SHLoading />
    if (redirect) return <Navigate to={`/${Config.Version}/account`} />

    const generalClass = `d-flex flex-column justify-content-center align-items-center col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mx-3`;

    return (
        <>
            <style type="text/css">{`.navbar {display: none}`}</style>
            <style type="text/css">{`.breadCrumbNav {display: none}`}</style>
            <div className={generalClass} style={{ height: '100vh' }}>
                <div className='d-flex justify-content-center align-items-center'>
                    <img alt='brand-logo' width='100%' className='mb-4' src='/logoSec.png' />
                </div>
                <SHCard
                    header={dataText.get("login.getInto")}
                    headerCenter
                    footer={
                        <div className='d-flex flex-column text-center' style={{ fontSize: 'small', fontWeight: 'bold' }}>
                            <a className="text-decoration-none" href='/' style={{ fontSize: 'small' }}>{dataText.get("login.forgotPassword")}</a>
                        </div>
                    }
                    alignFooter='center'
                >
                    <form onSubmit={handleSubmit} className='row g-2' >
                        <SHLabel id={'login-message'} textAlign='center' margin='0.5em 0 0 0'>{dataText.get("login.valInput")}</SHLabel>
                        <SHInput iconType="user" value={user}  onChange={handleChangeUser}  name='username' icon='user' placeholder={dataText.get('login.user')} />
                        <SHInput iconType="lock" hasPassword value={password}  onChange={handleChangePassword} name='password' icon='lock' placeholder={dataText.get('login.password')} />
                        <div className="d-grid">
                            <SHButton type="submit" action='login' />
                        </div>
                    </form >
                </SHCard>
            </div>
        </>
    )
}