import * as yup from 'yup';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';
import GETFilterRows from '../assets/utils/GETFilterRows';
import { Config } from '../env/Config';

const GetAccountNotes = () => {
  return Fetch.GETFetch(`${Config.WebAPI}/AccountNote`);
};
const GetAccountNote = (id) => {
  return Fetch.GETFetch(`${Config.WebAPI}/AccountNote/${id}`);
};

const GetAccountNoteByAccount = (id) => {
  return Fetch.GETFetch(
    `${Config.WebAPI}/AccountNote/Account${id}`
  );
};

const GetAccountNoteAndAttachments = (accountNoteID) => {
  const urlFormData = `${Config.WebAPI}/AccountNote/${accountNoteID}`;
  const urlFilesData = `${Config.WebAPI}/AccountNoteAttachment/getAttachmentByNoteId/${accountNoteID}`;
  return Fetch.GETDoubleFetch(urlFormData, urlFilesData)
};

const PostAccountNote = (data) => {
  return new Promise(function (resolve) {
    accountNoteSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountNoteSchema
          .validate(data, { abortEarly: false })
          .catch((result) => {
            Texts.ShowValidations('accountNote', result);
            console.log(result);
          });
        resolve(false);
      } else {
        Fetch.POSTFetch(`${Config.WebAPI}/AccountNote`, data)
          .then((rtn) => {
            console.log(rtn);
            resolve(rtn);
          })
          .then(Texts.Toast('new'));
      }
    });
  });
};

const PutAccountNote = async (id, data) => {
  return new Promise(function (resolve) {
    accountNoteSchema.isValid(data).then((valid) => {
      if (!valid) {
        accountNoteSchema
          .validate(data, { abortEarly: false })
          .catch((result) => {
            Texts.ShowValidations('accountNote', result);
            console.log(result);
          });
        resolve(false);
      } else {
        Fetch.PUTFetch(`${Config.WebAPI}/AccountNote/${id}`, data)
          .then((rtn) => {
            console.log(rtn);
            resolve(rtn);
          })
          .then(Texts.Toast('edit'));
      }
    });
  });
};

const DeleteAccountNote = async (id) => {
  return new Promise(function (resolve) {
    const url = `${Config.WebAPI}/AccountNote/${id}`;

    let init = { method: 'GET', headers: Fetch.HEADERS() };

    fetch(url, init)
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.return.canBeDeleted) {
          Texts.ShowConfirmation(
            'dialogs.confirmation.sureDelete',
            'dialogs.confirmation.sureDelete.extra'
          ).then((c) => {
            if (c) {
              Fetch.DELETEFetch(`${Config.WebAPI}/AccountNote/${id}`, id)
                .then((rtn) => {
                    console.log(rtn);
                    resolve(rtn);
                    Texts.Toast()
                })
            }
          });
        } else {
          Texts.ShowValidation('dialogs.information.cannotBeDeleted', '')
        }
      });
  });
};

const GetFilterNotes = async (query) => {
  return await GETFilterRows('/AccountNote', query)
};

const GetFilterNote = async (query, id) => {
  return await GETFilterRows(`/AccountNote/Account${id}`, query)
};

let accountNoteSchema = yup.object().shape({
  accountID: yup.number().min(1).required(),
  title: yup.string().nullable().max(1500).required(),
  description: yup.string().nullable().max(1500).required(),
  private: yup.boolean().nullable(),
  archived: yup.boolean().nullable(),
  noteAttachments: yup.array().nullable(),
});

export const accountNoteService = {
  GetAccountNote,
  GetAccountNotes,
  GetAccountNoteByAccount,
  GetAccountNoteAndAttachments,
  GetFilterNote,
  GetFilterNotes,
  PostAccountNote,
  PutAccountNote,
  DeleteAccountNote,
};
