import React, { useContext, useEffect, useState } from 'react';

// Librerias
import { useNavigate, useParams } from 'react-router-dom';

// Hooks
import { TextsContext } from '../../context/TextsContext';

import { CORESec } from '../../env/CORESec';

// Componentes
import SHHr from '../../components/SHHr/SHHr';
import SHTable from '../../components/SHTable/SHTable';
import SHColumns from '../../components/SHTable/SHColumns';
import SHContainer from '../../components/SHContainer/SHContainer';
import { environmentService } from '../../services/environmentService';
import SHLoading from '../../components/SHLoading/SHLoading';
import SHCollapse from '../../components/SHCollapse/SHCollapse';
import SHCounter from '../../components/SHCounter/SHCounter';
import { Config } from '../../env/Config';



function Search() {

    const dataText = useContext(TextsContext);
    let navigate = useNavigate()
    let {value: searchString} = useParams()

  
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
     
    useEffect(() => {
        if(!CORESec.validateSesion()) {
            CORESec.Logout()
            navigate(`/${Config.Version}/login`)
        }
        environmentService.GetSearchResult(searchString)      
        .then((rtn) => {
            setData(rtn);
          })
          .then(() => setLoading(false));
          
    }, [navigate,searchString])

    const handleRefresh = ()=>{
        environmentService.GetSearchResult(searchString)      
        .then((rtn) => {
            setData(rtn);
          })
          .then(() => setLoading(false));
    }


    let accountData = data.accounts;
    let eventData= data.accountEvents;
    let oppData = data.accountOpportunities;
    let productData = data.products;
    let contactData= data.contacts;

    const editRow = (row,entity) => {
        switch(entity){
            case 'event':
                navigate(`/${Config.Version}/account/get/${row.accountID}/${entity}/${row.accountEventID}`)
            break;
            case 'opportunity':
                navigate(`/${Config.Version}/account/get/${row.accountID}/${entity}/${row.accountOpportunityID}`)
            break;
            case 'account':
                navigate(`/${Config.Version}/account/get/${row.accountID}/${entity}`)
            break;
            case 'contact' : 
                navigate(`/${Config.Version}/account/get/${row.accountID}/${entity}/${row.accountContactID}`)
            break;
            default: 
            navigate(`/${Config.Version}/account/get/${row.accountID}/event`)
            break;
        }
        
       /*  navigate(`/${Config.Version}/account/get/${row.accountID}/${entity}/${row.accountEventID}`); */

       
    }

    // ------ Columnas CUENTAS ---------
    const accountColumns = SHColumns([
        { header: dataText.get('Account.AccountID'), key: 'accountID', id: 'accountID', width: 10,align: 'left', },
        { header: dataText.get('Account.AccountName'), key: 'name', width: 20 },
        {
          header: dataText.get('Account.SocialName'),
          key: 'socialName',
          width: 30,
        },
        {
          header: dataText.get('Account.TypeID'),
          key: 'typeString',
          align: 'center',
          width: 15,
        },
        {
          header: dataText.get('Account.IndustryID'),
          key: 'industryString',
          width: 30,
        },
        {
          header: dataText.get('Account.MarketID'),
          key: 'marketString',
          width: 30,
        },
        {
          header: dataText.get('Account.Commercial'),
          key: 'commercial',
          width: 30,
        },
        {
          header: dataText.get('Account.CustomerServices'),
          key: 'customerService',
          width: 30,
        },
        {
          header: dataText.get('Account.ClassificationID'),
          key: 'classificationString',
          align: 'center',
          width: 15,
        },
        {
          header: dataText.get('Account.PotentialID'),
          key: 'potentialString',
          align: 'center',
          width: 20,
        },
        {
          header: dataText.get('Account.StateID'),
          key: 'stateString',
          align: 'center',
          width: 20,
        },
        {
          header: dataText.get('Account.Config'),
          key: 'config',
          align: 'center',
          width: 20,
          boolType: true,
        },
        {
          header: dataText.get('Account.Action'),
          key: 'actions',
          align: 'center',
          width: 25,
          onRefresh: () => handleRefresh(),
        },
      ]);

    // ------ Columnas EVENTOS ---------
    const eventColumns = SHColumns([
        { key: 'expand', width: 5 },
        { header: dataText.get('AccountEvent.AccountEventID'), id: 'accountEventID', key: 'accountEventID', width: 10, },
        { header: dataText.get('AccountEvent.Title'), key: 'title', width: 25 },
        { header: dataText.get('AccountEvent.Account'), key: 'accountString', width: 25, }, //TODO Falta traer del BE
        { header: dataText.get('AccountEvent.Description'), key: 'description', width: 45 },
        { header: dataText.get('AccountEvent.TypeID'), key: 'typeString', align: 'center', width: 15 },
        { header: dataText.get('AccountEvent.StateID'), key: 'stateString', align: 'center', width: 15 },
        { header: dataText.get('AccountEvent.CreatedOnString'), key: 'createdOnString', align: 'center', width: 20 },
        { header: dataText.get('AccountEvent.DueDate'), key: 'dueDateString', align: 'center', width: 20 }, // TODO falta BE y pasar a string
        { header: dataText.get('AccountEvent.ResponsibleID'), key: 'responsibleString', width: 25 }, // TODO falta BE
        { header: dataText.get('AccountEvent.Follow'), key: 'follow', align: 'center', width: 6, boolType: true },
        { header: dataText.get('AccountEvent.Closed'), key: 'closed', align: 'center', width: 6, boolType: true },
        { header: dataText.get("AccountEvent.Actions"), key: 'actions', deleteOnly:true, align: 'center', width: 15, onRefresh: () => handleRefresh() },
    ])

    // ------ Columnas OPORTUNIDADES ---------
    const opportunityColumns = SHColumns([
        { header: dataText.get('AccountOpportunity.AccountOpportunityID'), id: 'accountOpportunityID', key: 'accountOpportunityID', width: 10 },
        { header: dataText.get('AccountOpportunity.Quotation'), key: 'name', width: 30 },
        { header: dataText.get('AccountOpportunity.Account'), key: 'accountString', width: 30 },
        { header: dataText.get('AccountOpportunity.Products'), key: 'product', width: 25 },
        { header: dataText.get('AccountOpportunity.CommercialID'), key: 'commercialString', align: 'center', width: 20 },
        { header: dataText.get('AccountOpportunity.Quotator'), key: 'quoteString', align: 'center', width: 13 },
        { header: dataText.get('AccountOpportunity.PotentialID'), key: 'potentialString', align: 'center', width: 13 },
        { header: dataText.get('AccountOpportunity.ImportanceID'), key: 'importanceString', align: 'center', width: 15 },
        { header: dataText.get('AccountOpportunity.DateOfCot'), key: 'quotationDateString', align: 'center', width: 18 },
        { header: dataText.get('AccountOpportunity.StateID'), key: 'stateString', align: 'center', width: 10 },
        { header: dataText.get('Account.Follow'), key: 'follow', align: 'center', width: 8, boolType: true },
        { header: dataText.get("AccountOpportunity.Actions"), key: 'actions', align: 'center', width: 15, deleteOnly: true, onRefresh: () => handleRefresh()  },
    ])

    // ------ Columnas PRODUCTOS ---------
    const productColumns = SHColumns([
        {
            header: dataText.get('Product.ProductID'),
            id: 'productID',
            key: 'productID',
            align: 'center',
            width: 10,
        },
        {
            header: dataText.get('Product.Code'),
            key: 'code',
            align: 'center',
            width: 30,
        },
        { header: dataText.get('Product.Name'), key: 'name', width: 210 },
        {
            header: dataText.get('Product.Description'),
            key: 'description',
            width: 40,
        },
        {
            header: dataText.get('Product.FamilyID'),
            key: 'familyString',
            width: 30,
        },
        {
            header: dataText.get('Product.MarketID'),
            key: 'marketString',
            width: 30,
        },
        {
            header: dataText.get('Product.VarietyID'),
            key: 'varietyString',
            align: 'center',
            width: 10,
        },
        {
            header: dataText.get('Product.Utility'),
            key: 'utility',
            align: 'center',
            width: 30,
        },
        {
            header: dataText.get('Product.Actions'),
            key: 'actions',
            align: 'center',
            width: 15,
            onRefresh: () => handleRefresh()
        },
    ]);
    

    // ------ Columnas CONTACTOS ---------
    const contactColumns = SHColumns([
        { header: dataText.get('AccountContact.AccountContactID'), key: 'accountContactID',  id: 'accountContactID', width: 10, align: 'left' },
        { header: dataText.get('AccountContact.Name'), key: 'name', align: 'left', width: 30 },
        { header: dataText.get('AccountContact.Surname'), key: 'surname', align: 'left', width: 30 },
        { header: dataText.get('AccountContact.Position'), key: 'position', align: 'center', width: 20 },
        { header: dataText.get('AccountContact.Addresses'), key: 'accountAddressString', align: 'left', width: 50 },
        { header: dataText.get('AccountContact.Private'), key: 'private', align: 'center', width: 10, boolType: true },
        { header: dataText.get('AccountContact.Emails'), key: 'workEmail', align: 'center', width: 30 },
        { header: dataText.get('AccountContact.Phones'), key: 'workPhone', align: 'center', width: 30 },
        { header: dataText.get('AccountContact.MessengerServices'), key: 'messenger', align: 'center', width: 40 },
        { header: dataText.get('AccountContact.Observations'), key: 'observations', align: 'left', width: 80 },
        { header: dataText.get('AccountContact.Actions'), key: 'actions', align: 'left', width:25, deleteOnly: true, onRefresh: () => handleRefresh() },
    ]);

    
    if(loading) return <SHLoading />
    return (
        <SHContainer>

            {/* ------------------------------------------------------------- */}
            {/* ------------------------- CUENTAS --------------------------- */}
            {/* ------------------------------------------------------------- */}
            <SHCollapse
                header={dataText.get('accountGets.administration')}
                openModal={accountData.length > 0 ? true : false}
                bsClassName="my-3"
                hsize="h5"
              
              >
                {/* <SHHeader entityTitle={dataText.get('accountGets.administration')} /> */}
                <SHCounter quantity={accountData.length > 0 ? accountData : []} />
                <SHHr />
                <SHTable
                    columns={accountColumns}
                    data={accountData || []}
                    entity="account"
                    btnDeleteFrom="accounts"
                    operationName="accountDelete"
                    onClick={editRow}
                    stickyHeader
                />
            </SHCollapse>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------- EVENTOS --------------------------- */}
            {/* ------------------------------------------------------------- */}
            <SHCollapse
                header={dataText.get("eventGets.administration")}
                openModal={eventData.length > 0 ? true : false}
                bsClassName="my-3"
                hsize="h5"
              >
                {/* <SHHeader entityTitle={dataText.get("eventGets.administration")} /> */}
                <SHCounter quantity={eventData.length > 0 ? eventData : []} />
                <SHHr />
                <SHTable
                    columns={eventColumns}
                    data={eventData || []}
                    entity='account'
                    btnDeleteFrom="events"
                    operationName="accountEventDelete"
                    onClick={(row)=>editRow(row,'event')}
                    stickyHeader
                />
            </SHCollapse>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------- OPORTUNIDADES ----------------------*/}
            {/* ------------------------------------------------------------- */}
            <SHCollapse
                header={dataText.get("opportunityGets.administration")}
                openModal={oppData.length > 0 ? true : false}
                bsClassName="my-3"
                hsize="h5"
              
            > 
               {/*  <SHHeader entityTitle={dataText.get("opportunityGets.administration")} /> */}
               <SHCounter quantity={oppData.length > 0 ? oppData : []} />
                <SHHr />
                <SHTable
                    columns={opportunityColumns}
                    data={oppData || []}
                    entity='account'
                    onClick={(row)=>editRow(row,'opportunity')}
                    btnDeleteFrom='opportunities'
                    operationName="accountOpportunityDelete"
                    stickyHeader
                />
            </SHCollapse>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------- PRODUCTOS ------------------------- */}
            {/* ------------------------------------------------------------- */}
            <SHCollapse
                header={dataText.get('productGets.administration')}
                openModal={productData.length > 0 ? true : false}
                bsClassName="my-3"
                hsize="h5"
            > 
                {/* <SHHeader entityTitle={dataText.get('productGets.administration')} /> */}
                <SHCounter quantity={productData.length > 0 ? productData: []} />
                <SHHr />
                <SHTable
                    columns={productColumns}
                    data={productData || []}
                    entity="product"
                    operationName="productDelete"
                    noRedirect
                    btnDeleteFrom="products"
                    stickyHeader
                />
            </SHCollapse>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------- CONTACTOS ------------------------- */}
            {/* ------------------------------------------------------------- */}
            <SHCollapse
                header={dataText.get("accountGetContact.contactAdministration")}
                openModal={contactData.length > 0 ? true : false}
                bsClassName="my-3"
                hsize="h5"
            > 
               {/*  <SHHeader entityTitle={dataText.get("accountGetContact.contactAdministration")} /> */}
                <SHCounter quantity={contactData.length > 0 ? contactData : []} />
                <SHHr />
                <SHTable
                    columns={contactColumns}
                    data={contactData || []}
                    entity='account'
                    stickyHeader
                    onClick={(row)=>editRow(row,'contact') }
                    operationName="accountContactDelete"
                    btnDeleteFrom='accountContact'
                />
             </SHCollapse>
        </SHContainer>
    );
};

export default Search;