import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

// Context
import { TextsContext } from '../../../context/TextsContext';

// Componentes propios
import SHButton from '../../../components/SHButton/SHButton';
import SHColumns from '../../../components/SHTable/SHColumns';
import SHLoading from '../../../components/SHLoading/SHLoading';
import SHTable from '../../../components/SHTable/SHTable';
import SHContainer from '../../../components/SHContainer/SHContainer';
import SHHr from '../../../components/SHHr/SHHr';
import SHTabs from '../../../components/SHTabs/SHTabs';
import SHTabPane from '../../../components/SHTabPane/SHTabPane';
import SHLabel from '../../../components/SHLabel/SHLabel';
import SHCollapse from '../../../components/SHCollapse/SHCollapse';

import { environmentService } from '../../../services/environmentService';
import { businessUnitAccountService } from '../../../services/businessUnitAccountService';

import { CORESec } from '../../../env/CORESec';

// Estilos
import './AccountGetBusinessUnits.css';

import AccountGetBusinessUnitsForm from './ENSOLFOOD/AccountGetBusinessUnitsForm';
import AccountGetBusinessUnitsConfigForm from './ENSOLFOOD/AccountGetBusinessUnitsConfigForm';
import AccountGetBusinessUnitOtherProductForm from './ENSOLFOOD/AccountGetBusinessUnitOtherProductForm';
import { Config } from '../../../env/Config';

export default function AccountGetBusinessUnits({
  rowAccountID,
  rowData,
  onRefresh,
}) {
  const dataText = useContext(TextsContext);

  let navigate = useNavigate();

  const [dataProducts, setDataProducts] = useState([]);
  const [dataOthersProducts, setDataOthersProducts] = useState([]);

  const [mode, setMode] = useState('');
  const [data, setData] = useState(null);

  const [dataOptions] = useState(environmentService.Properties);
  const [dataTableOptions] = useState(environmentService.TableValues);
  const [loadingTables, setLoadingTables] = useState(false);

  const [accountProductMode, setAccountProductMode] = useState('');
  const [accountOtherProductMode, setAccountOtherProductMode] = useState('');

  const tabs = [{ id: 'ensolFood', name: 'ENSOLFOOD', active: true }];

  /* MULTI FETCH */
  const FetchData = async () => {
    setLoadingTables(true);

    businessUnitAccountService.GetProductsAndOtherProducts(rowAccountID)
      .then((values) => {
        if(values[0].return) {
          setDataProducts(values[0].return);
        }
        if(values[1].return) {
          setDataOthersProducts(values[1].return);
        }
      })
      .finally(setLoadingTables(false))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => { // Se ejecuta solo la primera vez. No usamos FetchData() porque sino da warning.
    
    let abortController = new AbortController()

    const FirstFetchData = async () => {
      setLoadingTables(true);
        businessUnitAccountService.GetProductsAndOtherProducts(rowAccountID)
        .then((values) => {
          setDataProducts(values[0].return);
          setDataOthersProducts(values[1].return);
          abortController = null
        })
        .finally(setLoadingTables(false))
        .catch((err) => {
          console.log(err);
        });
    };

    FirstFetchData();

    return () => {
      abortController?.abort()
    }
  }, [rowAccountID]);
  
  const handleRefreshProducts = () => {
    businessUnitAccountService.GetProducts(rowAccountID)
    .then(res => {
      setDataProducts(res.return)
    })
  };
  const handleRefreshOtherProducts = () => {
    businessUnitAccountService.GetOtherProducts(rowAccountID)
    .then(res => {
      setDataOthersProducts(res.return)
    })
  };

  const goBack = () => {
    setMode('');
  };
  const goBackFromOtherProduct = () => {
    setAccountOtherProductMode('');
  };
  const goBackFromProduct = () => {
    setAccountProductMode('');
  };
  const config = () => setMode('config');

  const createAccountProductRow = () => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    }
    setAccountProductMode('new');
  };
  const createAccountOtherProductRow = () => {
    if (!CORESec.validateSesion()) {
      CORESec.Logout();
      navigate(`/${Config.Version}/login`);
    }
    setAccountOtherProductMode('new');
  };

  const editProduct = (row) => {
    setAccountProductMode('edit');
    setData(row);
  };
  const editOtherProduct = (row) => {
    setAccountOtherProductMode('edit');
    setData(row);
  };

  const columns = SHColumns([
    {
      header: dataText.get('AccountBusinessUnit.AccountProductID'),
      id: 'accountProductID',
      key: 'accountProductID',
      align: 'left',
      width: 10,
    },
    {
      header: dataText.get('AccountProduct.ProductID'),
      key: 'productString',
      align: 'left',
      width: 200,
    },
    {
      header: dataText.get('accountGetBusinessUnit.measurementUnit'),
      key: 'measurementUnitString',
      align: 'center',
      width: 20,
    },
    {
      header: dataText.get('accountGetBusinessUnit.quantity'),
      key: 'quantityByUser',
      align: 'center',
      width: 20,
    },
    {
      header: dataText.get('AccountOpportunity.Observations'),
      key: 'description',
      align: 'left',
      width: 200,
    },
    {
      header: dataText.get('AccountBusinessUnit.Actions'),
      key: 'actions',
      align: 'center',
      width: 15,
      deleteOnly: true,
      onRefresh: () => FetchData(),
    },
  ]);

  const columnsOtherProducts = SHColumns([
    {
      header: dataText.get('AccountBusinessUnit.AccountProductID'),
      key: 'accountOtherProductID',
      align: 'left',
      width: 10,
    },
    {
      header: dataText.get('AccountProduct.ProductID'),
      key: 'name',
      align: 'left',
      width: 200,
    },
    {
      header: dataText.get('accountGetBusinessUnit.measurementUnit'),
      key: 'measurementUnitString',
      align: 'center',
      width: 20,
    },
    {
      header: dataText.get('accountGetBusinessUnit.quantity'),
      key: 'quantityByUser',
      align: 'center',
      width: 20,
    },
    {
      header: dataText.get('AccountOpportunity.Observations'),
      key: 'description',
      align: 'left',
      width: 200,
    },
    {
      header: dataText.get('AccountBusinessUnit.Actions'),
      key: 'actions',
      align: 'center',
      width: 15,
      deleteOnly: true,
      onRefresh: () => FetchData(),
    },
  ]);

  if (loadingTables) return <SHLoading />;
  return (
    <SHContainer>
      <SHTabs arrayIds={tabs} noHr>
        <SHTabPane id={tabs[0].id} active={true}>
          <SHContainer flex align="center">
            {mode === '' && (
              <>
                {Array.isArray(rowData.accountProducts) && rowData.config ? (
                  <SHLabel color="gray">
                    {dataText.get('AccountBusinessUnit.Config')}
                  </SHLabel>
                ) : (
                  <SHLabel color="red">
                    {dataText.get('AccountBusinessUnit.NotConfig')}
                  </SHLabel>
                )}
              </>
            )}
            <SHContainer flex align="center" justify="end" gap="5px">
              {mode === '' && (
                <SHButton
                  action="config"
                  className="mb-1"
                  text={dataText.get('button.config')}
                  classType="both"
                  onClick={() => config()}
                  disabled={
                    loadingTables ||
                    accountProductMode !== '' ||
                    accountOtherProductMode !== '' ||
                    mode !== ''
                  }
                />
              )}
            </SHContainer>
          </SHContainer>
          <SHContainer flex justifyContent="space-between" sizeW="fit-content">
            {rowData.usersCommercial.length !== 0 && (
              <SHLabel
                style={{
                  display: 'flex',
                  width: 'fit-content',
                  justifyContent: 'space-between',
                }}
              >
                <SHLabel
                  margin="0 5px 0 0"
                  fnWeight="bolder"
                  style={{ width: 'fit-content' }}
                >
                  {dataText.get('Account.Commercial') + ':'}
                </SHLabel>
                {rowData.usersCommercial.map((user) => (
                  <SHLabel
                    margin="0 5px 0 0"
                    key={user.id}
                    style={{ width: 'fit-content' }}
                  >
                    {`${user.name} ${user.surname}`}
                  </SHLabel>
                ))}
              </SHLabel>
            )}
            {rowData.usersCustomerService.length !== 0 && (
              <SHLabel
                style={{
                  display: 'flex',
                  width: 'fit-content',
                  justifyContent: 'space-between',
                }}
              >
                <SHLabel
                  margin="0 5px 0 0"
                  fnWeight="bolder"
                  style={{ width: 'fit-content' }}
                >
                  {dataText.get('Account.CustomerServices') + ':'}
                </SHLabel>
                {rowData.usersCustomerService.map((user) => (
                  <SHLabel
                    margin="0 5px 0 0"
                    key={user.id}
                    style={{ width: 'fit-content' }}
                  >
                    {`${user.name} ${user.surname}`}
                  </SHLabel>
                ))}
              </SHLabel>
            )}
            {rowData.usersFunctional.length !== 0 && (
              <SHLabel
                style={{
                  display: 'flex',
                  width: 'fit-content',
                  justifyContent: 'space-between',
                }}
              >
                <SHLabel
                  margin="0 5px 0 0"
                  fnWeight="bolder"
                  style={{ width: 'fit-content' }}
                >
                  {dataText.get('Account.Functional') + ':'}
                </SHLabel>
                {rowData.usersFunctional.map((user) => (
                  <SHLabel key={user.id} style={{ width: 'fit-content' }}>
                    {`${user.name} ${user.surname}`}
                  </SHLabel>
                ))}
              </SHLabel>
            )}
          </SHContainer>
          {mode === 'config' && (
            <AccountGetBusinessUnitsConfigForm
              rowData={rowData}
              back={goBack}
              dataOptions={dataOptions}
              dataTableOptions={dataTableOptions}
              clickedTableRow={data}
              onRefresh={onRefresh}
            />
          )}
          <SHContainer margin="5px 0 20px 0">
            {rowData.config && (
              <SHCollapse
                header={dataText.get('AccountOpportunity.Products')}
                openModal={true}
                hsize="h5"
                bsClassName={`my-2 ${
                  (accountOtherProductMode !== '' || mode === 'config') &&
                  'set-blur'
                } ${accountProductMode !== '' && 'set-active'}`}
              >
                {accountProductMode === '' && (
                  <SHTable
                    columns={columns}
                    data={dataProducts || []}
                    entity={`account`}
                    operationName="accountBussinessUnitProductDelete"
                    onClick={editProduct}
                    btnDeleteFrom="accountProductsBU"
                    noRedirect
                  />
                )}
                {(accountProductMode === 'new' ||
                  accountProductMode === 'edit') && (
                  <AccountGetBusinessUnitsForm
                    rowData={data}
                    mode={accountProductMode}
                    back={goBackFromProduct}
                    dataOptions={dataOptions}
                    dataTableOptions={dataTableOptions}
                    rowAccountID={rowAccountID}
                    refreshProducts={handleRefreshProducts}
                  />
                )}
                <SHHr />
                {accountProductMode === '' && (
                  <SHContainer flex align="center" justify="end">
                    <SHButton
                      action="add"
                      text={dataText.get('accountGetBusinessUnit.addProduct')}
                      classType="both"
                      onClick={() => createAccountProductRow()}
                      disabled={
                        !rowData.config ||
                        loadingTables ||
                        accountProductMode !== '' ||
                        accountOtherProductMode !== '' ||
                        mode !== ''
                      }
                    />
                  </SHContainer>
                )}
              </SHCollapse>
            )}
          </SHContainer>

          <SHContainer>
            {rowData.config && (
              <SHCollapse
                header={dataText.get('AccountBusinessUnit.OthersProducts')}
                hsize="h5"
                openModal={true}
                bsClassName={`my-2 ${
                  (accountProductMode !== '' || mode === 'config') && 'set-blur'
                } ${accountOtherProductMode !== '' && 'set-active'}`}
              >
                {accountOtherProductMode === '' && (
                  <SHTable
                    columns={columnsOtherProducts}
                    data={dataOthersProducts || []}
                    entity={`account`}
                    operationName="accountBussinessUnitOtherProductDelete"
                    onClick={editOtherProduct}
                    btnDeleteFrom="accountOthersProductsBU"
                    noRedirect
                  />
                )}
                {(accountOtherProductMode === 'new' ||
                  accountOtherProductMode === 'edit') && (
                  <AccountGetBusinessUnitOtherProductForm
                    rowData={data}
                    loadingData={loadingTables}
                    mode={accountOtherProductMode}
                    back={goBackFromOtherProduct}
                    dataOptions={dataOptions}
                    dataTableOptions={dataTableOptions}
                    rowAccountID={rowAccountID}
                    refreshProducts={handleRefreshOtherProducts}
                  />
                )}
                <SHHr />
                {accountOtherProductMode === '' && (
                  <SHContainer flex align="center" justify="end">
                    <SHButton
                      action="add"
                      text="Agregar Otro Producto"
                      classType="both"
                      onClick={() => createAccountOtherProductRow()}
                      disabled={
                        !rowData.config ||
                        loadingTables ||
                        accountProductMode !== '' ||
                        accountOtherProductMode !== '' ||
                        mode !== ''
                      }
                    />
                  </SHContainer>
                )}
              </SHCollapse>
            )}
          </SHContainer>
        </SHTabPane>
      </SHTabs>
    </SHContainer>
  );
}
