import React, {useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { TextsContext } from '../../../../context/TextsContext';
import { Config } from '../../../../env/Config';

// Componentes
import SHIcon from '../../../SHIcon/SHIcon';

// Estilos
import './searchBar.css'

function SearchBar(props) {

    const dataText = useContext(TextsContext)

    let navigate = useNavigate()
    const [data, setData] = useState(undefined);
    const handleChange = (e) => {
        setData(e.target.value)
    }

  
    /* Llamada al */
    const handleSubmit = (e) => 
    {
        e.preventDefault();
        if(!/^\s*$/.test(data)){
            navigate(`${Config.Version}/search/${data}`)
        }
        else{
            return ''
        }
    }

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-nowrap mb-2 mb-lg-0 me-2 input-group">
            <input className="form-control form-control-sm searchBar" type="search" name='name' value={data === undefined ? '' : data} onChange={handleChange} placeholder={dataText.get('user.search')} aria-label="Search" />
            <button className="btn btn-sm text-light btn btn-sm nav-Icons" disabled={data === undefined || data === '' ? true : false} type="submit">
                <SHIcon type='search' size='18' />
            </button>
        </form>
    )
}

export default React.memo(SearchBar)