import React from 'react';

// Componentes
import SHHr from '../SHHr/SHHr';

// Estilos
import './SHTabs.css';

function SHTabs({ children, arrayIds, onClick, noHr }) {
  return (
    <div>
      {!noHr && <SHHr />}
      <ul className="nav nav-pills" id="pills-tab" role="tablist">
        {arrayIds.map((elem) => {
          return (
            <li
              style={{ pointerEvents: elem.active ? 'none' : null }}
              className="nav-item"
              role="presentation"
              key={elem.name}
            >
              <button
                className={`nav-link tabs-custom ${elem.active && 'active'}`}
                id={`pills-${elem.id}`}
                data-bs-toggle="pill"
                data-bs-target={`#${elem.id}`}
                type="button"
                role="tab"
                aria-controls={elem.name}
                aria-selected={elem.active}
                onClick={() => onClick(elem.id)}
              >
                {elem.name}
              </button>
            </li>
          );
        })}
      </ul>
      <SHHr />
      <div className="tab-content mx-0 my-1" id="pills-tabContent">
        {children}
      </div>
    </div>
  );
}

export default SHTabs;
