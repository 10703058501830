import * as yup from 'yup';
import GETFilterRows from '../assets/utils/GETFilterRows';
import validationResponse from '../assets/utils/validationResponse';
import { Config } from '../env/Config';
import { Fetch } from '../env/Fetch';
import { Texts } from '../env/Texts';

const GetProducts = (userID = null) => { 
    if(userID){
        return Fetch.GETFetch(`${Config.WebAPI}/Product?user=${userID}`);
    } else {
        return Fetch.GETFetch(`${Config.WebAPI}/Product`) 
    }
}
const GetProduct = (id) => { return Fetch.GETFetch(`${Config.WebAPI}/Product/${id}`) }

const PostProduct = async (data) => {

    return new Promise(function (resolve) {
        productSchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    productSchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('product', result)
                            console.log(result);
                        })
                    resolve(false)
                }
                else {
                    Fetch.POSTFetch(`${Config.WebAPI}/Product`, data)
                        .then(rtn => { 
                            switch (rtn.innerCode) {
                                case 610:
                                    console.log('ENTRA');
                                    Texts.ShowValidations('product', validationResponse('duplicated', rtn.innerMessage));
                                    break;

                                default:
                                    console.log(rtn.return); 
                                    Texts.Toast('new')
                                    return resolve(rtn);
                            }
                        })
                        .then()
                }
            })
    })
}

const PutProduct = async (id, data) => {

    return new Promise(function (resolve) {
        productSchema.isValid(data)
            .then(valid => {
                if (!valid) {
                    productSchema.validate(data, { abortEarly: false })
                        .catch(result => {
                            Texts.ShowValidations('product', result)
                            console.log(result);
                        })
                    resolve(false)
                }
                else {
                    Fetch.PUTFetch(`${Config.WebAPI}/Product/${id}`, data)
                        .then((rtn) => {
                            switch (rtn.innerCode) {
                            case 610:
                                Texts.ShowValidations('product', validationResponse('duplicated', rtn.innerMessage));
                                break;
                            
                            default:
                                return resolve(rtn);
                            }
                        })
                }
            })
    })
}

const GetFilterProduct = async (query) => {
    return await GETFilterRows(`/Product`, query)
}

const DeleteProduct = async (id) => {
    return new Promise(function (resolve) {
        const url = `${Config.WebAPI}/Product/${id}`

        let init = { method: 'GET', headers: Fetch.HEADERS() }

        fetch(url, init)
            .then(response => response.json())
            .then(fetchedData => {
                if (fetchedData.return.canBeDeleted) {
                    Texts.ShowConfirmation('dialogs.confirmation.sureDelete', 'dialogs.confirmation.sureDelete.extra')
                        .then(c => {
                            if (c) {
                                Fetch.DELETEFetch(`${Config.WebAPI}/Product/${id}`, id)
                                    .then(rtn => { console.log(rtn); resolve(rtn) })
                                    .then(Texts.Toast())
                            }
                        })
                }
                else {
                    Texts.ShowValidation('dialogs.information.cannotBeDeleted', '')
                }
            })
    })
}

let productSchema = yup.object().shape({
    code: yup.string().nullable().max(50).required(),
    name: yup.string().max(200).required(),
    familyID: yup.number().min(1).required(),
    varietyID: yup.number().min(1).required(),
    utility: yup.string().nullable(),
    markets: yup.array().nullable(),
    description: yup.string().nullable(),
    vendorID: yup.number().min(1),
    countryID: yup.number().min(1).required(),
});

export const productService = {
    GetProducts,
    GetProduct,
    GetFilterProduct,
    PostProduct,
    PutProduct,
    DeleteProduct,
}