import React, { useContext } from 'react';

// Context
import { TextsContext } from '../../context/TextsContext';

import {Link} from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {

    const dataText = useContext(TextsContext)

    return (
        <div className='div'>
            <h1 className='heading'>{dataText.get('notFound.goHome')}</h1>
            <Link to="/" className='link'>{dataText.get('notFound.notFound')}</Link>
        </div>
    );
};

export default NotFound;