import React, { useState } from 'react';

// Componentes
import SHIcon from '../SHIcon/SHIcon';
import SHLabel from '../SHLabel/SHLabel';

// Estilos
import './SHInput.css';

function SHInput({
    iconType,
    size,
    label,
    iconColor,
    confirmPassword,
    hasPassword,
    disabled,
    placeholder,
    value,
    autoFocus,
    type,
    name,
    rows,
    id,
    step,
    onChange,
    min
}) {
    // const [thisValue, setThisValue] = useState(value)

    // const localOnChange = (e) => {
    //     setThisValue(e.target.value)
    // }

    // Si el input es tipo password se usarán los siguientes
    // states para mostrar/ocultar la contraseña

    const [showPass, setShowPass] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    // Función para mostrar/ocultar el password
    // Si hay confirmación de contraseña ingresaremos la props.confirmPassword en true

    const togglePass = (confirm) => {
        // solo si ingresamos la props.confirmPassword={true}
        // al clicker el span con el ícono mostrará/ocultará
        // la contraseña a confirmar
        if (confirm === true) { setShowConfirm(!showConfirm) }
        // sino pasamos la props.confirmPassword
        // cambiará el state de una contraseña simple
        else { setShowPass(!showPass) }
    }

    return (
        <>
            {iconType ?
                //Si pasamos la props.iconType se mostrará un ícono previo al input
                <div className={size}>
                    {/* Solo si incluye props.label se mostrará a continuación */}
                    {label ? <SHLabel id={id} >{label}</SHLabel> : null}
                    <div className="input-group input-group-sm">
                        {/* span previo al input */}
                        <span className="input-group-text bg-light">
                            <SHIcon type={iconType} color={iconColor ? iconColor : 'gray'} />
                        </span>
                        {/* Input principal. Si es tipo password se deberá tener en cuenta si incluye confirmación o no. */}
                        <input
                            // De ser tipo password, el type del input podrá variar entre "text" o "password" para mostrar/ocultar la contraseña
                            type={hasPassword === true ? (confirmPassword ? (showConfirm ? "text" : "password") : (showPass ? "text" : "password")) : (type)}
                            name={name} onChange={onChange}  value={value || ''} disabled={disabled}
                            className="form-control" placeholder={placeholder} aria-label={label} autoComplete='off' 
                        />

                        {/* Solo si ingresamos props.hasPassword en true se verá el siguiente span para mostrar/ocultar contraseña */}
                        {hasPassword ?
                            // al hacer click en el span, invoca a la función para cambiar mostrar/ocultar password que a su vez cambia ícono y color a mostrar
                            // si le pasamos props.confirmPassword en true cambiará el state de confirmación de contraseña
                            // si no le pasamos props.confirmPassword cambiará la del state de password simple
                            <span className="input-group-text bg-light hasPassword" onClick={() => togglePass(confirmPassword ? confirmPassword : false)}>
                                <SHIcon
                                    // el type y color del ícono a mostrar variarán según el state, que cambia cuando el span es seleccionado
                                    // el state también varía si pasamos la props.confirmPassword
                                    // si lo pasamos en true cambiará el state de showConfirm (input password confirmación)
                                    //si no pasamos la props.confirmPassword cambiará el state showPass (input password simple)
                                    
                                    type={confirmPassword ? (showConfirm ? 'eyeFill' : 'eyeSlashFill') : (showPass ? 'eyeFill' : 'eyeSlashFill')}
                                    color={confirmPassword ? (showConfirm ? '#4e4e4e' : 'gray') : (showPass ? '#4e4e4e' : 'gray')} />
                            </span> : null
                        }
                    </div>
                </div>
                :
                <>
                    {/* Si queremos mostrar un input del tipo "url" */}
                    {type === 'url' ?
                        <div className={size}>
                            {label ? <SHLabel id={id}>{label}</SHLabel> : null}
                            <div className="input-group input-group-sm">
                                {/* Deberemos pasar el tipo de input ("url) */}
                                <span className="input-group-text"><SHIcon type="web"></SHIcon></span>
                                <input type={type} onChange={onChange} value={value || ''} className="form-control"
                                    name={name} placeholder={placeholder} aria-label={label}
                                    autoFocus={autoFocus} disabled={disabled} autoComplete='off' />
                            </div>
                        </div>
                        :
                        // Si queremos mostrar un input del tipo "textarea"    
                        type === 'textarea' ?
                            <div className={size}>
                                {label ? <SHLabel id={id}>{label}</SHLabel> : null}
                                <div className="input-group input-group-sm">
                                    <textarea type={type} style={{ minHeight: '30px' }} onChange={onChange} value={value || ''} className="form-control"
                                        name={name} placeholder={placeholder} aria-label={label} rows={rows}
                                        autoFocus={autoFocus} disabled={disabled} autoComplete='off' />
                                </div>
                            </div>
                            :
                            // Si queremos mostrar un input del tipo "teléfono"    
                            type === 'phone' ?
                                <div className={size}>
                                    {label ? <SHLabel id={id}>{label}</SHLabel> : null}
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-text"><SHIcon type="phone"></SHIcon></span>
                                        <input type={type} onChange={onChange}  value={value || ''} className="form-control"
                                            name={name} placeholder={placeholder} aria-label={label} rows={rows}
                                            autoFocus={autoFocus} disabled={disabled} autoComplete='off' />
                                    </div>
                                </div>
                                :
                                // Si queremos mostrar un input del tipo "mail"    
                                type === 'mail' ?
                                    <div className={size}>
                                        {label ? <SHLabel id={id}>{label}</SHLabel> : null}
                                        <div className="input-group input-group-sm">
                                            <span className="input-group-text"><SHIcon type="mail"></SHIcon></span>
                                            <input 
                                                type={type} onChange={onChange} value={value || ''} className="form-control"
                                                name={name} placeholder={placeholder} aria-label={label} rows={rows}
                                                autoFocus={autoFocus} disabled={disabled} autoComplete='off' />
                                        </div>
                                    </div>
                                    :
                                    // Si queremos mostrar un input común
                                    <div className={size}>
                                        {/* Si queremos mostrar un label sobre el input, debemos pasar la props.label sino no se mostrará */}
                                        {label ? <SHLabel id={id}>{label}</SHLabel> : null}
                                        <div className="input-group input-group-sm">
                                            {/* Deberemos pasar el tipo de input ("text", "date", "password", etc.) */}
                                            <input type={type} style={{textAlign: type === 'number' ? 'right' : 'left'}} step={step} onChange={onChange} value={value || ''} className="form-control"
                                                name={name} min={min} placeholder={placeholder} aria-label={label}
                                                autoFocus={autoFocus} disabled={disabled} autoComplete='off' />
                                        </div>
                                    </div>

                    }
                </>
            }
        </>
    )
}

export default SHInput;