import React from 'react';
import { Navigate } from 'react-router-dom';
import { Config } from '../../env/Config';
import { CORESec } from '../../env/CORESec';
//import SHErrorHandler from '../SHErrorHandler/SHErrorHandler';

function SHPrivateRoute({ children }) {
    //<SHErrorHandler>
    return CORESec.GetSession() ? children : <Navigate to={`/${Config.Version}/login`} />;
    //</SHErrorHandler>
}

export default React.memo(SHPrivateRoute)