import { Config } from "./Config";
import { Fetch } from "./Fetch";

import { environmentService } from "../services/environmentService";

import SecureLS from "secure-ls";

const ValidateUser = async (email, password) => { 

	let url = `${Config.WebAPI}/Auth`

	const init = {
        method: 'POST',
        headers: Fetch.HEADERS(),
        body: JSON.stringify({ Username: email, Password: password })
    };

	return new Promise(function (resolve){
		fetch(url, init)
		.then(response => response.json())
		.then(fetchedData => {

			Fetch.DEBUGFetch(url, fetchedData)
			if (fetchedData === 'Unauthorized') {
				if (!Config.NoLogin) {
					resolve(false)
					throw new Error(`SERVER RETURN: ${fetchedData}`)
				}
			}
			else if (fetchedData.exception) {
				if (!Config.NoLogin) {
					resolve(false)
					//throw new Error(`SERVER RETURN: ${fetchedData.exception}`)
					console.log(`SERVER RETURN: ${fetchedData.exception}`)
				}
			}

			if (Config.NoLogin) {
				console.log('Login OK! - NO LOGIN = TRUE')

				Config.WriteLS('session', 'vQptiv0I5sMT6jwS19zIpqQ8835zG2SaIy0q9NioCf7CJHQK5mT7hO6zLNrMkI9o25QIL6BsuJhCw0cEmpHVZA==')
				Config.WriteLS('countries', [{ code: "ARG", id: 1 }, { code: "BRA", id: 2 }])
				Config.WriteLS('country', { code: "BRA", id: 2 })
			}
			else {
				console.log('Login OK!')

				Config.WriteLS('session', fetchedData.return)

				const init = { method: 'GET', headers: Fetch.HEADERS(fetchedData.return) }
				return fetch(url, init)
			}
		})
		.then(response => response ? response.json() : '')
		.then(fetchedData => {
			if (fetchedData !== '') {

				Fetch.DEBUGFetch(url, fetchedData)
				Config.WriteLS('current', fetchedData.return)

				if (fetchedData.return.countryAccessLevels) {

					if (fetchedData.return.countryAccessLevels.length === 2) {
						Config.WriteLS('countries', [{ code: "ARG", id: 1 }, { code: "BRA", id: 2 }])
						Config.WriteLS('country', { code: "BRA", id: 2 })
					}
					else if (fetchedData.return.countryAccessLevels.length === 1) {
						if (fetchedData.return.countryAccessLevels[0] === "ARG") {
							Config.WriteLS('countries', [{ code: "ARG", id: 1 }])
							Config.WriteLS('country', { code: "ARG", id: 1 })
						}
						else if (fetchedData.return.countryAccessLevels[0] === "BRA") {
							Config.WriteLS('countries', [{ code: "BRA", id: 2 }])
							Config.WriteLS('country', { code: "BRA", id: 2 })
						}
						else {
							console.log(`Error on AccessLevels: ${fetchedData.return.countryAccessLevels}`);
						}
					}
					else {
						Config.WriteLS('countries', [{ code: "ARG", id: 1 }, { code: "BRA", id: 2 }])
						Config.WriteLS('country', { code: "BRA", id: 2 })
					}
				}
			}
			else {
				Config.WriteLS('current', {
					"userID": 1240,
					"username": "NO USER!",
					"firstName": "NO USER!",
					"lastName": "NO USER!",
					"roles": [
						{
							"roleID": 2097,
							"code": "CRME_Direccion",
							"name": "CRME_Direccion",
							"operations": [],
							"systemID": 44
						}
					],
					"countryAccessLevels": [],
					"mail": "ccarmona_pre@carmocal.com",
					"isRoot": false
				})
			}

			console.log(`CountryAccessLevel: ${GetCountryAccessLevels().code}`)

			const init = { method: 'GET', headers: Fetch.HEADERS() }
			url = `${Config.WebAPI}/Auth/Operation`
			
			return fetch(url, init)
		})
		.then(response => response.json())
		.then(fetchedData => {

			Fetch.DEBUGFetch(url, fetchedData)
			Config.WriteLS('ops', fetchedData.return)
			
			const init = { method: 'GET', headers: Fetch.HEADERS() }
			url = `${Config.WebAPI}/Environment/PropertyValue`
			
			return fetch(url, init)
		})
		.then(response => response.json())
		.then(fetchedData => {

			Fetch.DEBUGFetch(url, fetchedData)
			Config.WriteLS('props', fetchedData.return)
			
			const init = { method: 'GET', headers: Fetch.HEADERS() }
			url = `${Config.WebAPI}/Environment/TableValue`
			
			return fetch(url, init)
		})
		.then(response => response.json())
		.then(fetchedData => {

			Fetch.DEBUGFetch(url, fetchedData)
			Config.WriteLS('tables', fetchedData.return)
			
			const init = { method: 'GET', headers: Fetch.HEADERS() }
			url = `${Config.WebAPI}/Environment/Text`
			
			return fetch(url, init)
		})
		.then(response => response.json())
		.then(fetchedData => {

			Fetch.DEBUGFetch(url, fetchedData)
			Config.WriteLS('texts', fetchedData.return)

			resolve(true)
		})
		.catch(err => {console.log(err)})
	})
}

const GetSession = () => { return Config.ReadLS("session") }

const GetSessionStatus = () => { 
	return (
		Config.ReadLS("session") && Config.ReadLS("session") !== '' &&
		Config.ReadLS('current') && Config.ReadLS("current") !== {} &&
		Config.ReadLS('countries') && Config.ReadLS("countries") !== [] &&
		Config.ReadLS('country') && Config.ReadLS("country") !== [] &&
		Config.ReadLS('ops') && Config.ReadLS("ops") !== [] &&
		Config.ReadLS('props') && Config.ReadLS("props") !== [] &&
		Config.ReadLS('tables') && Config.ReadLS("tables") !== []
)}

const GetCurrentUser = () => { return Config.ReadLS("current") }

const GetCountryAccessLevels = () => {

	const country = Config.ReadLS("country")
	const countries = Config.ReadLS("countries")

	if (country)
		return { code: country.code, id: country.id, countries}
	else
		return { code: '', id: 0 }
}

const Logout = () => { 
	Config.RemoveLS('session')
	Config.RemoveLS('current')
	Config.RemoveLS('countries')
	Config.RemoveLS('country')
	Config.RemoveLS('ops')
	Config.RemoveLS('props')
	Config.RemoveLS('tables')
	
	console.log('Logout OK!')
	environmentService.Dummy()
}

const Operation = (code) => {
	if (Config.WIP) return true
	
	if (!GetSessionStatus()) return false
	
	const ops = Config.ReadLS('ops')
	let rtn = false

	ops.forEach(o => {
        if (o.code.toLowerCase() === code.toLowerCase()){
            rtn = true;
        }
	})
	if (rtn) {
		rtn = false
		const user = Config.ReadLS('current')
		user.roles.forEach(r => {
			r.operations.forEach(o => {
				if (o.code.toLowerCase() === code.toLowerCase()) {
						rtn = true
					}
				})
		})
		return rtn
	}
	return rtn
}

// Expiración de sesión

function validateSesion() {
	let ls = new SecureLS({
		isCompression: true,
	})
	var date = new Date();
	var lastActivity= new Date(ls.get("LastActivity"));
	// console.log('lastActivity => ', lastActivity)
	lastActivity.setSeconds(lastActivity.getSeconds() + Config.SessionTime)
	// console.log('lastActivity + sessionTime', lastActivity)
	if(date > lastActivity || ls.get("LastActivity")==="")
	{
		return false;
	}
	else
	{
		ls.set("LastActivity",date)
		return true;
	}
	
	

}

export const CORESec = {
	GetCountryAccessLevels,
	GetCurrentUser,
	GetSession,
	GetSessionStatus,
	Operation,
	Logout,
	ValidateUser,
	validateSesion,
};