import { Config } from "../../../../../env/Config";


export const entries = [
    { code: 'account', to: `/${Config.Version}/account`, text: 'menu.accounts', visible: true, operation: 'accountGets', entries: [] },
    { code: 'event', to: `/${Config.Version}/event`, text: 'menu.events', visible: true, operation: 'accountEventGets', entries: [] },
    { code: 'opportunity', to: `/${Config.Version}/opportunity`, text: 'menu.opportunities', operation: 'accountOpportunityGets', visible: true, entries: [] },
    { code: 'products', to: `/${Config.Version}/products`, text: 'menu.products', operation: 'productGets', visible: true, entries: [] },
    { code: 'contact', to: `/${Config.Version}/contact`, text: 'menu.contacts', operation: 'accountContactGets', visible: true, entries: [] },
    { code: 'reports', to: `/${Config.Version}/reports`, text: 'menu.reports', visible: true, entries: [
        { code: 'SummaryAccounts', to: `/SummaryAccounts`, text: 'menu.summaryAccounts', visible: true, hasHr: false, entries: [] },
        { code: 'AccountsAnalysis', to: `/AccountsAnalysis`, text: 'menu.accountsAnalysis', visible: true, hasHr: false, entries: [] },
        { code: 'AccountsReports', to: `/AccountsReports`, text: 'menu.accountsReports', visible: true, hasHr: false, entries: [] },
        { code: 'QuotedProductsReports', to: `/QuotedProductsReports`, text: 'menu.quotedProducts', visible: true, hasHr: false, entries: [] },
    ] },
];

// PROPIEDADES DE LA CONSTANTE ENTRIES

// code: ???

// to: Es hacia donde redireccionará.

// text: Es el texto que se ve.

// visible: Si es visible o no.

// operation: Si tiene operación de CoreSec habilitada o no.

// entries: Cuando es un dropdown, se colocan los hijos en el array con el mismo formato del objeto padre pero se le añade la propiedad "hasHR".

// hasHr: En caso de querer utilizar un <hr> debe cambiarse la propiedad del hijo "hasHr" a true.

// NOTA
// Las rutas deben estar en su orden de aparición.
